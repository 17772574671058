<template>
  <div>
    <header>
      <div class="l-content">
        <el-menu
          class="menu"
          mode="horizontal"
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#fff"
          :default-active="activeIndex"
        >
          <el-menu-item
            index="1"
            class="header-logo"
            @click="handleCommand('')"
          >
            <img
              :src="logoSrc"
              class="logo-img"
            >
          </el-menu-item>
          <el-submenu index="2">
            <template slot="title">
              {{ $t("header.mainProducts") }}
            </template>
            <el-menu-item
              v-for="(cat, i) in categories"
              :key="'2-' + i"
              :index="'2-' + i"
              @click="jumpPage('ParamsSelect?cat=' + cat)"
            >
              {{ $translate(cat, cat) }}
            </el-menu-item>
          </el-submenu>

          <!--        <el-submenu index="2">-->
          <!--          <template slot="title" >主要产品</template>-->
          <!--          <el-menu-item index="2-1">选项1</el-menu-item>-->
          <!--          <el-menu-item index="2-2">选项2</el-menu-item>-->
          <!--        </el-submenu>-->
          <el-submenu index="3">
            <template slot="title">
              {{ $t("header.technicalProposal") }}
            </template>
            <!--<el-menu-item-->
            <!--  index="3-1"-->
            <!--  @click="jumpPage('SolutionList')"-->
            <!--&gt;-->
            <!--  {{ $t("header.technicalProposalList.solutionList") }}-->
            <!--</el-menu-item>-->
            <el-menu-item
              index="3-2"
              @click="locate('car')"
            >
              {{ $t("header.technicalProposalList.carRegion") }}
            </el-menu-item>
            <el-menu-item
              index="3-3"
              @click="locate('solutions')"
            >
              {{ $t("header.technicalProposalList.newEnergyConnector") }}
            </el-menu-item>
            <el-menu-item
              index="3-4"
              @click="locate('solutions')"
            >
              {{ $t("header.technicalProposalList.heavyDutyConnector") }}
            </el-menu-item>
            <el-menu-item
              index="3-5"
              @click="locate('solutions')"
            >
              {{ $t("header.technicalProposalList.houseAppliances") }}
            </el-menu-item>
            <el-menu-item
              index="3-6"
              @click="locate('solutions')"
            >
              {{ $t("header.technicalProposalList.greenEnergy") }}
            </el-menu-item>
          </el-submenu>
          <el-menu-item
            index="4"
            @click="handleCommand('News')"
          >
            {{ $t("header.information") }}
          </el-menu-item>
          <!--          <el-menu-item-->
          <!--            index="5"-->
          <!--            @click="handleCommand('CommentsArea')"-->
          <!--          >-->
          <!--            我要留言-->
          <!--          </el-menu-item>-->
          <el-menu-item
            index="6"
            @click="handleCommand('CompanyProfile')"
          >
            {{ $t("header.aboutUs") }}
          </el-menu-item>
          <el-menu-item
            index="10"
            @click="handleCommand('Honor')"
          >
            {{ $t("header.honor") }}
          </el-menu-item>
          <!--          <el-menu-item-->
          <!--            index="7"-->
          <!--            @click="handleCommand('ParamsSelect')"-->
          <!--          >-->
          <!--            参数选型-->
          <!--          </el-menu-item>-->
          <el-menu-item
            index="8"
            @click="handleCommand('SampReq')"
          >
            {{ $t("header.sampleApply") }}
          </el-menu-item>

          <el-menu-item
            index="9"
            class="download-menu"
            @click="downloadProductManual"
          >
            <template slot="title">
              <div>
                <span>{{ $t("header.productManual") }}</span>
                <i class="el-icon-download" />
              </div>
            </template>
          </el-menu-item>
        </el-menu>
      </div>
      <div class="r-content">
        <div
          class="input-box"
        >
          <transition name="el-zoom-in-center">
            <input
              v-show="!isCollapsed"
              v-model="inputValue"
              :placeholder="$t('header.searchPlaceholder')"
              @keyup.enter="search"
              @blur="handleBlur"
            >
          </transition>
          <el-button
            type="primary"
            style="padding: 10px; font-size: 15px"
            @click="search"
          >
            <i class="el-icon-search" />
          </el-button>
        </div>

        <el-button
          type="primary"
          class="more"
          @click="handleCommand('MyCart')"
        >
          <el-badge
            v-if="countCart>0"
            :value="countCart"
            :max="10"
          >
            <i class="el-icon-shopping-bag-2" />
          </el-badge>
          <i
            v-else
            class="el-icon-shopping-bag-2"
          />
        </el-button>

        <el-dropdown
          v-if="isLogin"
          @command="handleCommand"
        >
          <el-button
            type="primary"
            class="more"
          >
            <i class="el-icon-user" />
          </el-button>
          <el-dropdown-menu

            slot="dropdown"
          >
            <el-dropdown-item
              icon="el-icon-user"
              disabled
            >
              {{ username }}
            </el-dropdown-item>
            <el-dropdown-item
              command="Personal"
              icon="el-icon-house"
              divided
            >
              {{ $t("header.personalCenter") }}
            </el-dropdown-item>
            <el-dropdown-item
              command="Logout"
              icon="el-icon-back"
            >
              {{ $t("header.logout") }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!--        <el-tooltip-->
        <!--          v-else-->
        <!--          class="item"-->
        <!--          content="点击登录"-->
        <!--          effect="light"-->
        <!--          placement="bottom"-->
        <!--        >-->
        <el-button
          v-else
          :title="$t('header.clickLogin')"
          type="primary"
          class="more"
          @click="showLoginBox"
        >
          <i class="el-icon-user" />
        </el-button>
        <!--        </el-tooltip>-->
        <el-button
          v-if="$i18n.locale === 'en_US'"
          title="ZH"
          type="primary"
          class="i18n-btn more"
          @click="langToggle"
        >
          ZH
        </el-button>
        <el-button
          v-else
          title="EN"
          type="primary"
          class="i18n-btn more"
          @click="langToggle"
        >
          EN
        </el-button>
      </div>
    </header>
    <el-dialog
      custom-class="login-dialog"
      :visible.sync="login.visible"
      :append-to-body="true"
      width="450px"
      :close-on-click-modal="false"
      :before-close="clear"
      :show-close="false"
    >
      <div
        slot="title"
        class="dialog-title"
      >
        <div
          class="back el-icon-arrow-left"
          @click="clear"
        >
          {{ $t("header.quit") }}
        </div>
        <div>{{ $t("header.loginToApplySamples") }}</div>
        <div
          class="sub"
          @click="login.type='wx'"
        >
          <span> {{ $t("header.weChatLogin") }}&nbsp;&nbsp;</span>
          <span class="el-icon-full-screen" />
        </div>
      </div>

      <div
        v-if="login.type==='wx'"
        key="wx"
        class="wx"
      >
        <el-image
          :src="login.toEmail"
          class="switch"
          @click="login.type='email'"
        />
        <wxlogin
          id="wxcode"
          class="wx-box"
          theme=""
          :appid="appId"
          :redirect_uri="redirectUri"
          scope="snsapi_login"
        />
      </div>
      <div
        v-else-if="login.type==='email'"
        key="email"
        class="email"
      >
        <el-image
          :src="login.toQrCode"
          class="switch"
          @click="toQrCode"
        />
        <div>
          <!--登录 -->
          <div v-if="login.active==='login'">
            <div class="login_form_title">
              {{ $t('header.loginTitle') }}
            </div>
            <el-form
              key="login"
              ref="loginRef"
              :rules="rules.login"
              :model="form.login"
            >
              <el-form-item prop="email">
                <el-input
                  key="login-email"
                  v-model.trim="form.login.email"
                  :placeholder="$t('header.email')"
                />
              </el-form-item>
              <el-form-item prop="pwd">
                <el-input
                  key="login-pwd"
                  v-model.trim="form.login.pwd"
                  type="password"
                  :placeholder="$t('header.pwd')"
                />
              </el-form-item>
            </el-form>

            <div class="protocol">
              <span>{{ $t('header.loginAgree') }}</span>
              <span @click="toProtocol">{{ $t('header.protocol') }}</span>
            </div>
            <el-button
              key="login-1"
              size="midium"
              class="login_btn"
              @click="emailLogin"
            >
              {{ $t('header.login') }}
            </el-button>
            <el-button
              key="login-2"
              size="midium"
              @click="login.active='register'"
            >
              {{ $t('header.signUp') }}
            </el-button>
            <el-button
              key="login-3"
              size="midium"
              @click="login.active='reset'"
            >
              {{ $t('header.reset') }}
            </el-button>
          </div>


          <!--注册-->
          <div v-else-if="login.active==='register'">
            <div class="login_form_title">
              {{ $t('header.regTitle') }}
            </div>
            <el-form
              key="reg"
              ref="regRef"
              :rules="rules.reg"
              :model="form.reg"
            >
              <el-form-item prop="email">
                <el-input
                  key="reg-email"
                  v-model.trim="form.reg.email"
                  :placeholder="$t('header.email')"
                />
              </el-form-item>
              <el-form-item
                prop="code"
                class="verification"
              >
                <el-input
                  key="reg-code"
                  v-model.trim="form.reg.code"
                  :placeholder="$t('header.verCode')"
                />
                <el-button
                  :disabled="form.reg.sendDisabled"
                  @click="sendVerCode(login.action.sendRegCode)"
                >
                  {{ form.reg.text }}
                </el-button>
              </el-form-item>
              <el-form-item prop="pwd">
                <el-input
                  key="reg-pwd"
                  v-model.trim="form.reg.pwd"
                  type="password"
                  :placeholder="$t('header.pwd')"
                />
              </el-form-item>
              <el-form-item prop="confirmPwd">
                <el-input
                  key="reg-confirmPwd"
                  v-model.trim="form.reg.confirmPwd"
                  type="password"
                  :placeholder="$t('header.confirmPwd')"
                />
              </el-form-item>
            </el-form>

            <div class="protocol">
              <span>{{ $t('header.signUpAgree') }}</span>
              <span @click="toProtocol">{{ $t('header.protocol') }}</span>
            </div>
            <el-button
              key="reg-1"
              class="login_btn"
              @click="signIn"
            >
              {{ $t('header.signUp') }}
            </el-button>
            <el-button
              key="reg-2"
              @click="login.active='login'"
            >
              {{ $t('header.toLogin') }}
            </el-button>
          </div>
          <!--重置密码-->
          <div v-else-if="login.active==='reset'">
            <div class="login_form_title">
              {{ $t('header.resetTitle') }}
            </div>
            <el-form
              key="reset"
              ref="resetRef"
              :rules="rules.reset"
              :model="form.reset"
            >
              <el-form-item prop="email">
                <el-input
                  key="reset-email"
                  v-model.trim="form.reset.email"
                  :placeholder="$t('header.email')"
                />
              </el-form-item>
              <el-form-item
                prop="code"
                class="verification"
              >
                <el-input
                  key="reset-code"
                  v-model.trim="form.reset.code"
                  :placeholder="$t('header.verCode')"
                />
                <el-button
                  key="reset-1"
                  :disabled="form.reset.sendDisabled"
                  @click="sendVerCode(login.action.sendResetCode)"
                >
                  {{ form.reset.text }}
                </el-button>
              </el-form-item>
              <el-form-item prop="pwd">
                <el-input
                  key="reset-pwd"
                  v-model.trim="form.reset.pwd"
                  type="password"
                  :placeholder="$t('header.pwd')"
                />
              </el-form-item>
              <el-form-item prop="confirmPwd">
                <el-input
                  key="reset-confirmPwd"
                  v-model.trim="form.reset.confirmPwd"
                  type="password"
                  :placeholder="$t('header.confirmPwd')"
                />
              </el-form-item>
            </el-form>

            <div class="protocol">
              <span>{{ $t('header.signUpAgree') }}</span>
              <span @click="toProtocol">{{ $t('header.protocol') }}</span>
            </div>
            <el-button
              key="reset-2"
              class="login_btn"
              @click="reset"
            >
              {{ $t('header.submit') }}
            </el-button>
            <el-button
              key="reset-3"
              @click="login.active='login'"
            >
              {{ $t('header.toLogin') }}
            </el-button>
          </div>

          <div
            v-else-if="login.active==='success'"
            class="svg-box"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="180px"
              height="200.00px"
              viewBox="0 0 1024 1024"
              version="1.1"
            ><path
              d="M512 1024A512 512 0 1 1 512 0a512 512 0 0 1 0 1024z m-49.590857-377.197714L315.977143 498.614857 219.428571 590.848c70.217143 37.814857 168.594286 106.788571 252.854858 213.723429C531.821714 692.662857 715.337143 463.725714 804.571429 443.245714c-14.409143-57.709714-22.528-166.034286 0-223.817143-183.003429 120.685714-342.162286 427.373714-342.162286 427.373715z"
              fill="#029B00"
            /></svg>
            <div class="success-text">
              {{ successText.title }}
            </div>
            <div class="success-text">
              {{ successText.subTitle }}
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import wxlogin from 'vue-wxlogin';
import eventBus from "@/utils/eventBus";
import {toEn} from "@/utils/i18n";
import {doPost} from "@/utils/http";
import commonUtil from "@/utils/commonUtil";
export default {
  components: {
    wxlogin,
  },
  props: {
    loginBoxVisible: {
      type: Boolean,
      default: false,
    }
  },
  data(){
    return{
      userImg: require("../assets/images/logo.png"),
      wxImg: require("../assets/images/wx.png"),
      isLogin: false,
      appId: "",
      redirectUri: "",
      userId : null,
      username: null,
      cartData: [],
      countCart: 0,
      isCollapsed: false,
      inputValue: "",
      categories: [],
      logoSrc: require("@/assets/images/logo.png"),
      activeIndex: "-1",
      successText: { title: null, subTitle: null },
      login: {
        visible: false,
        active: "login",
        type: "wx",
        toEmail: require("@/assets/login/toEmail.png"),
        toQrCode: require("@/assets/login/toQrCode.png"),
        action: {
          sendRegCode: 0,
          sendResetCode: 1,
          reg: 2,
          login: 3,
          reset: 4
        }
      },
      form: null,
      orgForm: {
        reg: {
          email: null,
          pwd: null,
          confirmPwd: null,
          code: null,
          text: this.$t("header.sendCode"),
          sendDisabled: false,
        },
        reset: {
          email: null,
          pwd: null,
          confirmPwd: null,
          code: null,
          text: this.$t("header.sendCode"),
          sendDisabled: false,
        },
        login: {
          email: null,
          pwd: null,
        }
      }
      }
  },
  computed: {
    text() {
      return {
        sendCode: this.$t("header.sendCode")
      }
    },
    rules() {
      let checkPwd = (rule, value, callback) => {
        let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,}$/;
        if (reg.test(value)) {
          callback();
        }
        callback(new Error(this.$t("header.pwdRule")));
      };
      return  {
        login: {
          email: [
            { required: true, message: this.$t('header.email'), trigger: 'blur' },
          ],
          pwd: [
            { required: true, message: this.$t('header.pwd'), trigger: 'blur' },
          ],
        },
        reg: {
          email: [
            { required: true, message: this.$t('header.email'), trigger: 'blur' },
          ],
          pwd: [
            { required: true, message: this.$t('header.pwd'), trigger: 'blur' },
            { validator: checkPwd, trigger: 'blur' },
          ],
          confirmPwd: [
            { required: true, message: this.$t('header.confirmPwd'), trigger: 'blur' },
          ],
          code: [
            { required: true, message: this.$t('header.verCode'), trigger: 'blur' },
          ],
        },
        reset: {
          email: [
            { required: true, message: this.$t('header.email'), trigger: 'blur' },
          ],
          pwd: [
            { required: true, message: this.$t('header.pwd'), trigger: 'blur' },
            { validator: checkPwd, trigger: 'blur' },
          ],
          confirmPwd: [
            { required: true, message: this.$t('header.confirmPwd'), trigger: 'blur' },
          ],
          code: [
            { required: true, message: this.$t('header.verCode'), trigger: 'blur' },
          ],
        },
      }
    },

  },
  watch: {
    $route: {
      handler () {
        if (this.$route.query.code !== null && this.$route.query.code !== undefined) {
          this.sendCode();
        }
      }
    },
    loginBoxVisible : {
      handler() {
        this.login.visible = this.loginBoxVisible;
      }
    }
  },
  created() {
    this.form = this.$commonUtil.cloneObj(this.orgForm);
    this.getUserInfo();
    this.initCats();
    if (this.$route.query.code !== null && this.$route.query.code !== undefined) {
      this.sendCode();
    }
    eventBus.$on("userId", (params) => {
      this.countUserCart(params);
    });
    eventBus.$on("isLogin", (params) => {
      this.isLogin = false;
      this.getQrCode();
    });
    this.countUserCart(this.userId);
  },
  Destroy(){
    eventBus.$off("userId")
  },
  methods: {
    checkLoginAndToken(data) {
      let userId = localStorage.getItem("userId");
      let token = localStorage.getItem("token");
      if (userId !== null && token !== null) {
        let url = "/sso/user-login/checkToken";
        let params = {userId: userId, token: token}
        this.$doPost(url, params).then(res => {
          if (res.code === 200) {
            if (res.data === false) {
              // let reLogin = localStorage.getItem("lang") === "en_US" ? "Token invalid, please login again!" : "令牌失效，请重新登录！";
              // this.$message.info(reLogin);
              this.countCart = 0;
              this.isLogin = false;
              localStorage.clear();
              this.getQrCode();
            } else {
              this.$router.push(data);
            }
          }
        })
      } else {
        // let loginFirst = localStorage.getItem("lang") === "en_US" ? "Please login first!" : "请先登录！";
        // this.$message.info(loginFirst);
        this.countCart = 0;
        this.isLogin = false;
        this.showLoginBox();
      }
    },
    langToggle() {
      if (localStorage.getItem("lang") === "en_US") {
        this.$i18n.locale = "zh_CN";
        localStorage.setItem("lang", "zh_CN");
        window.location.reload();
      } else {
        this.$i18n.locale = "en_US";
        toEn();
      }
      // location.reload();
    },
    handleBlur() {
      if (!this.isCollapsed) {
        this.collapse();
      }
    },
    handleMenu() {
      this.$store.commit("collapseMenu");
    },
    initCats() {
      let url = "/item/goods/getAllCats";
      this.$doPost(url).then(res=>{
        if (res.code === 200) {
          this.categories = res.data;
        }
      });
    },
    getUserInfo() {
      let username = localStorage.getItem("username");
      let userId = localStorage.getItem("userId");
      if (username !== null && userId !== null) {
        this.username = username;
        this.userId = userId;
        this.isLogin = true;
      } else {
        this.username = null;
        this.userId = null;
        this.isLogin = false;
      }

    },
    search () {
      this.$router.push({
        name: "productList",
        query: {
          searchKey: this.inputValue
        }
      });
    },
    collapse() {
      // this.isCollapsed = !this.isCollapsed;
    },
    handleCommand(command) {
      if (command === "Logout") {
        this.logout();
        return;
      }
      if (command === "SampReq") {
        this.checkLoginAndToken({
          name: "Personal",
          query: {"selectedTitle": "样品申请"},
        });
        return;
      }
      if (command === "MyCart") {
        this.checkLoginAndToken({
          name: "Personal",
          query: {"selectedTitle": "我的购物车"},
        });
        return;
      }
      if (command === "Personal") {
        this.checkLoginAndToken({
          name: "Personal",
          query: {"selectedTitle": "账户信息"},
        });
        return;
      }
      if (this.$route.path === "/" + command) {
        return;
      }
      this.$router.push("/" + command);
    },

    getQrCode() {
      let url = "/sso/wx/getWXQrCode"
      this.$doPost(url).then(res=>{
        this.appId = res.data.appId;
        this.redirectUri = res.data.redirectUri;
        this.login.type='wx';
        this.login.visible = true;
      })
    },
    showLoginBox() {
      if (this.$i18n.locale === 'en_US') {
        this.login.type = "email";
        this.login.visible = true;
      } else {
        this.login.type = "wx";
        this.getQrCode();
      }
      this.$refs.regRef?.clearValidate();
      this.$refs.resetRef?.clearValidate();
      this.$refs.loginRef?.clearValidate();
    },
    // 清除校验信息
    clear() {
      this.form = this.$commonUtil.cloneObj(this.orgForm)
      this.$refs.regRef?.resetFields();
      this.$refs.resetRef?.resetFields();
      this.$refs.loginRef?.resetFields();
      this.login.visible = false;
    },
    emailLogin () {
      this.$refs.loginRef.validate((valid) => {
        if (!valid) {
          return;
        }
        let url = "/sso/user-login/userLogin"
        let param = {
          loginType: "email",
          email: this.form.login.email,
          pwd: commonUtil.encode(this.form.login.pwd),
          action: this.login.action.login
        }
        this.$doPost(url, param, true).then(res=> {
          this.loginCallback(res)
        })});
    },
    sendCode() {
      if (this.isLogin) {
        return;
      }
      let code = this.$route.query.code;
      let param = {loginType: "wx", wxCode: code}
      let url = "/sso/user-login/userLogin";
      this.$doPost(url, param, true).then(res => {
        this.loginCallback(res);
      })
    },
    loginCallback(res) {
      if (res.code === 200) {
        let username = res.data.username;
        let userId = res.data.userId;
        let token = res.data.token.token;
        localStorage.setItem("username", username);
        localStorage.setItem("userId", userId);
        localStorage.setItem("token", token);
        this.isLogin = true;
        this.username = username;
        this.userId = userId;
        eventBus.$emit("userId", this.userId);
        this.activeIndex = "-1";
        this.login.active = "success";
        this.successText.title = this.$t('header.loginSuccess') ;
        this.successText.subTitle =  username;
        setTimeout(()=> {
          this.login.active = "login";
          this.login.visible = false;
        }, 3000)
      }
    },
    locate(id){
      if (this.$route.path === "/" || this.$route.path === "/main" ){
        document.getElementById(id)
            .scrollIntoView({ behavior: "smooth", block: "start" });
      } else {
      this.$router.replace({path:'/'}).then(()=>{
        document.getElementById(id)
            .scrollIntoView({ behavior: "smooth", block: "start" });
      })}
    },
    jumpPage(destination) {
      if (this.$route.path === "/" + destination) {
        // this.$router.go(0);
      }
      this.$router.push(destination);
    },
    logout() {
      localStorage.removeItem("username");
      localStorage.removeItem("userId");
      localStorage.removeItem("token");
      let url = "/sso/user-login/checkOut"
      let params = {userId: this.userId}
      this.$doPost(url, params).then(res => {});
      this.$router.push("/").then(r=>{});
      this.isLogin = false;
      this.countCart = 0;
      this.activeIndex = "-1";
      this.clear();
      // this.$router.go(0)
    },
    countUserCart(userId){
      let url = "/cart/sample-cart/countSampleCart";
      let param = {
        userId: userId,
      }
      this.$doPost(url,param).then(res => {
        if (res.code == 200) {
          this.countCart = res.data;
        } else {
          this.$alert("请求购物车数量接口失败！");
        }
      })
    },
    // 下载产品手册
    downloadProductManual() {
      this.$commonUtil.downloadFile("/item/download/productManual");
    },
   // 注册
    //发送验证码
    sendVerCode(action) {
      let ref;
      let email;
      if (action === this.login.action.sendRegCode) {
        ref = this.$refs.regRef;
        email = this.form.reg.email;
      } else if (action === this.login.action.sendResetCode) {
        ref = this.$refs.resetRef;
        email = this.form.reset.email;
      } else {
        return;
      }
      ref.validateField("email", (valid) => {
        if (!this.$commonUtil.isNull(valid)) {
          return;
        }
        console.log(1)
        this.tackBtn(action);
        let params = {
          email: email,
          action: action
        };
        let url = "/sso/user-login/sendVerCode"
        this.$doPost(url, params).then(res=> {
        });
      })
    },
    tackBtn(action) {       //验证码倒数60秒
      let time = 60;
      let that = this;
      this.tiktok(action, time);
      let timer = setInterval(() => {
        if (time === 0) {
          clearInterval(timer);
          if (action === that.login.action.sendRegCode) {
            that.form.reg.sendDisabled = false;
            that.form.reg.text = that.$t('header.sendCode');
          } else if (action === that.login.action.sendResetCode) {
            that.form.reset.sendDisabled = false;
            that.form.reset.text = that.$t('header.sendCode');
          }
        } else {
          time--;
          this.tiktok(action, time);
        }
      }, 1000);
    },
    tiktok(action, time) {
      if (action === this.login.action.sendRegCode) {
        this.form.reg.sendDisabled = true;
        this.form.reg.text = time + "s";
      } else if (action === this.login.action.sendResetCode) {
        this.form.reset.sendDisabled = true;
        this.form.reset.text = time + "s";
      }
    },
    reset() {
      this.$refs.resetRef.validate((valid) => {
          if (!valid) {
            return;
          }
        let url = "/sso/user-login/reset";
        let email = this.form.reset.email;
        let params = {
          email: email,
          pwd: commonUtil.encode(this.form.reset.pwd),
          confirmPwd: commonUtil.encode(this.form.reset.confirmPwd),
          code: this.form.reset.code,
          action: this.login.action.reset
        };
        this.$doPost(url, params).then(res=>{
          if (res.code === 200 && res.data) {
            this.login.active = "success";
            this.successText.title = this.$t("header.resetSuccess");
            this.successText.subTitle = this.$t("header.jmp2Login");
            setTimeout(()=> {
              this.login.active = "login";
              this.form.login.email = email;
              this.form.login.pwd = null;
            }, 3000)
          }
        })
      })
    },
    toProtocol() {
      if(this.$i18n.locale === "zh_CN" ) {
        window.open("/protocol-zh")
      } else if (this.$i18n.locale === "en_US" ) {
        window.open("/protocol-en")
      }
    },
    toQrCode() {
      this.getQrCode();
    },
    signIn() {
      this.$refs.regRef.validate((valid) => {
        if (!valid) {
          return;
        }
        let url = "/sso/user-login/register"
        let email = this.form.reg.email;
        let param = {
          email: email,
          pwd: commonUtil.encode(this.form.reg.pwd),
          confirmPwd: commonUtil.encode(this.form.reg.confirmPwd),
          code: this.form.reg.code,
          action: this.login.action.reg
        }
        this.$doPost(url, param).then(res=> {
          if (res.code === 200 && res.data) {
            this.login.active = "success";
            this.successText.title = this.$t("header.regSuccess");
            this.successText.subTitle = this.$t("header.jmp2Login");
            setTimeout(()=> {
              this.login.active = "login";
              this.form.login.email = email;
              this.form.login.pwd = null;
            }, 3000)
          }
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
header{
  display: flex;
  height: 81px;
  width: 100%;
  justify-content: space-between;
  font-family: "PingFang SC", "微软雅黑";
  align-items: center;
  overflow: hidden ;
  background: rgba(0,15,30,0.45);
  .l-content {
    height: 100%;
    width: 100%;
    padding: 0;
    .menu {
      height: 100%;
    }
    .el-menu{
      background: rgba(0,0,0,0) !important;
      .el-menu-item, .el-submenu{
        background: rgba(0,0,0,0) !important;
        font-size: 18px;
        display: flex;
        align-items: center;
        height: 100%;
        color: white;
        margin-left: 10px;
        margin-right: 10px;
        ::v-deep .el-submenu__title {
          color: #FFFFFF;
          font-size: 18px !important;
          background: rgba(0,0,0,0) !important;
        }

      }
    }
  }
  .r-content {
    display: flex;
    align-items: center;
    .input-box{
      height: 80%;
      line-height: 40px;
      //border: white solid 1px;
      border-radius: 10px;
      margin-right: 15px;
      display: flex;
      background: rgba(0,0,0,0.5);
      input {
        color: white;
        margin-left: 12px;
        border: 0;
        outline: none;
        width: 225px;
        background: rgba(0, 0, 0, 0);
        font-size: 15px;
      };
      input::placeholder {
        color: white;
      }
    }
    /deep/ .el-button {
      margin: unset;
      font-size: 19px;
      background: rgba(0, 0, 0, 0);
      border: rgba(0, 0, 0, 0);
    }
    .i18n-btn{
      font-size: 15px !important;
    }
  }
}
/deep/ .el-submenu__title {
  font-size: 15px;
  min-height: 100%;
  display: flex;
  align-items: center;
}

/deep/ .el-menu {
  width: 100%;
}

/deep/ .el-submenu__icon-arrow{
  color: white;
  opacity: 1;
  margin-top: 2px;
}
// 弹窗
// 1. header
/deep/ .el-dialog__header {
  color: rgb(100, 100, 100);
  font-family: 微软雅黑;
  font-size: 25px;
}
.dialog-title {
  color: black;
  .back {
    color: grey;
    font-size: 20px;
    margin-bottom: 25px;
    cursor: pointer;
    display: inline-block;
    &:hover {
      color: #578dff;
    }
  }
  .sub {
    display: inline-block;
    font-size: 17px;
    color: grey;
    cursor: pointer;
    &:hover {
      color: #578dff;
    }
  }
}

.switch {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 100px;
}
// 2.body
.wx {
  margin: 0 auto;
  .wx-box {
    text-align: center;
  }
}
.email {
  padding: 10px 40px;
  .login_form_title {
    font-size: 20px;
    margin: 5px 0 10px 0;
  }
  .protocol {
    margin-top: 45px;
    font-size: 13px;
    >span:nth-child(2){
      color: #578dff;
      cursor: pointer;
    }
  }
  // 间距
  .el-input, .el-button {
    display: block;
    width: 100%;
  }
  .el-button {
    margin: 20px 0;
  }
  // 字号
  .el-button {
    font-size: 16px;
  }
  .login_btn {
    color: white;
    background: #0550ED;
  }
  .verification {
    ::v-deep .el-form-item__content{
      display: flex;
    }
    width: 100%;
    .el-input {
      margin: 0 15px 0 0;
    }
    .el-button {
      width: 120px;
      font-size: 14px;
      margin: 0;
    }
  }
}


/deep/ .el-badge__content{
  background-color: red;
  color: white;
  border: 0 solid #fff;
}
.item {
  font-size: 25px;
  font-family: 微软雅黑;
}
.more {
  padding: 10px;
}

.el-icon-download {
  color: #ffffff;
}

::v-deep .download-menu.is-active {
  border-bottom: none;
}

::v-deep .header-logo.is-active {
  border-bottom: none;
}
.header-logo {
  width: 100px;
  font-size: 34px;
  padding:0;
  margin: 0
}
.logo-img {
  height: 59px;
  display: inline-block;
  margin: 0 auto;
}

.el-dropdown-menu.el-popper {
  z-index: 9999 !important;
}

.box__wrapper {
  z-index: 9999 !important;
}
.svg-box {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 40px;
  svg {
    margin-bottom: 30px;
  }
  .success-text {
    font-size: 20px;
    font-family: Calibri;
    font-weight: 800;
    color: #909399;
  }
}

</style>
