const addressTree = [
  {
    id: 110000,
    addressName: "北京市",
    parentId: "86",
    updateTime: null,
    addressList: [
      {
        id: 110100,
        addressName: "市辖区",
        parentId: "110000",
        updateTime: null,
        addressList: [
          {
            id: 110101,
            addressName: "东城区",
            parentId: "110100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 110102,
            addressName: "西城区",
            parentId: "110100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 110105,
            addressName: "朝阳区",
            parentId: "110100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 110106,
            addressName: "丰台区",
            parentId: "110100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 110107,
            addressName: "石景山区",
            parentId: "110100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 110108,
            addressName: "海淀区",
            parentId: "110100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 110109,
            addressName: "门头沟区",
            parentId: "110100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 110111,
            addressName: "房山区",
            parentId: "110100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 110112,
            addressName: "通州区",
            parentId: "110100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 110113,
            addressName: "顺义区",
            parentId: "110100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 110114,
            addressName: "昌平区",
            parentId: "110100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 110115,
            addressName: "大兴区",
            parentId: "110100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 110116,
            addressName: "怀柔区",
            parentId: "110100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 110117,
            addressName: "平谷区",
            parentId: "110100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 110118,
            addressName: "密云区",
            parentId: "110100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 110119,
            addressName: "延庆区",
            parentId: "110100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
    ],
  },
  {
    id: 120000,
    addressName: "天津市",
    parentId: "86",
    updateTime: null,
    addressList: [
      {
        id: 120100,
        addressName: "市辖区",
        parentId: "120000",
        updateTime: null,
        addressList: [
          {
            id: 120101,
            addressName: "和平区",
            parentId: "120100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 120102,
            addressName: "河东区",
            parentId: "120100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 120103,
            addressName: "河西区",
            parentId: "120100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 120104,
            addressName: "南开区",
            parentId: "120100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 120105,
            addressName: "河北区",
            parentId: "120100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 120106,
            addressName: "红桥区",
            parentId: "120100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 120110,
            addressName: "东丽区",
            parentId: "120100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 120111,
            addressName: "西青区",
            parentId: "120100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 120112,
            addressName: "津南区",
            parentId: "120100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 120113,
            addressName: "北辰区",
            parentId: "120100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 120114,
            addressName: "武清区",
            parentId: "120100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 120115,
            addressName: "宝坻区",
            parentId: "120100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 120116,
            addressName: "滨海新区",
            parentId: "120100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 120117,
            addressName: "宁河区",
            parentId: "120100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 120118,
            addressName: "静海区",
            parentId: "120100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 120119,
            addressName: "蓟州区",
            parentId: "120100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
    ],
  },
  {
    id: 130000,
    addressName: "河北省",
    parentId: "86",
    updateTime: null,
    addressList: [
      {
        id: 130100,
        addressName: "石家庄市",
        parentId: "130000",
        updateTime: null,
        addressList: [
          {
            id: 130101,
            addressName: "市辖区",
            parentId: "130100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130102,
            addressName: "长安区",
            parentId: "130100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130104,
            addressName: "桥西区",
            parentId: "130100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130105,
            addressName: "新华区",
            parentId: "130100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130107,
            addressName: "井陉矿区",
            parentId: "130100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130108,
            addressName: "裕华区",
            parentId: "130100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130109,
            addressName: "藁城区",
            parentId: "130100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130110,
            addressName: "鹿泉区",
            parentId: "130100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130111,
            addressName: "栾城区",
            parentId: "130100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130121,
            addressName: "井陉县",
            parentId: "130100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130123,
            addressName: "正定县",
            parentId: "130100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130125,
            addressName: "行唐县",
            parentId: "130100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130126,
            addressName: "灵寿县",
            parentId: "130100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130127,
            addressName: "高邑县",
            parentId: "130100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130128,
            addressName: "深泽县",
            parentId: "130100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130129,
            addressName: "赞皇县",
            parentId: "130100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130130,
            addressName: "无极县",
            parentId: "130100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130131,
            addressName: "平山县",
            parentId: "130100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130132,
            addressName: "元氏县",
            parentId: "130100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130133,
            addressName: "赵县",
            parentId: "130100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130171,
            addressName: "石家庄高新技术产业开发区",
            parentId: "130100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130172,
            addressName: "石家庄循环化工园区",
            parentId: "130100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130181,
            addressName: "辛集市",
            parentId: "130100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130183,
            addressName: "晋州市",
            parentId: "130100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130184,
            addressName: "新乐市",
            parentId: "130100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 130200,
        addressName: "唐山市",
        parentId: "130000",
        updateTime: null,
        addressList: [
          {
            id: 130201,
            addressName: "市辖区",
            parentId: "130200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130202,
            addressName: "路南区",
            parentId: "130200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130203,
            addressName: "路北区",
            parentId: "130200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130204,
            addressName: "古冶区",
            parentId: "130200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130205,
            addressName: "开平区",
            parentId: "130200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130207,
            addressName: "丰南区",
            parentId: "130200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130208,
            addressName: "丰润区",
            parentId: "130200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130209,
            addressName: "曹妃甸区",
            parentId: "130200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130224,
            addressName: "滦南县",
            parentId: "130200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130225,
            addressName: "乐亭县",
            parentId: "130200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130227,
            addressName: "迁西县",
            parentId: "130200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130229,
            addressName: "玉田县",
            parentId: "130200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130271,
            addressName: "河北唐山芦台经济开发区",
            parentId: "130200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130272,
            addressName: "唐山市汉沽管理区",
            parentId: "130200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130273,
            addressName: "唐山高新技术产业开发区",
            parentId: "130200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130274,
            addressName: "河北唐山海港经济开发区",
            parentId: "130200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130281,
            addressName: "遵化市",
            parentId: "130200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130283,
            addressName: "迁安市",
            parentId: "130200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130284,
            addressName: "滦州市",
            parentId: "130200",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 130300,
        addressName: "秦皇岛市",
        parentId: "130000",
        updateTime: null,
        addressList: [
          {
            id: 130301,
            addressName: "市辖区",
            parentId: "130300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130302,
            addressName: "海港区",
            parentId: "130300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130303,
            addressName: "山海关区",
            parentId: "130300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130304,
            addressName: "北戴河区",
            parentId: "130300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130306,
            addressName: "抚宁区",
            parentId: "130300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130321,
            addressName: "青龙满族自治县",
            parentId: "130300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130322,
            addressName: "昌黎县",
            parentId: "130300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130324,
            addressName: "卢龙县",
            parentId: "130300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130371,
            addressName: "秦皇岛市经济技术开发区",
            parentId: "130300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130372,
            addressName: "北戴河新区",
            parentId: "130300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 130400,
        addressName: "邯郸市",
        parentId: "130000",
        updateTime: null,
        addressList: [
          {
            id: 130401,
            addressName: "市辖区",
            parentId: "130400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130402,
            addressName: "邯山区",
            parentId: "130400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130403,
            addressName: "丛台区",
            parentId: "130400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130404,
            addressName: "复兴区",
            parentId: "130400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130406,
            addressName: "峰峰矿区",
            parentId: "130400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130407,
            addressName: "肥乡区",
            parentId: "130400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130408,
            addressName: "永年区",
            parentId: "130400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130423,
            addressName: "临漳县",
            parentId: "130400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130424,
            addressName: "成安县",
            parentId: "130400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130425,
            addressName: "大名县",
            parentId: "130400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130426,
            addressName: "涉县",
            parentId: "130400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130427,
            addressName: "磁县",
            parentId: "130400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130430,
            addressName: "邱县",
            parentId: "130400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130431,
            addressName: "鸡泽县",
            parentId: "130400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130432,
            addressName: "广平县",
            parentId: "130400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130433,
            addressName: "馆陶县",
            parentId: "130400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130434,
            addressName: "魏县",
            parentId: "130400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130435,
            addressName: "曲周县",
            parentId: "130400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130471,
            addressName: "邯郸经济技术开发区",
            parentId: "130400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130473,
            addressName: "邯郸冀南新区",
            parentId: "130400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130481,
            addressName: "武安市",
            parentId: "130400",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 130500,
        addressName: "邢台市",
        parentId: "130000",
        updateTime: null,
        addressList: [
          {
            id: 130501,
            addressName: "市辖区",
            parentId: "130500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130502,
            addressName: "桥东区",
            parentId: "130500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130503,
            addressName: "桥西区",
            parentId: "130500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130521,
            addressName: "邢台县",
            parentId: "130500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130522,
            addressName: "临城县",
            parentId: "130500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130523,
            addressName: "内丘县",
            parentId: "130500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130524,
            addressName: "柏乡县",
            parentId: "130500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130525,
            addressName: "隆尧县",
            parentId: "130500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130526,
            addressName: "任县",
            parentId: "130500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130527,
            addressName: "南和县",
            parentId: "130500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130528,
            addressName: "宁晋县",
            parentId: "130500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130529,
            addressName: "巨鹿县",
            parentId: "130500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130530,
            addressName: "新河县",
            parentId: "130500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130531,
            addressName: "广宗县",
            parentId: "130500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130532,
            addressName: "平乡县",
            parentId: "130500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130533,
            addressName: "威县",
            parentId: "130500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130534,
            addressName: "清河县",
            parentId: "130500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130535,
            addressName: "临西县",
            parentId: "130500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130571,
            addressName: "河北邢台经济开发区",
            parentId: "130500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130581,
            addressName: "南宫市",
            parentId: "130500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130582,
            addressName: "沙河市",
            parentId: "130500",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 130600,
        addressName: "保定市",
        parentId: "130000",
        updateTime: null,
        addressList: [
          {
            id: 130601,
            addressName: "市辖区",
            parentId: "130600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130602,
            addressName: "竞秀区",
            parentId: "130600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130606,
            addressName: "莲池区",
            parentId: "130600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130607,
            addressName: "满城区",
            parentId: "130600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130608,
            addressName: "清苑区",
            parentId: "130600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130609,
            addressName: "徐水区",
            parentId: "130600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130623,
            addressName: "涞水县",
            parentId: "130600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130624,
            addressName: "阜平县",
            parentId: "130600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130626,
            addressName: "定兴县",
            parentId: "130600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130627,
            addressName: "唐县",
            parentId: "130600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130628,
            addressName: "高阳县",
            parentId: "130600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130629,
            addressName: "容城县",
            parentId: "130600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130630,
            addressName: "涞源县",
            parentId: "130600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130631,
            addressName: "望都县",
            parentId: "130600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130632,
            addressName: "安新县",
            parentId: "130600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130633,
            addressName: "易县",
            parentId: "130600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130634,
            addressName: "曲阳县",
            parentId: "130600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130635,
            addressName: "蠡县",
            parentId: "130600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130636,
            addressName: "顺平县",
            parentId: "130600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130637,
            addressName: "博野县",
            parentId: "130600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130638,
            addressName: "雄县",
            parentId: "130600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130671,
            addressName: "保定高新技术产业开发区",
            parentId: "130600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130672,
            addressName: "保定白沟新城",
            parentId: "130600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130681,
            addressName: "涿州市",
            parentId: "130600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130682,
            addressName: "定州市",
            parentId: "130600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130683,
            addressName: "安国市",
            parentId: "130600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130684,
            addressName: "高碑店市",
            parentId: "130600",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 130700,
        addressName: "张家口市",
        parentId: "130000",
        updateTime: null,
        addressList: [
          {
            id: 130701,
            addressName: "市辖区",
            parentId: "130700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130702,
            addressName: "桥东区",
            parentId: "130700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130703,
            addressName: "桥西区",
            parentId: "130700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130705,
            addressName: "宣化区",
            parentId: "130700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130706,
            addressName: "下花园区",
            parentId: "130700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130708,
            addressName: "万全区",
            parentId: "130700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130709,
            addressName: "崇礼区",
            parentId: "130700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130722,
            addressName: "张北县",
            parentId: "130700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130723,
            addressName: "康保县",
            parentId: "130700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130724,
            addressName: "沽源县",
            parentId: "130700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130725,
            addressName: "尚义县",
            parentId: "130700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130726,
            addressName: "蔚县",
            parentId: "130700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130727,
            addressName: "阳原县",
            parentId: "130700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130728,
            addressName: "怀安县",
            parentId: "130700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130730,
            addressName: "怀来县",
            parentId: "130700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130731,
            addressName: "涿鹿县",
            parentId: "130700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130732,
            addressName: "赤城县",
            parentId: "130700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130771,
            addressName: "张家口经济开发区",
            parentId: "130700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130772,
            addressName: "张家口市察北管理区",
            parentId: "130700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130773,
            addressName: "张家口市塞北管理区",
            parentId: "130700",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 130800,
        addressName: "承德市",
        parentId: "130000",
        updateTime: null,
        addressList: [
          {
            id: 130801,
            addressName: "市辖区",
            parentId: "130800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130802,
            addressName: "双桥区",
            parentId: "130800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130803,
            addressName: "双滦区",
            parentId: "130800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130804,
            addressName: "鹰手营子矿区",
            parentId: "130800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130821,
            addressName: "承德县",
            parentId: "130800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130822,
            addressName: "兴隆县",
            parentId: "130800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130824,
            addressName: "滦平县",
            parentId: "130800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130825,
            addressName: "隆化县",
            parentId: "130800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130826,
            addressName: "丰宁满族自治县",
            parentId: "130800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130827,
            addressName: "宽城满族自治县",
            parentId: "130800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130828,
            addressName: "围场满族蒙古族自治县",
            parentId: "130800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130871,
            addressName: "承德高新技术产业开发区",
            parentId: "130800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130881,
            addressName: "平泉市",
            parentId: "130800",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 130900,
        addressName: "沧州市",
        parentId: "130000",
        updateTime: null,
        addressList: [
          {
            id: 130901,
            addressName: "市辖区",
            parentId: "130900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130902,
            addressName: "新华区",
            parentId: "130900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130903,
            addressName: "运河区",
            parentId: "130900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130921,
            addressName: "沧县",
            parentId: "130900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130922,
            addressName: "青县",
            parentId: "130900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130923,
            addressName: "东光县",
            parentId: "130900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130924,
            addressName: "海兴县",
            parentId: "130900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130925,
            addressName: "盐山县",
            parentId: "130900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130926,
            addressName: "肃宁县",
            parentId: "130900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130927,
            addressName: "南皮县",
            parentId: "130900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130928,
            addressName: "吴桥县",
            parentId: "130900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130929,
            addressName: "献县",
            parentId: "130900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130930,
            addressName: "孟村回族自治县",
            parentId: "130900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130971,
            addressName: "河北沧州经济开发区",
            parentId: "130900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130972,
            addressName: "沧州高新技术产业开发区",
            parentId: "130900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130973,
            addressName: "沧州渤海新区",
            parentId: "130900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130981,
            addressName: "泊头市",
            parentId: "130900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130982,
            addressName: "任丘市",
            parentId: "130900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130983,
            addressName: "黄骅市",
            parentId: "130900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 130984,
            addressName: "河间市",
            parentId: "130900",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 131000,
        addressName: "廊坊市",
        parentId: "130000",
        updateTime: null,
        addressList: [
          {
            id: 131001,
            addressName: "市辖区",
            parentId: "131000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 131002,
            addressName: "安次区",
            parentId: "131000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 131003,
            addressName: "广阳区",
            parentId: "131000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 131022,
            addressName: "固安县",
            parentId: "131000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 131023,
            addressName: "永清县",
            parentId: "131000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 131024,
            addressName: "香河县",
            parentId: "131000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 131025,
            addressName: "大城县",
            parentId: "131000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 131026,
            addressName: "文安县",
            parentId: "131000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 131028,
            addressName: "大厂回族自治县",
            parentId: "131000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 131071,
            addressName: "廊坊经济技术开发区",
            parentId: "131000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 131081,
            addressName: "霸州市",
            parentId: "131000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 131082,
            addressName: "三河市",
            parentId: "131000",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 131100,
        addressName: "衡水市",
        parentId: "130000",
        updateTime: null,
        addressList: [
          {
            id: 131101,
            addressName: "市辖区",
            parentId: "131100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 131102,
            addressName: "桃城区",
            parentId: "131100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 131103,
            addressName: "冀州区",
            parentId: "131100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 131121,
            addressName: "枣强县",
            parentId: "131100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 131122,
            addressName: "武邑县",
            parentId: "131100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 131123,
            addressName: "武强县",
            parentId: "131100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 131124,
            addressName: "饶阳县",
            parentId: "131100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 131125,
            addressName: "安平县",
            parentId: "131100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 131126,
            addressName: "故城县",
            parentId: "131100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 131127,
            addressName: "景县",
            parentId: "131100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 131128,
            addressName: "阜城县",
            parentId: "131100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 131171,
            addressName: "河北衡水高新技术产业开发区",
            parentId: "131100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 131172,
            addressName: "衡水滨湖新区",
            parentId: "131100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 131182,
            addressName: "深州市",
            parentId: "131100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
    ],
  },
  {
    id: 140000,
    addressName: "山西省",
    parentId: "86",
    updateTime: null,
    addressList: [
      {
        id: 140100,
        addressName: "太原市",
        parentId: "140000",
        updateTime: null,
        addressList: [
          {
            id: 140101,
            addressName: "市辖区",
            parentId: "140100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140105,
            addressName: "小店区",
            parentId: "140100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140106,
            addressName: "迎泽区",
            parentId: "140100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140107,
            addressName: "杏花岭区",
            parentId: "140100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140108,
            addressName: "尖草坪区",
            parentId: "140100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140109,
            addressName: "万柏林区",
            parentId: "140100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140110,
            addressName: "晋源区",
            parentId: "140100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140121,
            addressName: "清徐县",
            parentId: "140100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140122,
            addressName: "阳曲县",
            parentId: "140100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140123,
            addressName: "娄烦县",
            parentId: "140100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140171,
            addressName: "山西转型综合改革示范区",
            parentId: "140100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140181,
            addressName: "古交市",
            parentId: "140100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 140200,
        addressName: "大同市",
        parentId: "140000",
        updateTime: null,
        addressList: [
          {
            id: 140201,
            addressName: "市辖区",
            parentId: "140200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140212,
            addressName: "新荣区",
            parentId: "140200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140213,
            addressName: "平城区",
            parentId: "140200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140214,
            addressName: "云冈区",
            parentId: "140200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140215,
            addressName: "云州区",
            parentId: "140200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140221,
            addressName: "阳高县",
            parentId: "140200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140222,
            addressName: "天镇县",
            parentId: "140200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140223,
            addressName: "广灵县",
            parentId: "140200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140224,
            addressName: "灵丘县",
            parentId: "140200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140225,
            addressName: "浑源县",
            parentId: "140200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140226,
            addressName: "左云县",
            parentId: "140200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140271,
            addressName: "山西大同经济开发区",
            parentId: "140200",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 140300,
        addressName: "阳泉市",
        parentId: "140000",
        updateTime: null,
        addressList: [
          {
            id: 140301,
            addressName: "市辖区",
            parentId: "140300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140302,
            addressName: "城区",
            parentId: "140300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140303,
            addressName: "矿区",
            parentId: "140300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140311,
            addressName: "郊区",
            parentId: "140300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140321,
            addressName: "平定县",
            parentId: "140300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140322,
            addressName: "盂县",
            parentId: "140300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 140400,
        addressName: "长治市",
        parentId: "140000",
        updateTime: null,
        addressList: [
          {
            id: 140401,
            addressName: "市辖区",
            parentId: "140400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140403,
            addressName: "潞州区",
            parentId: "140400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140404,
            addressName: "上党区",
            parentId: "140400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140405,
            addressName: "屯留区",
            parentId: "140400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140406,
            addressName: "潞城区",
            parentId: "140400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140423,
            addressName: "襄垣县",
            parentId: "140400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140425,
            addressName: "平顺县",
            parentId: "140400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140426,
            addressName: "黎城县",
            parentId: "140400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140427,
            addressName: "壶关县",
            parentId: "140400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140428,
            addressName: "长子县",
            parentId: "140400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140429,
            addressName: "武乡县",
            parentId: "140400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140430,
            addressName: "沁县",
            parentId: "140400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140431,
            addressName: "沁源县",
            parentId: "140400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140471,
            addressName: "山西长治高新技术产业园区",
            parentId: "140400",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 140500,
        addressName: "晋城市",
        parentId: "140000",
        updateTime: null,
        addressList: [
          {
            id: 140501,
            addressName: "市辖区",
            parentId: "140500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140502,
            addressName: "城区",
            parentId: "140500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140521,
            addressName: "沁水县",
            parentId: "140500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140522,
            addressName: "阳城县",
            parentId: "140500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140524,
            addressName: "陵川县",
            parentId: "140500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140525,
            addressName: "泽州县",
            parentId: "140500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140581,
            addressName: "高平市",
            parentId: "140500",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 140600,
        addressName: "朔州市",
        parentId: "140000",
        updateTime: null,
        addressList: [
          {
            id: 140601,
            addressName: "市辖区",
            parentId: "140600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140602,
            addressName: "朔城区",
            parentId: "140600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140603,
            addressName: "平鲁区",
            parentId: "140600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140621,
            addressName: "山阴县",
            parentId: "140600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140622,
            addressName: "应县",
            parentId: "140600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140623,
            addressName: "右玉县",
            parentId: "140600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140671,
            addressName: "山西朔州经济开发区",
            parentId: "140600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140681,
            addressName: "怀仁市",
            parentId: "140600",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 140700,
        addressName: "晋中市",
        parentId: "140000",
        updateTime: null,
        addressList: [
          {
            id: 140701,
            addressName: "市辖区",
            parentId: "140700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140702,
            addressName: "榆次区",
            parentId: "140700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140721,
            addressName: "榆社县",
            parentId: "140700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140722,
            addressName: "左权县",
            parentId: "140700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140723,
            addressName: "和顺县",
            parentId: "140700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140724,
            addressName: "昔阳县",
            parentId: "140700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140725,
            addressName: "寿阳县",
            parentId: "140700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140726,
            addressName: "太谷县",
            parentId: "140700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140727,
            addressName: "祁县",
            parentId: "140700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140728,
            addressName: "平遥县",
            parentId: "140700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140729,
            addressName: "灵石县",
            parentId: "140700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140781,
            addressName: "介休市",
            parentId: "140700",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 140800,
        addressName: "运城市",
        parentId: "140000",
        updateTime: null,
        addressList: [
          {
            id: 140801,
            addressName: "市辖区",
            parentId: "140800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140802,
            addressName: "盐湖区",
            parentId: "140800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140821,
            addressName: "临猗县",
            parentId: "140800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140822,
            addressName: "万荣县",
            parentId: "140800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140823,
            addressName: "闻喜县",
            parentId: "140800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140824,
            addressName: "稷山县",
            parentId: "140800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140825,
            addressName: "新绛县",
            parentId: "140800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140826,
            addressName: "绛县",
            parentId: "140800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140827,
            addressName: "垣曲县",
            parentId: "140800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140828,
            addressName: "夏县",
            parentId: "140800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140829,
            addressName: "平陆县",
            parentId: "140800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140830,
            addressName: "芮城县",
            parentId: "140800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140881,
            addressName: "永济市",
            parentId: "140800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140882,
            addressName: "河津市",
            parentId: "140800",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 140900,
        addressName: "忻州市",
        parentId: "140000",
        updateTime: null,
        addressList: [
          {
            id: 140901,
            addressName: "市辖区",
            parentId: "140900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140902,
            addressName: "忻府区",
            parentId: "140900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140921,
            addressName: "定襄县",
            parentId: "140900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140922,
            addressName: "五台县",
            parentId: "140900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140923,
            addressName: "代县",
            parentId: "140900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140924,
            addressName: "繁峙县",
            parentId: "140900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140925,
            addressName: "宁武县",
            parentId: "140900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140926,
            addressName: "静乐县",
            parentId: "140900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140927,
            addressName: "神池县",
            parentId: "140900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140928,
            addressName: "五寨县",
            parentId: "140900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140929,
            addressName: "岢岚县",
            parentId: "140900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140930,
            addressName: "河曲县",
            parentId: "140900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140931,
            addressName: "保德县",
            parentId: "140900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140932,
            addressName: "偏关县",
            parentId: "140900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140971,
            addressName: "五台山风景名胜区",
            parentId: "140900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 140981,
            addressName: "原平市",
            parentId: "140900",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 141000,
        addressName: "临汾市",
        parentId: "140000",
        updateTime: null,
        addressList: [
          {
            id: 141001,
            addressName: "市辖区",
            parentId: "141000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 141002,
            addressName: "尧都区",
            parentId: "141000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 141021,
            addressName: "曲沃县",
            parentId: "141000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 141022,
            addressName: "翼城县",
            parentId: "141000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 141023,
            addressName: "襄汾县",
            parentId: "141000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 141024,
            addressName: "洪洞县",
            parentId: "141000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 141025,
            addressName: "古县",
            parentId: "141000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 141026,
            addressName: "安泽县",
            parentId: "141000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 141027,
            addressName: "浮山县",
            parentId: "141000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 141028,
            addressName: "吉县",
            parentId: "141000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 141029,
            addressName: "乡宁县",
            parentId: "141000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 141030,
            addressName: "大宁县",
            parentId: "141000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 141031,
            addressName: "隰县",
            parentId: "141000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 141032,
            addressName: "永和县",
            parentId: "141000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 141033,
            addressName: "蒲县",
            parentId: "141000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 141034,
            addressName: "汾西县",
            parentId: "141000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 141081,
            addressName: "侯马市",
            parentId: "141000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 141082,
            addressName: "霍州市",
            parentId: "141000",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 141100,
        addressName: "吕梁市",
        parentId: "140000",
        updateTime: null,
        addressList: [
          {
            id: 141101,
            addressName: "市辖区",
            parentId: "141100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 141102,
            addressName: "离石区",
            parentId: "141100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 141121,
            addressName: "文水县",
            parentId: "141100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 141122,
            addressName: "交城县",
            parentId: "141100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 141123,
            addressName: "兴县",
            parentId: "141100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 141124,
            addressName: "临县",
            parentId: "141100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 141125,
            addressName: "柳林县",
            parentId: "141100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 141126,
            addressName: "石楼县",
            parentId: "141100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 141127,
            addressName: "岚县",
            parentId: "141100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 141128,
            addressName: "方山县",
            parentId: "141100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 141129,
            addressName: "中阳县",
            parentId: "141100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 141130,
            addressName: "交口县",
            parentId: "141100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 141181,
            addressName: "孝义市",
            parentId: "141100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 141182,
            addressName: "汾阳市",
            parentId: "141100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
    ],
  },
  {
    id: 150000,
    addressName: "内蒙古自治区",
    parentId: "86",
    updateTime: null,
    addressList: [
      {
        id: 150100,
        addressName: "呼和浩特市",
        parentId: "150000",
        updateTime: null,
        addressList: [
          {
            id: 150101,
            addressName: "市辖区",
            parentId: "150100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150102,
            addressName: "新城区",
            parentId: "150100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150103,
            addressName: "回民区",
            parentId: "150100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150104,
            addressName: "玉泉区",
            parentId: "150100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150105,
            addressName: "赛罕区",
            parentId: "150100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150121,
            addressName: "土默特左旗",
            parentId: "150100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150122,
            addressName: "托克托县",
            parentId: "150100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150123,
            addressName: "和林格尔县",
            parentId: "150100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150124,
            addressName: "清水河县",
            parentId: "150100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150125,
            addressName: "武川县",
            parentId: "150100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150171,
            addressName: "呼和浩特金海工业园区",
            parentId: "150100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150172,
            addressName: "呼和浩特经济技术开发区",
            parentId: "150100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 150200,
        addressName: "包头市",
        parentId: "150000",
        updateTime: null,
        addressList: [
          {
            id: 150201,
            addressName: "市辖区",
            parentId: "150200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150202,
            addressName: "东河区",
            parentId: "150200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150203,
            addressName: "昆都仑区",
            parentId: "150200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150204,
            addressName: "青山区",
            parentId: "150200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150205,
            addressName: "石拐区",
            parentId: "150200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150206,
            addressName: "白云鄂博矿区",
            parentId: "150200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150207,
            addressName: "九原区",
            parentId: "150200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150221,
            addressName: "土默特右旗",
            parentId: "150200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150222,
            addressName: "固阳县",
            parentId: "150200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150223,
            addressName: "达尔罕茂明安联合旗",
            parentId: "150200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150271,
            addressName: "包头稀土高新技术产业开发区",
            parentId: "150200",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 150300,
        addressName: "乌海市",
        parentId: "150000",
        updateTime: null,
        addressList: [
          {
            id: 150301,
            addressName: "市辖区",
            parentId: "150300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150302,
            addressName: "海勃湾区",
            parentId: "150300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150303,
            addressName: "海南区",
            parentId: "150300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150304,
            addressName: "乌达区",
            parentId: "150300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 150400,
        addressName: "赤峰市",
        parentId: "150000",
        updateTime: null,
        addressList: [
          {
            id: 150401,
            addressName: "市辖区",
            parentId: "150400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150402,
            addressName: "红山区",
            parentId: "150400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150403,
            addressName: "元宝山区",
            parentId: "150400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150404,
            addressName: "松山区",
            parentId: "150400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150421,
            addressName: "阿鲁科尔沁旗",
            parentId: "150400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150422,
            addressName: "巴林左旗",
            parentId: "150400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150423,
            addressName: "巴林右旗",
            parentId: "150400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150424,
            addressName: "林西县",
            parentId: "150400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150425,
            addressName: "克什克腾旗",
            parentId: "150400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150426,
            addressName: "翁牛特旗",
            parentId: "150400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150428,
            addressName: "喀喇沁旗",
            parentId: "150400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150429,
            addressName: "宁城县",
            parentId: "150400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150430,
            addressName: "敖汉旗",
            parentId: "150400",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 150500,
        addressName: "通辽市",
        parentId: "150000",
        updateTime: null,
        addressList: [
          {
            id: 150501,
            addressName: "市辖区",
            parentId: "150500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150502,
            addressName: "科尔沁区",
            parentId: "150500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150521,
            addressName: "科尔沁左翼中旗",
            parentId: "150500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150522,
            addressName: "科尔沁左翼后旗",
            parentId: "150500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150523,
            addressName: "开鲁县",
            parentId: "150500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150524,
            addressName: "库伦旗",
            parentId: "150500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150525,
            addressName: "奈曼旗",
            parentId: "150500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150526,
            addressName: "扎鲁特旗",
            parentId: "150500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150571,
            addressName: "通辽经济技术开发区",
            parentId: "150500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150581,
            addressName: "霍林郭勒市",
            parentId: "150500",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 150600,
        addressName: "鄂尔多斯市",
        parentId: "150000",
        updateTime: null,
        addressList: [
          {
            id: 150601,
            addressName: "市辖区",
            parentId: "150600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150602,
            addressName: "东胜区",
            parentId: "150600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150603,
            addressName: "康巴什区",
            parentId: "150600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150621,
            addressName: "达拉特旗",
            parentId: "150600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150622,
            addressName: "准格尔旗",
            parentId: "150600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150623,
            addressName: "鄂托克前旗",
            parentId: "150600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150624,
            addressName: "鄂托克旗",
            parentId: "150600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150625,
            addressName: "杭锦旗",
            parentId: "150600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150626,
            addressName: "乌审旗",
            parentId: "150600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150627,
            addressName: "伊金霍洛旗",
            parentId: "150600",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 150700,
        addressName: "呼伦贝尔市",
        parentId: "150000",
        updateTime: null,
        addressList: [
          {
            id: 150701,
            addressName: "市辖区",
            parentId: "150700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150702,
            addressName: "海拉尔区",
            parentId: "150700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150703,
            addressName: "扎赉诺尔区",
            parentId: "150700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150721,
            addressName: "阿荣旗",
            parentId: "150700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150722,
            addressName: "莫力达瓦达斡尔族自治旗",
            parentId: "150700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150723,
            addressName: "鄂伦春自治旗",
            parentId: "150700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150724,
            addressName: "鄂温克族自治旗",
            parentId: "150700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150725,
            addressName: "陈巴尔虎旗",
            parentId: "150700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150726,
            addressName: "新巴尔虎左旗",
            parentId: "150700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150727,
            addressName: "新巴尔虎右旗",
            parentId: "150700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150781,
            addressName: "满洲里市",
            parentId: "150700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150782,
            addressName: "牙克石市",
            parentId: "150700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150783,
            addressName: "扎兰屯市",
            parentId: "150700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150784,
            addressName: "额尔古纳市",
            parentId: "150700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150785,
            addressName: "根河市",
            parentId: "150700",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 150800,
        addressName: "巴彦淖尔市",
        parentId: "150000",
        updateTime: null,
        addressList: [
          {
            id: 150801,
            addressName: "市辖区",
            parentId: "150800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150802,
            addressName: "临河区",
            parentId: "150800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150821,
            addressName: "五原县",
            parentId: "150800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150822,
            addressName: "磴口县",
            parentId: "150800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150823,
            addressName: "乌拉特前旗",
            parentId: "150800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150824,
            addressName: "乌拉特中旗",
            parentId: "150800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150825,
            addressName: "乌拉特后旗",
            parentId: "150800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150826,
            addressName: "杭锦后旗",
            parentId: "150800",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 150900,
        addressName: "乌兰察布市",
        parentId: "150000",
        updateTime: null,
        addressList: [
          {
            id: 150901,
            addressName: "市辖区",
            parentId: "150900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150902,
            addressName: "集宁区",
            parentId: "150900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150921,
            addressName: "卓资县",
            parentId: "150900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150922,
            addressName: "化德县",
            parentId: "150900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150923,
            addressName: "商都县",
            parentId: "150900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150924,
            addressName: "兴和县",
            parentId: "150900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150925,
            addressName: "凉城县",
            parentId: "150900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150926,
            addressName: "察哈尔右翼前旗",
            parentId: "150900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150927,
            addressName: "察哈尔右翼中旗",
            parentId: "150900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150928,
            addressName: "察哈尔右翼后旗",
            parentId: "150900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150929,
            addressName: "四子王旗",
            parentId: "150900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 150981,
            addressName: "丰镇市",
            parentId: "150900",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 152200,
        addressName: "兴安盟",
        parentId: "150000",
        updateTime: null,
        addressList: [
          {
            id: 152201,
            addressName: "乌兰浩特市",
            parentId: "152200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 152202,
            addressName: "阿尔山市",
            parentId: "152200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 152221,
            addressName: "科尔沁右翼前旗",
            parentId: "152200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 152222,
            addressName: "科尔沁右翼中旗",
            parentId: "152200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 152223,
            addressName: "扎赉特旗",
            parentId: "152200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 152224,
            addressName: "突泉县",
            parentId: "152200",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 152500,
        addressName: "锡林郭勒盟",
        parentId: "150000",
        updateTime: null,
        addressList: [
          {
            id: 152501,
            addressName: "二连浩特市",
            parentId: "152500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 152502,
            addressName: "锡林浩特市",
            parentId: "152500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 152522,
            addressName: "阿巴嘎旗",
            parentId: "152500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 152523,
            addressName: "苏尼特左旗",
            parentId: "152500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 152524,
            addressName: "苏尼特右旗",
            parentId: "152500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 152525,
            addressName: "东乌珠穆沁旗",
            parentId: "152500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 152526,
            addressName: "西乌珠穆沁旗",
            parentId: "152500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 152527,
            addressName: "太仆寺旗",
            parentId: "152500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 152528,
            addressName: "镶黄旗",
            parentId: "152500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 152529,
            addressName: "正镶白旗",
            parentId: "152500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 152530,
            addressName: "正蓝旗",
            parentId: "152500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 152531,
            addressName: "多伦县",
            parentId: "152500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 152571,
            addressName: "乌拉盖管委会",
            parentId: "152500",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 152900,
        addressName: "阿拉善盟",
        parentId: "150000",
        updateTime: null,
        addressList: [
          {
            id: 152921,
            addressName: "阿拉善左旗",
            parentId: "152900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 152922,
            addressName: "阿拉善右旗",
            parentId: "152900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 152923,
            addressName: "额济纳旗",
            parentId: "152900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 152971,
            addressName: "内蒙古阿拉善经济开发区",
            parentId: "152900",
            updateTime: null,
            addressList: null,
          },
        ],
      },
    ],
  },
  {
    id: 210000,
    addressName: "辽宁省",
    parentId: "86",
    updateTime: null,
    addressList: [
      {
        id: 210100,
        addressName: "沈阳市",
        parentId: "210000",
        updateTime: null,
        addressList: [
          {
            id: 210101,
            addressName: "市辖区",
            parentId: "210100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210102,
            addressName: "和平区",
            parentId: "210100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210103,
            addressName: "沈河区",
            parentId: "210100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210104,
            addressName: "大东区",
            parentId: "210100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210105,
            addressName: "皇姑区",
            parentId: "210100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210106,
            addressName: "铁西区",
            parentId: "210100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210111,
            addressName: "苏家屯区",
            parentId: "210100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210112,
            addressName: "浑南区",
            parentId: "210100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210113,
            addressName: "沈北新区",
            parentId: "210100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210114,
            addressName: "于洪区",
            parentId: "210100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210115,
            addressName: "辽中区",
            parentId: "210100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210123,
            addressName: "康平县",
            parentId: "210100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210124,
            addressName: "法库县",
            parentId: "210100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210181,
            addressName: "新民市",
            parentId: "210100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 210200,
        addressName: "大连市",
        parentId: "210000",
        updateTime: null,
        addressList: [
          {
            id: 210201,
            addressName: "市辖区",
            parentId: "210200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210202,
            addressName: "中山区",
            parentId: "210200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210203,
            addressName: "西岗区",
            parentId: "210200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210204,
            addressName: "沙河口区",
            parentId: "210200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210211,
            addressName: "甘井子区",
            parentId: "210200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210212,
            addressName: "旅顺口区",
            parentId: "210200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210213,
            addressName: "金州区",
            parentId: "210200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210214,
            addressName: "普兰店区",
            parentId: "210200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210224,
            addressName: "长海县",
            parentId: "210200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210281,
            addressName: "瓦房店市",
            parentId: "210200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210283,
            addressName: "庄河市",
            parentId: "210200",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 210300,
        addressName: "鞍山市",
        parentId: "210000",
        updateTime: null,
        addressList: [
          {
            id: 210301,
            addressName: "市辖区",
            parentId: "210300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210302,
            addressName: "铁东区",
            parentId: "210300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210303,
            addressName: "铁西区",
            parentId: "210300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210304,
            addressName: "立山区",
            parentId: "210300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210311,
            addressName: "千山区",
            parentId: "210300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210321,
            addressName: "台安县",
            parentId: "210300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210323,
            addressName: "岫岩满族自治县",
            parentId: "210300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210381,
            addressName: "海城市",
            parentId: "210300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 210400,
        addressName: "抚顺市",
        parentId: "210000",
        updateTime: null,
        addressList: [
          {
            id: 210401,
            addressName: "市辖区",
            parentId: "210400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210402,
            addressName: "新抚区",
            parentId: "210400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210403,
            addressName: "东洲区",
            parentId: "210400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210404,
            addressName: "望花区",
            parentId: "210400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210411,
            addressName: "顺城区",
            parentId: "210400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210421,
            addressName: "抚顺县",
            parentId: "210400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210422,
            addressName: "新宾满族自治县",
            parentId: "210400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210423,
            addressName: "清原满族自治县",
            parentId: "210400",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 210500,
        addressName: "本溪市",
        parentId: "210000",
        updateTime: null,
        addressList: [
          {
            id: 210501,
            addressName: "市辖区",
            parentId: "210500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210502,
            addressName: "平山区",
            parentId: "210500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210503,
            addressName: "溪湖区",
            parentId: "210500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210504,
            addressName: "明山区",
            parentId: "210500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210505,
            addressName: "南芬区",
            parentId: "210500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210521,
            addressName: "本溪满族自治县",
            parentId: "210500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210522,
            addressName: "桓仁满族自治县",
            parentId: "210500",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 210600,
        addressName: "丹东市",
        parentId: "210000",
        updateTime: null,
        addressList: [
          {
            id: 210601,
            addressName: "市辖区",
            parentId: "210600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210602,
            addressName: "元宝区",
            parentId: "210600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210603,
            addressName: "振兴区",
            parentId: "210600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210604,
            addressName: "振安区",
            parentId: "210600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210624,
            addressName: "宽甸满族自治县",
            parentId: "210600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210681,
            addressName: "东港市",
            parentId: "210600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210682,
            addressName: "凤城市",
            parentId: "210600",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 210700,
        addressName: "锦州市",
        parentId: "210000",
        updateTime: null,
        addressList: [
          {
            id: 210701,
            addressName: "市辖区",
            parentId: "210700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210702,
            addressName: "古塔区",
            parentId: "210700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210703,
            addressName: "凌河区",
            parentId: "210700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210711,
            addressName: "太和区",
            parentId: "210700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210726,
            addressName: "黑山县",
            parentId: "210700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210727,
            addressName: "义县",
            parentId: "210700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210781,
            addressName: "凌海市",
            parentId: "210700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210782,
            addressName: "北镇市",
            parentId: "210700",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 210800,
        addressName: "营口市",
        parentId: "210000",
        updateTime: null,
        addressList: [
          {
            id: 210801,
            addressName: "市辖区",
            parentId: "210800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210802,
            addressName: "站前区",
            parentId: "210800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210803,
            addressName: "西市区",
            parentId: "210800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210804,
            addressName: "鲅鱼圈区",
            parentId: "210800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210811,
            addressName: "老边区",
            parentId: "210800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210881,
            addressName: "盖州市",
            parentId: "210800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210882,
            addressName: "大石桥市",
            parentId: "210800",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 210900,
        addressName: "阜新市",
        parentId: "210000",
        updateTime: null,
        addressList: [
          {
            id: 210901,
            addressName: "市辖区",
            parentId: "210900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210902,
            addressName: "海州区",
            parentId: "210900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210903,
            addressName: "新邱区",
            parentId: "210900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210904,
            addressName: "太平区",
            parentId: "210900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210905,
            addressName: "清河门区",
            parentId: "210900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210911,
            addressName: "细河区",
            parentId: "210900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210921,
            addressName: "阜新蒙古族自治县",
            parentId: "210900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 210922,
            addressName: "彰武县",
            parentId: "210900",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 211000,
        addressName: "辽阳市",
        parentId: "210000",
        updateTime: null,
        addressList: [
          {
            id: 211001,
            addressName: "市辖区",
            parentId: "211000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 211002,
            addressName: "白塔区",
            parentId: "211000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 211003,
            addressName: "文圣区",
            parentId: "211000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 211004,
            addressName: "宏伟区",
            parentId: "211000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 211005,
            addressName: "弓长岭区",
            parentId: "211000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 211011,
            addressName: "太子河区",
            parentId: "211000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 211021,
            addressName: "辽阳县",
            parentId: "211000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 211081,
            addressName: "灯塔市",
            parentId: "211000",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 211100,
        addressName: "盘锦市",
        parentId: "210000",
        updateTime: null,
        addressList: [
          {
            id: 211101,
            addressName: "市辖区",
            parentId: "211100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 211102,
            addressName: "双台子区",
            parentId: "211100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 211103,
            addressName: "兴隆台区",
            parentId: "211100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 211104,
            addressName: "大洼区",
            parentId: "211100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 211122,
            addressName: "盘山县",
            parentId: "211100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 211200,
        addressName: "铁岭市",
        parentId: "210000",
        updateTime: null,
        addressList: [
          {
            id: 211201,
            addressName: "市辖区",
            parentId: "211200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 211202,
            addressName: "银州区",
            parentId: "211200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 211204,
            addressName: "清河区",
            parentId: "211200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 211221,
            addressName: "铁岭县",
            parentId: "211200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 211223,
            addressName: "西丰县",
            parentId: "211200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 211224,
            addressName: "昌图县",
            parentId: "211200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 211281,
            addressName: "调兵山市",
            parentId: "211200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 211282,
            addressName: "开原市",
            parentId: "211200",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 211300,
        addressName: "朝阳市",
        parentId: "210000",
        updateTime: null,
        addressList: [
          {
            id: 211301,
            addressName: "市辖区",
            parentId: "211300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 211302,
            addressName: "双塔区",
            parentId: "211300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 211303,
            addressName: "龙城区",
            parentId: "211300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 211321,
            addressName: "朝阳县",
            parentId: "211300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 211322,
            addressName: "建平县",
            parentId: "211300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 211324,
            addressName: "喀喇沁左翼蒙古族自治县",
            parentId: "211300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 211381,
            addressName: "北票市",
            parentId: "211300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 211382,
            addressName: "凌源市",
            parentId: "211300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 211400,
        addressName: "葫芦岛市",
        parentId: "210000",
        updateTime: null,
        addressList: [
          {
            id: 211401,
            addressName: "市辖区",
            parentId: "211400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 211402,
            addressName: "连山区",
            parentId: "211400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 211403,
            addressName: "龙港区",
            parentId: "211400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 211404,
            addressName: "南票区",
            parentId: "211400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 211421,
            addressName: "绥中县",
            parentId: "211400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 211422,
            addressName: "建昌县",
            parentId: "211400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 211481,
            addressName: "兴城市",
            parentId: "211400",
            updateTime: null,
            addressList: null,
          },
        ],
      },
    ],
  },
  {
    id: 220000,
    addressName: "吉林省",
    parentId: "86",
    updateTime: null,
    addressList: [
      {
        id: 220100,
        addressName: "长春市",
        parentId: "220000",
        updateTime: null,
        addressList: [
          {
            id: 220101,
            addressName: "市辖区",
            parentId: "220100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220102,
            addressName: "南关区",
            parentId: "220100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220103,
            addressName: "宽城区",
            parentId: "220100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220104,
            addressName: "朝阳区",
            parentId: "220100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220105,
            addressName: "二道区",
            parentId: "220100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220106,
            addressName: "绿园区",
            parentId: "220100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220112,
            addressName: "双阳区",
            parentId: "220100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220113,
            addressName: "九台区",
            parentId: "220100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220122,
            addressName: "农安县",
            parentId: "220100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220171,
            addressName: "长春经济技术开发区",
            parentId: "220100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220172,
            addressName: "长春净月高新技术产业开发区",
            parentId: "220100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220173,
            addressName: "长春高新技术产业开发区",
            parentId: "220100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220174,
            addressName: "长春汽车经济技术开发区",
            parentId: "220100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220182,
            addressName: "榆树市",
            parentId: "220100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220183,
            addressName: "德惠市",
            parentId: "220100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 220200,
        addressName: "吉林市",
        parentId: "220000",
        updateTime: null,
        addressList: [
          {
            id: 220201,
            addressName: "市辖区",
            parentId: "220200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220202,
            addressName: "昌邑区",
            parentId: "220200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220203,
            addressName: "龙潭区",
            parentId: "220200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220204,
            addressName: "船营区",
            parentId: "220200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220211,
            addressName: "丰满区",
            parentId: "220200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220221,
            addressName: "永吉县",
            parentId: "220200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220271,
            addressName: "吉林经济开发区",
            parentId: "220200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220272,
            addressName: "吉林高新技术产业开发区",
            parentId: "220200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220273,
            addressName: "吉林中国新加坡食品区",
            parentId: "220200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220281,
            addressName: "蛟河市",
            parentId: "220200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220282,
            addressName: "桦甸市",
            parentId: "220200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220283,
            addressName: "舒兰市",
            parentId: "220200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220284,
            addressName: "磐石市",
            parentId: "220200",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 220300,
        addressName: "四平市",
        parentId: "220000",
        updateTime: null,
        addressList: [
          {
            id: 220301,
            addressName: "市辖区",
            parentId: "220300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220302,
            addressName: "铁西区",
            parentId: "220300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220303,
            addressName: "铁东区",
            parentId: "220300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220322,
            addressName: "梨树县",
            parentId: "220300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220323,
            addressName: "伊通满族自治县",
            parentId: "220300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220381,
            addressName: "公主岭市",
            parentId: "220300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220382,
            addressName: "双辽市",
            parentId: "220300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 220400,
        addressName: "辽源市",
        parentId: "220000",
        updateTime: null,
        addressList: [
          {
            id: 220401,
            addressName: "市辖区",
            parentId: "220400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220402,
            addressName: "龙山区",
            parentId: "220400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220403,
            addressName: "西安区",
            parentId: "220400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220421,
            addressName: "东丰县",
            parentId: "220400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220422,
            addressName: "东辽县",
            parentId: "220400",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 220500,
        addressName: "通化市",
        parentId: "220000",
        updateTime: null,
        addressList: [
          {
            id: 220501,
            addressName: "市辖区",
            parentId: "220500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220502,
            addressName: "东昌区",
            parentId: "220500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220503,
            addressName: "二道江区",
            parentId: "220500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220521,
            addressName: "通化县",
            parentId: "220500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220523,
            addressName: "辉南县",
            parentId: "220500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220524,
            addressName: "柳河县",
            parentId: "220500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220581,
            addressName: "梅河口市",
            parentId: "220500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220582,
            addressName: "集安市",
            parentId: "220500",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 220600,
        addressName: "白山市",
        parentId: "220000",
        updateTime: null,
        addressList: [
          {
            id: 220601,
            addressName: "市辖区",
            parentId: "220600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220602,
            addressName: "浑江区",
            parentId: "220600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220605,
            addressName: "江源区",
            parentId: "220600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220621,
            addressName: "抚松县",
            parentId: "220600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220622,
            addressName: "靖宇县",
            parentId: "220600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220623,
            addressName: "长白朝鲜族自治县",
            parentId: "220600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220681,
            addressName: "临江市",
            parentId: "220600",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 220700,
        addressName: "松原市",
        parentId: "220000",
        updateTime: null,
        addressList: [
          {
            id: 220701,
            addressName: "市辖区",
            parentId: "220700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220702,
            addressName: "宁江区",
            parentId: "220700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220721,
            addressName: "前郭尔罗斯蒙古族自治县",
            parentId: "220700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220722,
            addressName: "长岭县",
            parentId: "220700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220723,
            addressName: "乾安县",
            parentId: "220700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220771,
            addressName: "吉林松原经济开发区",
            parentId: "220700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220781,
            addressName: "扶余市",
            parentId: "220700",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 220800,
        addressName: "白城市",
        parentId: "220000",
        updateTime: null,
        addressList: [
          {
            id: 220801,
            addressName: "市辖区",
            parentId: "220800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220802,
            addressName: "洮北区",
            parentId: "220800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220821,
            addressName: "镇赉县",
            parentId: "220800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220822,
            addressName: "通榆县",
            parentId: "220800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220871,
            addressName: "吉林白城经济开发区",
            parentId: "220800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220881,
            addressName: "洮南市",
            parentId: "220800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 220882,
            addressName: "大安市",
            parentId: "220800",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 222400,
        addressName: "延边朝鲜族自治州",
        parentId: "220000",
        updateTime: null,
        addressList: [
          {
            id: 222401,
            addressName: "延吉市",
            parentId: "222400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 222402,
            addressName: "图们市",
            parentId: "222400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 222403,
            addressName: "敦化市",
            parentId: "222400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 222404,
            addressName: "珲春市",
            parentId: "222400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 222405,
            addressName: "龙井市",
            parentId: "222400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 222406,
            addressName: "和龙市",
            parentId: "222400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 222424,
            addressName: "汪清县",
            parentId: "222400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 222426,
            addressName: "安图县",
            parentId: "222400",
            updateTime: null,
            addressList: null,
          },
        ],
      },
    ],
  },
  {
    id: 230000,
    addressName: "黑龙江省",
    parentId: "86",
    updateTime: null,
    addressList: [
      {
        id: 230100,
        addressName: "哈尔滨市",
        parentId: "230000",
        updateTime: null,
        addressList: [
          {
            id: 230101,
            addressName: "市辖区",
            parentId: "230100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230102,
            addressName: "道里区",
            parentId: "230100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230103,
            addressName: "南岗区",
            parentId: "230100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230104,
            addressName: "道外区",
            parentId: "230100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230108,
            addressName: "平房区",
            parentId: "230100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230109,
            addressName: "松北区",
            parentId: "230100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230110,
            addressName: "香坊区",
            parentId: "230100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230111,
            addressName: "呼兰区",
            parentId: "230100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230112,
            addressName: "阿城区",
            parentId: "230100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230113,
            addressName: "双城区",
            parentId: "230100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230123,
            addressName: "依兰县",
            parentId: "230100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230124,
            addressName: "方正县",
            parentId: "230100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230125,
            addressName: "宾县",
            parentId: "230100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230126,
            addressName: "巴彦县",
            parentId: "230100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230127,
            addressName: "木兰县",
            parentId: "230100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230128,
            addressName: "通河县",
            parentId: "230100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230129,
            addressName: "延寿县",
            parentId: "230100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230183,
            addressName: "尚志市",
            parentId: "230100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230184,
            addressName: "五常市",
            parentId: "230100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 230200,
        addressName: "齐齐哈尔市",
        parentId: "230000",
        updateTime: null,
        addressList: [
          {
            id: 230201,
            addressName: "市辖区",
            parentId: "230200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230202,
            addressName: "龙沙区",
            parentId: "230200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230203,
            addressName: "建华区",
            parentId: "230200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230204,
            addressName: "铁锋区",
            parentId: "230200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230205,
            addressName: "昂昂溪区",
            parentId: "230200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230206,
            addressName: "富拉尔基区",
            parentId: "230200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230207,
            addressName: "碾子山区",
            parentId: "230200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230208,
            addressName: "梅里斯达斡尔族区",
            parentId: "230200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230221,
            addressName: "龙江县",
            parentId: "230200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230223,
            addressName: "依安县",
            parentId: "230200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230224,
            addressName: "泰来县",
            parentId: "230200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230225,
            addressName: "甘南县",
            parentId: "230200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230227,
            addressName: "富裕县",
            parentId: "230200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230229,
            addressName: "克山县",
            parentId: "230200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230230,
            addressName: "克东县",
            parentId: "230200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230231,
            addressName: "拜泉县",
            parentId: "230200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230281,
            addressName: "讷河市",
            parentId: "230200",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 230300,
        addressName: "鸡西市",
        parentId: "230000",
        updateTime: null,
        addressList: [
          {
            id: 230301,
            addressName: "市辖区",
            parentId: "230300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230302,
            addressName: "鸡冠区",
            parentId: "230300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230303,
            addressName: "恒山区",
            parentId: "230300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230304,
            addressName: "滴道区",
            parentId: "230300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230305,
            addressName: "梨树区",
            parentId: "230300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230306,
            addressName: "城子河区",
            parentId: "230300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230307,
            addressName: "麻山区",
            parentId: "230300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230321,
            addressName: "鸡东县",
            parentId: "230300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230381,
            addressName: "虎林市",
            parentId: "230300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230382,
            addressName: "密山市",
            parentId: "230300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 230400,
        addressName: "鹤岗市",
        parentId: "230000",
        updateTime: null,
        addressList: [
          {
            id: 230401,
            addressName: "市辖区",
            parentId: "230400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230402,
            addressName: "向阳区",
            parentId: "230400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230403,
            addressName: "工农区",
            parentId: "230400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230404,
            addressName: "南山区",
            parentId: "230400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230405,
            addressName: "兴安区",
            parentId: "230400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230406,
            addressName: "东山区",
            parentId: "230400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230407,
            addressName: "兴山区",
            parentId: "230400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230421,
            addressName: "萝北县",
            parentId: "230400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230422,
            addressName: "绥滨县",
            parentId: "230400",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 230500,
        addressName: "双鸭山市",
        parentId: "230000",
        updateTime: null,
        addressList: [
          {
            id: 230501,
            addressName: "市辖区",
            parentId: "230500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230502,
            addressName: "尖山区",
            parentId: "230500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230503,
            addressName: "岭东区",
            parentId: "230500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230505,
            addressName: "四方台区",
            parentId: "230500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230506,
            addressName: "宝山区",
            parentId: "230500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230521,
            addressName: "集贤县",
            parentId: "230500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230522,
            addressName: "友谊县",
            parentId: "230500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230523,
            addressName: "宝清县",
            parentId: "230500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230524,
            addressName: "饶河县",
            parentId: "230500",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 230600,
        addressName: "大庆市",
        parentId: "230000",
        updateTime: null,
        addressList: [
          {
            id: 230601,
            addressName: "市辖区",
            parentId: "230600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230602,
            addressName: "萨尔图区",
            parentId: "230600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230603,
            addressName: "龙凤区",
            parentId: "230600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230604,
            addressName: "让胡路区",
            parentId: "230600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230605,
            addressName: "红岗区",
            parentId: "230600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230606,
            addressName: "大同区",
            parentId: "230600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230621,
            addressName: "肇州县",
            parentId: "230600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230622,
            addressName: "肇源县",
            parentId: "230600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230623,
            addressName: "林甸县",
            parentId: "230600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230624,
            addressName: "杜尔伯特蒙古族自治县",
            parentId: "230600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230671,
            addressName: "大庆高新技术产业开发区",
            parentId: "230600",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 230700,
        addressName: "伊春市",
        parentId: "230000",
        updateTime: null,
        addressList: [
          {
            id: 230701,
            addressName: "市辖区",
            parentId: "230700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230717,
            addressName: "伊美区",
            parentId: "230700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230718,
            addressName: "乌翠区",
            parentId: "230700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230719,
            addressName: "友好区",
            parentId: "230700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230722,
            addressName: "嘉荫县",
            parentId: "230700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230723,
            addressName: "汤旺县",
            parentId: "230700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230724,
            addressName: "丰林县",
            parentId: "230700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230725,
            addressName: "大箐山县",
            parentId: "230700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230726,
            addressName: "南岔县",
            parentId: "230700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230751,
            addressName: "金林区",
            parentId: "230700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230781,
            addressName: "铁力市",
            parentId: "230700",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 230800,
        addressName: "佳木斯市",
        parentId: "230000",
        updateTime: null,
        addressList: [
          {
            id: 230801,
            addressName: "市辖区",
            parentId: "230800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230803,
            addressName: "向阳区",
            parentId: "230800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230804,
            addressName: "前进区",
            parentId: "230800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230805,
            addressName: "东风区",
            parentId: "230800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230811,
            addressName: "郊区",
            parentId: "230800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230822,
            addressName: "桦南县",
            parentId: "230800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230826,
            addressName: "桦川县",
            parentId: "230800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230828,
            addressName: "汤原县",
            parentId: "230800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230881,
            addressName: "同江市",
            parentId: "230800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230882,
            addressName: "富锦市",
            parentId: "230800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230883,
            addressName: "抚远市",
            parentId: "230800",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 230900,
        addressName: "七台河市",
        parentId: "230000",
        updateTime: null,
        addressList: [
          {
            id: 230901,
            addressName: "市辖区",
            parentId: "230900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230902,
            addressName: "新兴区",
            parentId: "230900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230903,
            addressName: "桃山区",
            parentId: "230900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230904,
            addressName: "茄子河区",
            parentId: "230900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 230921,
            addressName: "勃利县",
            parentId: "230900",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 231000,
        addressName: "牡丹江市",
        parentId: "230000",
        updateTime: null,
        addressList: [
          {
            id: 231001,
            addressName: "市辖区",
            parentId: "231000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 231002,
            addressName: "东安区",
            parentId: "231000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 231003,
            addressName: "阳明区",
            parentId: "231000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 231004,
            addressName: "爱民区",
            parentId: "231000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 231005,
            addressName: "西安区",
            parentId: "231000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 231025,
            addressName: "林口县",
            parentId: "231000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 231071,
            addressName: "牡丹江经济技术开发区",
            parentId: "231000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 231081,
            addressName: "绥芬河市",
            parentId: "231000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 231083,
            addressName: "海林市",
            parentId: "231000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 231084,
            addressName: "宁安市",
            parentId: "231000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 231085,
            addressName: "穆棱市",
            parentId: "231000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 231086,
            addressName: "东宁市",
            parentId: "231000",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 231100,
        addressName: "黑河市",
        parentId: "230000",
        updateTime: null,
        addressList: [
          {
            id: 231101,
            addressName: "市辖区",
            parentId: "231100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 231102,
            addressName: "爱辉区",
            parentId: "231100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 231123,
            addressName: "逊克县",
            parentId: "231100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 231124,
            addressName: "孙吴县",
            parentId: "231100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 231181,
            addressName: "北安市",
            parentId: "231100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 231182,
            addressName: "五大连池市",
            parentId: "231100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 231183,
            addressName: "嫩江市",
            parentId: "231100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 231200,
        addressName: "绥化市",
        parentId: "230000",
        updateTime: null,
        addressList: [
          {
            id: 231201,
            addressName: "市辖区",
            parentId: "231200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 231202,
            addressName: "北林区",
            parentId: "231200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 231221,
            addressName: "望奎县",
            parentId: "231200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 231222,
            addressName: "兰西县",
            parentId: "231200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 231223,
            addressName: "青冈县",
            parentId: "231200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 231224,
            addressName: "庆安县",
            parentId: "231200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 231225,
            addressName: "明水县",
            parentId: "231200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 231226,
            addressName: "绥棱县",
            parentId: "231200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 231281,
            addressName: "安达市",
            parentId: "231200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 231282,
            addressName: "肇东市",
            parentId: "231200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 231283,
            addressName: "海伦市",
            parentId: "231200",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 232700,
        addressName: "大兴安岭地区",
        parentId: "230000",
        updateTime: null,
        addressList: [
          {
            id: 232701,
            addressName: "漠河市",
            parentId: "232700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 232721,
            addressName: "呼玛县",
            parentId: "232700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 232722,
            addressName: "塔河县",
            parentId: "232700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 232761,
            addressName: "加格达奇区",
            parentId: "232700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 232762,
            addressName: "松岭区",
            parentId: "232700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 232763,
            addressName: "新林区",
            parentId: "232700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 232764,
            addressName: "呼中区",
            parentId: "232700",
            updateTime: null,
            addressList: null,
          },
        ],
      },
    ],
  },
  {
    id: 310000,
    addressName: "上海市",
    parentId: "86",
    updateTime: null,
    addressList: [
      {
        id: 310100,
        addressName: "市辖区",
        parentId: "310000",
        updateTime: null,
        addressList: [
          {
            id: 310101,
            addressName: "黄浦区",
            parentId: "310100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 310104,
            addressName: "徐汇区",
            parentId: "310100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 310105,
            addressName: "长宁区",
            parentId: "310100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 310106,
            addressName: "静安区",
            parentId: "310100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 310107,
            addressName: "普陀区",
            parentId: "310100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 310109,
            addressName: "虹口区",
            parentId: "310100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 310110,
            addressName: "杨浦区",
            parentId: "310100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 310112,
            addressName: "闵行区",
            parentId: "310100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 310113,
            addressName: "宝山区",
            parentId: "310100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 310114,
            addressName: "嘉定区",
            parentId: "310100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 310115,
            addressName: "浦东新区",
            parentId: "310100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 310116,
            addressName: "金山区",
            parentId: "310100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 310117,
            addressName: "松江区",
            parentId: "310100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 310118,
            addressName: "青浦区",
            parentId: "310100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 310120,
            addressName: "奉贤区",
            parentId: "310100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 310151,
            addressName: "崇明区",
            parentId: "310100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
    ],
  },
  {
    id: 320000,
    addressName: "江苏省",
    parentId: "86",
    updateTime: null,
    addressList: [
      {
        id: 320100,
        addressName: "南京市",
        parentId: "320000",
        updateTime: null,
        addressList: [
          {
            id: 320101,
            addressName: "市辖区",
            parentId: "320100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320102,
            addressName: "玄武区",
            parentId: "320100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320104,
            addressName: "秦淮区",
            parentId: "320100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320105,
            addressName: "建邺区",
            parentId: "320100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320106,
            addressName: "鼓楼区",
            parentId: "320100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320111,
            addressName: "浦口区",
            parentId: "320100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320113,
            addressName: "栖霞区",
            parentId: "320100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320114,
            addressName: "雨花台区",
            parentId: "320100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320115,
            addressName: "江宁区",
            parentId: "320100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320116,
            addressName: "六合区",
            parentId: "320100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320117,
            addressName: "溧水区",
            parentId: "320100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320118,
            addressName: "高淳区",
            parentId: "320100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 320200,
        addressName: "无锡市",
        parentId: "320000",
        updateTime: null,
        addressList: [
          {
            id: 320201,
            addressName: "市辖区",
            parentId: "320200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320205,
            addressName: "锡山区",
            parentId: "320200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320206,
            addressName: "惠山区",
            parentId: "320200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320211,
            addressName: "滨湖区",
            parentId: "320200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320213,
            addressName: "梁溪区",
            parentId: "320200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320214,
            addressName: "新吴区",
            parentId: "320200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320281,
            addressName: "江阴市",
            parentId: "320200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320282,
            addressName: "宜兴市",
            parentId: "320200",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 320300,
        addressName: "徐州市",
        parentId: "320000",
        updateTime: null,
        addressList: [
          {
            id: 320301,
            addressName: "市辖区",
            parentId: "320300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320302,
            addressName: "鼓楼区",
            parentId: "320300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320303,
            addressName: "云龙区",
            parentId: "320300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320305,
            addressName: "贾汪区",
            parentId: "320300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320311,
            addressName: "泉山区",
            parentId: "320300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320312,
            addressName: "铜山区",
            parentId: "320300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320321,
            addressName: "丰县",
            parentId: "320300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320322,
            addressName: "沛县",
            parentId: "320300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320324,
            addressName: "睢宁县",
            parentId: "320300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320371,
            addressName: "徐州经济技术开发区",
            parentId: "320300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320381,
            addressName: "新沂市",
            parentId: "320300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320382,
            addressName: "邳州市",
            parentId: "320300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 320400,
        addressName: "常州市",
        parentId: "320000",
        updateTime: null,
        addressList: [
          {
            id: 320401,
            addressName: "市辖区",
            parentId: "320400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320402,
            addressName: "天宁区",
            parentId: "320400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320404,
            addressName: "钟楼区",
            parentId: "320400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320411,
            addressName: "新北区",
            parentId: "320400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320412,
            addressName: "武进区",
            parentId: "320400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320413,
            addressName: "金坛区",
            parentId: "320400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320481,
            addressName: "溧阳市",
            parentId: "320400",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 320500,
        addressName: "苏州市",
        parentId: "320000",
        updateTime: null,
        addressList: [
          {
            id: 320501,
            addressName: "市辖区",
            parentId: "320500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320505,
            addressName: "虎丘区",
            parentId: "320500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320506,
            addressName: "吴中区",
            parentId: "320500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320507,
            addressName: "相城区",
            parentId: "320500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320508,
            addressName: "姑苏区",
            parentId: "320500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320509,
            addressName: "吴江区",
            parentId: "320500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320571,
            addressName: "苏州工业园区",
            parentId: "320500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320581,
            addressName: "常熟市",
            parentId: "320500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320582,
            addressName: "张家港市",
            parentId: "320500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320583,
            addressName: "昆山市",
            parentId: "320500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320585,
            addressName: "太仓市",
            parentId: "320500",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 320600,
        addressName: "南通市",
        parentId: "320000",
        updateTime: null,
        addressList: [
          {
            id: 320601,
            addressName: "市辖区",
            parentId: "320600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320602,
            addressName: "崇川区",
            parentId: "320600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320611,
            addressName: "港闸区",
            parentId: "320600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320612,
            addressName: "通州区",
            parentId: "320600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320623,
            addressName: "如东县",
            parentId: "320600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320671,
            addressName: "南通经济技术开发区",
            parentId: "320600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320681,
            addressName: "启东市",
            parentId: "320600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320682,
            addressName: "如皋市",
            parentId: "320600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320684,
            addressName: "海门市",
            parentId: "320600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320685,
            addressName: "海安市",
            parentId: "320600",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 320700,
        addressName: "连云港市",
        parentId: "320000",
        updateTime: null,
        addressList: [
          {
            id: 320701,
            addressName: "市辖区",
            parentId: "320700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320703,
            addressName: "连云区",
            parentId: "320700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320706,
            addressName: "海州区",
            parentId: "320700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320707,
            addressName: "赣榆区",
            parentId: "320700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320722,
            addressName: "东海县",
            parentId: "320700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320723,
            addressName: "灌云县",
            parentId: "320700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320724,
            addressName: "灌南县",
            parentId: "320700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320771,
            addressName: "连云港经济技术开发区",
            parentId: "320700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320772,
            addressName: "连云港高新技术产业开发区",
            parentId: "320700",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 320800,
        addressName: "淮安市",
        parentId: "320000",
        updateTime: null,
        addressList: [
          {
            id: 320801,
            addressName: "市辖区",
            parentId: "320800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320803,
            addressName: "淮安区",
            parentId: "320800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320804,
            addressName: "淮阴区",
            parentId: "320800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320812,
            addressName: "清江浦区",
            parentId: "320800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320813,
            addressName: "洪泽区",
            parentId: "320800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320826,
            addressName: "涟水县",
            parentId: "320800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320830,
            addressName: "盱眙县",
            parentId: "320800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320831,
            addressName: "金湖县",
            parentId: "320800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320871,
            addressName: "淮安经济技术开发区",
            parentId: "320800",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 320900,
        addressName: "盐城市",
        parentId: "320000",
        updateTime: null,
        addressList: [
          {
            id: 320901,
            addressName: "市辖区",
            parentId: "320900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320902,
            addressName: "亭湖区",
            parentId: "320900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320903,
            addressName: "盐都区",
            parentId: "320900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320904,
            addressName: "大丰区",
            parentId: "320900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320921,
            addressName: "响水县",
            parentId: "320900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320922,
            addressName: "滨海县",
            parentId: "320900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320923,
            addressName: "阜宁县",
            parentId: "320900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320924,
            addressName: "射阳县",
            parentId: "320900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320925,
            addressName: "建湖县",
            parentId: "320900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320971,
            addressName: "盐城经济技术开发区",
            parentId: "320900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 320981,
            addressName: "东台市",
            parentId: "320900",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 321000,
        addressName: "扬州市",
        parentId: "320000",
        updateTime: null,
        addressList: [
          {
            id: 321001,
            addressName: "市辖区",
            parentId: "321000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 321002,
            addressName: "广陵区",
            parentId: "321000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 321003,
            addressName: "邗江区",
            parentId: "321000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 321012,
            addressName: "江都区",
            parentId: "321000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 321023,
            addressName: "宝应县",
            parentId: "321000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 321071,
            addressName: "扬州经济技术开发区",
            parentId: "321000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 321081,
            addressName: "仪征市",
            parentId: "321000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 321084,
            addressName: "高邮市",
            parentId: "321000",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 321100,
        addressName: "镇江市",
        parentId: "320000",
        updateTime: null,
        addressList: [
          {
            id: 321101,
            addressName: "市辖区",
            parentId: "321100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 321102,
            addressName: "京口区",
            parentId: "321100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 321111,
            addressName: "润州区",
            parentId: "321100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 321112,
            addressName: "丹徒区",
            parentId: "321100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 321171,
            addressName: "镇江新区",
            parentId: "321100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 321181,
            addressName: "丹阳市",
            parentId: "321100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 321182,
            addressName: "扬中市",
            parentId: "321100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 321183,
            addressName: "句容市",
            parentId: "321100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 321200,
        addressName: "泰州市",
        parentId: "320000",
        updateTime: null,
        addressList: [
          {
            id: 321201,
            addressName: "市辖区",
            parentId: "321200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 321202,
            addressName: "海陵区",
            parentId: "321200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 321203,
            addressName: "高港区",
            parentId: "321200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 321204,
            addressName: "姜堰区",
            parentId: "321200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 321271,
            addressName: "泰州医药高新技术产业开发区",
            parentId: "321200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 321281,
            addressName: "兴化市",
            parentId: "321200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 321282,
            addressName: "靖江市",
            parentId: "321200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 321283,
            addressName: "泰兴市",
            parentId: "321200",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 321300,
        addressName: "宿迁市",
        parentId: "320000",
        updateTime: null,
        addressList: [
          {
            id: 321301,
            addressName: "市辖区",
            parentId: "321300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 321302,
            addressName: "宿城区",
            parentId: "321300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 321311,
            addressName: "宿豫区",
            parentId: "321300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 321322,
            addressName: "沭阳县",
            parentId: "321300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 321323,
            addressName: "泗阳县",
            parentId: "321300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 321324,
            addressName: "泗洪县",
            parentId: "321300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 321371,
            addressName: "宿迁经济技术开发区",
            parentId: "321300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
    ],
  },
  {
    id: 330000,
    addressName: "浙江省",
    parentId: "86",
    updateTime: null,
    addressList: [
      {
        id: 330100,
        addressName: "杭州市",
        parentId: "330000",
        updateTime: null,
        addressList: [
          {
            id: 330101,
            addressName: "市辖区",
            parentId: "330100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330102,
            addressName: "上城区",
            parentId: "330100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330103,
            addressName: "下城区",
            parentId: "330100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330104,
            addressName: "江干区",
            parentId: "330100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330105,
            addressName: "拱墅区",
            parentId: "330100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330106,
            addressName: "西湖区",
            parentId: "330100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330108,
            addressName: "滨江区",
            parentId: "330100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330109,
            addressName: "萧山区",
            parentId: "330100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330110,
            addressName: "余杭区",
            parentId: "330100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330111,
            addressName: "富阳区",
            parentId: "330100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330112,
            addressName: "临安区",
            parentId: "330100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330122,
            addressName: "桐庐县",
            parentId: "330100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330127,
            addressName: "淳安县",
            parentId: "330100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330182,
            addressName: "建德市",
            parentId: "330100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 330200,
        addressName: "宁波市",
        parentId: "330000",
        updateTime: null,
        addressList: [
          {
            id: 330201,
            addressName: "市辖区",
            parentId: "330200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330203,
            addressName: "海曙区",
            parentId: "330200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330205,
            addressName: "江北区",
            parentId: "330200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330206,
            addressName: "北仑区",
            parentId: "330200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330211,
            addressName: "镇海区",
            parentId: "330200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330212,
            addressName: "鄞州区",
            parentId: "330200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330213,
            addressName: "奉化区",
            parentId: "330200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330225,
            addressName: "象山县",
            parentId: "330200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330226,
            addressName: "宁海县",
            parentId: "330200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330281,
            addressName: "余姚市",
            parentId: "330200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330282,
            addressName: "慈溪市",
            parentId: "330200",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 330300,
        addressName: "温州市",
        parentId: "330000",
        updateTime: null,
        addressList: [
          {
            id: 330301,
            addressName: "市辖区",
            parentId: "330300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330302,
            addressName: "鹿城区",
            parentId: "330300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330303,
            addressName: "龙湾区",
            parentId: "330300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330304,
            addressName: "瓯海区",
            parentId: "330300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330305,
            addressName: "洞头区",
            parentId: "330300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330324,
            addressName: "永嘉县",
            parentId: "330300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330326,
            addressName: "平阳县",
            parentId: "330300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330327,
            addressName: "苍南县",
            parentId: "330300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330328,
            addressName: "文成县",
            parentId: "330300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330329,
            addressName: "泰顺县",
            parentId: "330300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330371,
            addressName: "温州经济技术开发区",
            parentId: "330300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330381,
            addressName: "瑞安市",
            parentId: "330300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330382,
            addressName: "乐清市",
            parentId: "330300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330383,
            addressName: "龙港市",
            parentId: "330300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 330400,
        addressName: "嘉兴市",
        parentId: "330000",
        updateTime: null,
        addressList: [
          {
            id: 330401,
            addressName: "市辖区",
            parentId: "330400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330402,
            addressName: "南湖区",
            parentId: "330400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330411,
            addressName: "秀洲区",
            parentId: "330400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330421,
            addressName: "嘉善县",
            parentId: "330400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330424,
            addressName: "海盐县",
            parentId: "330400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330481,
            addressName: "海宁市",
            parentId: "330400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330482,
            addressName: "平湖市",
            parentId: "330400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330483,
            addressName: "桐乡市",
            parentId: "330400",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 330500,
        addressName: "湖州市",
        parentId: "330000",
        updateTime: null,
        addressList: [
          {
            id: 330501,
            addressName: "市辖区",
            parentId: "330500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330502,
            addressName: "吴兴区",
            parentId: "330500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330503,
            addressName: "南浔区",
            parentId: "330500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330521,
            addressName: "德清县",
            parentId: "330500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330522,
            addressName: "长兴县",
            parentId: "330500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330523,
            addressName: "安吉县",
            parentId: "330500",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 330600,
        addressName: "绍兴市",
        parentId: "330000",
        updateTime: null,
        addressList: [
          {
            id: 330601,
            addressName: "市辖区",
            parentId: "330600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330602,
            addressName: "越城区",
            parentId: "330600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330603,
            addressName: "柯桥区",
            parentId: "330600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330604,
            addressName: "上虞区",
            parentId: "330600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330624,
            addressName: "新昌县",
            parentId: "330600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330681,
            addressName: "诸暨市",
            parentId: "330600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330683,
            addressName: "嵊州市",
            parentId: "330600",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 330700,
        addressName: "金华市",
        parentId: "330000",
        updateTime: null,
        addressList: [
          {
            id: 330701,
            addressName: "市辖区",
            parentId: "330700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330702,
            addressName: "婺城区",
            parentId: "330700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330703,
            addressName: "金东区",
            parentId: "330700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330723,
            addressName: "武义县",
            parentId: "330700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330726,
            addressName: "浦江县",
            parentId: "330700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330727,
            addressName: "磐安县",
            parentId: "330700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330781,
            addressName: "兰溪市",
            parentId: "330700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330782,
            addressName: "义乌市",
            parentId: "330700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330783,
            addressName: "东阳市",
            parentId: "330700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330784,
            addressName: "永康市",
            parentId: "330700",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 330800,
        addressName: "衢州市",
        parentId: "330000",
        updateTime: null,
        addressList: [
          {
            id: 330801,
            addressName: "市辖区",
            parentId: "330800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330802,
            addressName: "柯城区",
            parentId: "330800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330803,
            addressName: "衢江区",
            parentId: "330800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330822,
            addressName: "常山县",
            parentId: "330800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330824,
            addressName: "开化县",
            parentId: "330800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330825,
            addressName: "龙游县",
            parentId: "330800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330881,
            addressName: "江山市",
            parentId: "330800",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 330900,
        addressName: "舟山市",
        parentId: "330000",
        updateTime: null,
        addressList: [
          {
            id: 330901,
            addressName: "市辖区",
            parentId: "330900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330902,
            addressName: "定海区",
            parentId: "330900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330903,
            addressName: "普陀区",
            parentId: "330900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330921,
            addressName: "岱山县",
            parentId: "330900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 330922,
            addressName: "嵊泗县",
            parentId: "330900",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 331000,
        addressName: "台州市",
        parentId: "330000",
        updateTime: null,
        addressList: [
          {
            id: 331001,
            addressName: "市辖区",
            parentId: "331000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 331002,
            addressName: "椒江区",
            parentId: "331000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 331003,
            addressName: "黄岩区",
            parentId: "331000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 331004,
            addressName: "路桥区",
            parentId: "331000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 331022,
            addressName: "三门县",
            parentId: "331000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 331023,
            addressName: "天台县",
            parentId: "331000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 331024,
            addressName: "仙居县",
            parentId: "331000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 331081,
            addressName: "温岭市",
            parentId: "331000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 331082,
            addressName: "临海市",
            parentId: "331000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 331083,
            addressName: "玉环市",
            parentId: "331000",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 331100,
        addressName: "丽水市",
        parentId: "330000",
        updateTime: null,
        addressList: [
          {
            id: 331101,
            addressName: "市辖区",
            parentId: "331100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 331102,
            addressName: "莲都区",
            parentId: "331100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 331121,
            addressName: "青田县",
            parentId: "331100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 331122,
            addressName: "缙云县",
            parentId: "331100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 331123,
            addressName: "遂昌县",
            parentId: "331100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 331124,
            addressName: "松阳县",
            parentId: "331100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 331125,
            addressName: "云和县",
            parentId: "331100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 331126,
            addressName: "庆元县",
            parentId: "331100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 331127,
            addressName: "景宁畲族自治县",
            parentId: "331100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 331181,
            addressName: "龙泉市",
            parentId: "331100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
    ],
  },
  {
    id: 340000,
    addressName: "安徽省",
    parentId: "86",
    updateTime: null,
    addressList: [
      {
        id: 340100,
        addressName: "合肥市",
        parentId: "340000",
        updateTime: null,
        addressList: [
          {
            id: 340101,
            addressName: "市辖区",
            parentId: "340100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340102,
            addressName: "瑶海区",
            parentId: "340100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340103,
            addressName: "庐阳区",
            parentId: "340100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340104,
            addressName: "蜀山区",
            parentId: "340100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340111,
            addressName: "包河区",
            parentId: "340100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340121,
            addressName: "长丰县",
            parentId: "340100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340122,
            addressName: "肥东县",
            parentId: "340100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340123,
            addressName: "肥西县",
            parentId: "340100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340124,
            addressName: "庐江县",
            parentId: "340100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340171,
            addressName: "合肥高新技术产业开发区",
            parentId: "340100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340172,
            addressName: "合肥经济技术开发区",
            parentId: "340100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340173,
            addressName: "合肥新站高新技术产业开发区",
            parentId: "340100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340181,
            addressName: "巢湖市",
            parentId: "340100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 340200,
        addressName: "芜湖市",
        parentId: "340000",
        updateTime: null,
        addressList: [
          {
            id: 340201,
            addressName: "市辖区",
            parentId: "340200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340202,
            addressName: "镜湖区",
            parentId: "340200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340203,
            addressName: "弋江区",
            parentId: "340200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340207,
            addressName: "鸠江区",
            parentId: "340200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340208,
            addressName: "三山区",
            parentId: "340200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340221,
            addressName: "芜湖县",
            parentId: "340200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340222,
            addressName: "繁昌县",
            parentId: "340200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340223,
            addressName: "南陵县",
            parentId: "340200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340225,
            addressName: "无为县",
            parentId: "340200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340271,
            addressName: "芜湖经济技术开发区",
            parentId: "340200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340272,
            addressName: "安徽芜湖长江大桥经济开发区",
            parentId: "340200",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 340300,
        addressName: "蚌埠市",
        parentId: "340000",
        updateTime: null,
        addressList: [
          {
            id: 340301,
            addressName: "市辖区",
            parentId: "340300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340302,
            addressName: "龙子湖区",
            parentId: "340300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340303,
            addressName: "蚌山区",
            parentId: "340300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340304,
            addressName: "禹会区",
            parentId: "340300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340311,
            addressName: "淮上区",
            parentId: "340300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340321,
            addressName: "怀远县",
            parentId: "340300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340322,
            addressName: "五河县",
            parentId: "340300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340323,
            addressName: "固镇县",
            parentId: "340300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340371,
            addressName: "蚌埠市高新技术开发区",
            parentId: "340300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340372,
            addressName: "蚌埠市经济开发区",
            parentId: "340300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 340400,
        addressName: "淮南市",
        parentId: "340000",
        updateTime: null,
        addressList: [
          {
            id: 340401,
            addressName: "市辖区",
            parentId: "340400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340402,
            addressName: "大通区",
            parentId: "340400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340403,
            addressName: "田家庵区",
            parentId: "340400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340404,
            addressName: "谢家集区",
            parentId: "340400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340405,
            addressName: "八公山区",
            parentId: "340400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340406,
            addressName: "潘集区",
            parentId: "340400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340421,
            addressName: "凤台县",
            parentId: "340400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340422,
            addressName: "寿县",
            parentId: "340400",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 340500,
        addressName: "马鞍山市",
        parentId: "340000",
        updateTime: null,
        addressList: [
          {
            id: 340501,
            addressName: "市辖区",
            parentId: "340500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340503,
            addressName: "花山区",
            parentId: "340500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340504,
            addressName: "雨山区",
            parentId: "340500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340506,
            addressName: "博望区",
            parentId: "340500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340521,
            addressName: "当涂县",
            parentId: "340500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340522,
            addressName: "含山县",
            parentId: "340500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340523,
            addressName: "和县",
            parentId: "340500",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 340600,
        addressName: "淮北市",
        parentId: "340000",
        updateTime: null,
        addressList: [
          {
            id: 340601,
            addressName: "市辖区",
            parentId: "340600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340602,
            addressName: "杜集区",
            parentId: "340600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340603,
            addressName: "相山区",
            parentId: "340600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340604,
            addressName: "烈山区",
            parentId: "340600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340621,
            addressName: "濉溪县",
            parentId: "340600",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 340700,
        addressName: "铜陵市",
        parentId: "340000",
        updateTime: null,
        addressList: [
          {
            id: 340701,
            addressName: "市辖区",
            parentId: "340700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340705,
            addressName: "铜官区",
            parentId: "340700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340706,
            addressName: "义安区",
            parentId: "340700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340711,
            addressName: "郊区",
            parentId: "340700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340722,
            addressName: "枞阳县",
            parentId: "340700",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 340800,
        addressName: "安庆市",
        parentId: "340000",
        updateTime: null,
        addressList: [
          {
            id: 340801,
            addressName: "市辖区",
            parentId: "340800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340802,
            addressName: "迎江区",
            parentId: "340800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340803,
            addressName: "大观区",
            parentId: "340800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340811,
            addressName: "宜秀区",
            parentId: "340800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340822,
            addressName: "怀宁县",
            parentId: "340800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340825,
            addressName: "太湖县",
            parentId: "340800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340826,
            addressName: "宿松县",
            parentId: "340800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340827,
            addressName: "望江县",
            parentId: "340800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340828,
            addressName: "岳西县",
            parentId: "340800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340871,
            addressName: "安徽安庆经济开发区",
            parentId: "340800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340881,
            addressName: "桐城市",
            parentId: "340800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 340882,
            addressName: "潜山市",
            parentId: "340800",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 341000,
        addressName: "黄山市",
        parentId: "340000",
        updateTime: null,
        addressList: [
          {
            id: 341001,
            addressName: "市辖区",
            parentId: "341000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341002,
            addressName: "屯溪区",
            parentId: "341000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341003,
            addressName: "黄山区",
            parentId: "341000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341004,
            addressName: "徽州区",
            parentId: "341000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341021,
            addressName: "歙县",
            parentId: "341000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341022,
            addressName: "休宁县",
            parentId: "341000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341023,
            addressName: "黟县",
            parentId: "341000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341024,
            addressName: "祁门县",
            parentId: "341000",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 341100,
        addressName: "滁州市",
        parentId: "340000",
        updateTime: null,
        addressList: [
          {
            id: 341101,
            addressName: "市辖区",
            parentId: "341100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341102,
            addressName: "琅琊区",
            parentId: "341100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341103,
            addressName: "南谯区",
            parentId: "341100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341122,
            addressName: "来安县",
            parentId: "341100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341124,
            addressName: "全椒县",
            parentId: "341100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341125,
            addressName: "定远县",
            parentId: "341100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341126,
            addressName: "凤阳县",
            parentId: "341100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341171,
            addressName: "苏滁现代产业园",
            parentId: "341100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341172,
            addressName: "滁州经济技术开发区",
            parentId: "341100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341181,
            addressName: "天长市",
            parentId: "341100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341182,
            addressName: "明光市",
            parentId: "341100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 341200,
        addressName: "阜阳市",
        parentId: "340000",
        updateTime: null,
        addressList: [
          {
            id: 341201,
            addressName: "市辖区",
            parentId: "341200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341202,
            addressName: "颍州区",
            parentId: "341200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341203,
            addressName: "颍东区",
            parentId: "341200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341204,
            addressName: "颍泉区",
            parentId: "341200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341221,
            addressName: "临泉县",
            parentId: "341200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341222,
            addressName: "太和县",
            parentId: "341200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341225,
            addressName: "阜南县",
            parentId: "341200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341226,
            addressName: "颍上县",
            parentId: "341200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341271,
            addressName: "阜阳合肥现代产业园区",
            parentId: "341200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341272,
            addressName: "阜阳经济技术开发区",
            parentId: "341200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341282,
            addressName: "界首市",
            parentId: "341200",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 341300,
        addressName: "宿州市",
        parentId: "340000",
        updateTime: null,
        addressList: [
          {
            id: 341301,
            addressName: "市辖区",
            parentId: "341300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341302,
            addressName: "埇桥区",
            parentId: "341300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341321,
            addressName: "砀山县",
            parentId: "341300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341322,
            addressName: "萧县",
            parentId: "341300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341323,
            addressName: "灵璧县",
            parentId: "341300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341324,
            addressName: "泗县",
            parentId: "341300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341371,
            addressName: "宿州马鞍山现代产业园区",
            parentId: "341300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341372,
            addressName: "宿州经济技术开发区",
            parentId: "341300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 341500,
        addressName: "六安市",
        parentId: "340000",
        updateTime: null,
        addressList: [
          {
            id: 341501,
            addressName: "市辖区",
            parentId: "341500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341502,
            addressName: "金安区",
            parentId: "341500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341503,
            addressName: "裕安区",
            parentId: "341500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341504,
            addressName: "叶集区",
            parentId: "341500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341522,
            addressName: "霍邱县",
            parentId: "341500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341523,
            addressName: "舒城县",
            parentId: "341500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341524,
            addressName: "金寨县",
            parentId: "341500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341525,
            addressName: "霍山县",
            parentId: "341500",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 341600,
        addressName: "亳州市",
        parentId: "340000",
        updateTime: null,
        addressList: [
          {
            id: 341601,
            addressName: "市辖区",
            parentId: "341600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341602,
            addressName: "谯城区",
            parentId: "341600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341621,
            addressName: "涡阳县",
            parentId: "341600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341622,
            addressName: "蒙城县",
            parentId: "341600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341623,
            addressName: "利辛县",
            parentId: "341600",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 341700,
        addressName: "池州市",
        parentId: "340000",
        updateTime: null,
        addressList: [
          {
            id: 341701,
            addressName: "市辖区",
            parentId: "341700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341702,
            addressName: "贵池区",
            parentId: "341700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341721,
            addressName: "东至县",
            parentId: "341700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341722,
            addressName: "石台县",
            parentId: "341700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341723,
            addressName: "青阳县",
            parentId: "341700",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 341800,
        addressName: "宣城市",
        parentId: "340000",
        updateTime: null,
        addressList: [
          {
            id: 341801,
            addressName: "市辖区",
            parentId: "341800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341802,
            addressName: "宣州区",
            parentId: "341800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341821,
            addressName: "郎溪县",
            parentId: "341800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341823,
            addressName: "泾县",
            parentId: "341800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341824,
            addressName: "绩溪县",
            parentId: "341800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341825,
            addressName: "旌德县",
            parentId: "341800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341871,
            addressName: "宣城市经济开发区",
            parentId: "341800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341881,
            addressName: "宁国市",
            parentId: "341800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 341882,
            addressName: "广德市",
            parentId: "341800",
            updateTime: null,
            addressList: null,
          },
        ],
      },
    ],
  },
  {
    id: 350000,
    addressName: "福建省",
    parentId: "86",
    updateTime: null,
    addressList: [
      {
        id: 350100,
        addressName: "福州市",
        parentId: "350000",
        updateTime: null,
        addressList: [
          {
            id: 350101,
            addressName: "市辖区",
            parentId: "350100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350102,
            addressName: "鼓楼区",
            parentId: "350100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350103,
            addressName: "台江区",
            parentId: "350100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350104,
            addressName: "仓山区",
            parentId: "350100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350105,
            addressName: "马尾区",
            parentId: "350100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350111,
            addressName: "晋安区",
            parentId: "350100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350112,
            addressName: "长乐区",
            parentId: "350100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350121,
            addressName: "闽侯县",
            parentId: "350100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350122,
            addressName: "连江县",
            parentId: "350100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350123,
            addressName: "罗源县",
            parentId: "350100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350124,
            addressName: "闽清县",
            parentId: "350100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350125,
            addressName: "永泰县",
            parentId: "350100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350128,
            addressName: "平潭县",
            parentId: "350100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350181,
            addressName: "福清市",
            parentId: "350100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 350200,
        addressName: "厦门市",
        parentId: "350000",
        updateTime: null,
        addressList: [
          {
            id: 350201,
            addressName: "市辖区",
            parentId: "350200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350203,
            addressName: "思明区",
            parentId: "350200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350205,
            addressName: "海沧区",
            parentId: "350200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350206,
            addressName: "湖里区",
            parentId: "350200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350211,
            addressName: "集美区",
            parentId: "350200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350212,
            addressName: "同安区",
            parentId: "350200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350213,
            addressName: "翔安区",
            parentId: "350200",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 350300,
        addressName: "莆田市",
        parentId: "350000",
        updateTime: null,
        addressList: [
          {
            id: 350301,
            addressName: "市辖区",
            parentId: "350300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350302,
            addressName: "城厢区",
            parentId: "350300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350303,
            addressName: "涵江区",
            parentId: "350300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350304,
            addressName: "荔城区",
            parentId: "350300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350305,
            addressName: "秀屿区",
            parentId: "350300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350322,
            addressName: "仙游县",
            parentId: "350300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 350400,
        addressName: "三明市",
        parentId: "350000",
        updateTime: null,
        addressList: [
          {
            id: 350401,
            addressName: "市辖区",
            parentId: "350400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350402,
            addressName: "梅列区",
            parentId: "350400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350403,
            addressName: "三元区",
            parentId: "350400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350421,
            addressName: "明溪县",
            parentId: "350400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350423,
            addressName: "清流县",
            parentId: "350400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350424,
            addressName: "宁化县",
            parentId: "350400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350425,
            addressName: "大田县",
            parentId: "350400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350426,
            addressName: "尤溪县",
            parentId: "350400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350427,
            addressName: "沙县",
            parentId: "350400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350428,
            addressName: "将乐县",
            parentId: "350400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350429,
            addressName: "泰宁县",
            parentId: "350400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350430,
            addressName: "建宁县",
            parentId: "350400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350481,
            addressName: "永安市",
            parentId: "350400",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 350500,
        addressName: "泉州市",
        parentId: "350000",
        updateTime: null,
        addressList: [
          {
            id: 350501,
            addressName: "市辖区",
            parentId: "350500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350502,
            addressName: "鲤城区",
            parentId: "350500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350503,
            addressName: "丰泽区",
            parentId: "350500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350504,
            addressName: "洛江区",
            parentId: "350500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350505,
            addressName: "泉港区",
            parentId: "350500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350521,
            addressName: "惠安县",
            parentId: "350500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350524,
            addressName: "安溪县",
            parentId: "350500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350525,
            addressName: "永春县",
            parentId: "350500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350526,
            addressName: "德化县",
            parentId: "350500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350527,
            addressName: "金门县",
            parentId: "350500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350581,
            addressName: "石狮市",
            parentId: "350500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350582,
            addressName: "晋江市",
            parentId: "350500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350583,
            addressName: "南安市",
            parentId: "350500",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 350600,
        addressName: "漳州市",
        parentId: "350000",
        updateTime: null,
        addressList: [
          {
            id: 350601,
            addressName: "市辖区",
            parentId: "350600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350602,
            addressName: "芗城区",
            parentId: "350600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350603,
            addressName: "龙文区",
            parentId: "350600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350622,
            addressName: "云霄县",
            parentId: "350600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350623,
            addressName: "漳浦县",
            parentId: "350600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350624,
            addressName: "诏安县",
            parentId: "350600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350625,
            addressName: "长泰县",
            parentId: "350600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350626,
            addressName: "东山县",
            parentId: "350600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350627,
            addressName: "南靖县",
            parentId: "350600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350628,
            addressName: "平和县",
            parentId: "350600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350629,
            addressName: "华安县",
            parentId: "350600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350681,
            addressName: "龙海市",
            parentId: "350600",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 350700,
        addressName: "南平市",
        parentId: "350000",
        updateTime: null,
        addressList: [
          {
            id: 350701,
            addressName: "市辖区",
            parentId: "350700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350702,
            addressName: "延平区",
            parentId: "350700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350703,
            addressName: "建阳区",
            parentId: "350700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350721,
            addressName: "顺昌县",
            parentId: "350700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350722,
            addressName: "浦城县",
            parentId: "350700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350723,
            addressName: "光泽县",
            parentId: "350700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350724,
            addressName: "松溪县",
            parentId: "350700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350725,
            addressName: "政和县",
            parentId: "350700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350781,
            addressName: "邵武市",
            parentId: "350700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350782,
            addressName: "武夷山市",
            parentId: "350700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350783,
            addressName: "建瓯市",
            parentId: "350700",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 350800,
        addressName: "龙岩市",
        parentId: "350000",
        updateTime: null,
        addressList: [
          {
            id: 350801,
            addressName: "市辖区",
            parentId: "350800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350802,
            addressName: "新罗区",
            parentId: "350800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350803,
            addressName: "永定区",
            parentId: "350800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350821,
            addressName: "长汀县",
            parentId: "350800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350823,
            addressName: "上杭县",
            parentId: "350800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350824,
            addressName: "武平县",
            parentId: "350800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350825,
            addressName: "连城县",
            parentId: "350800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350881,
            addressName: "漳平市",
            parentId: "350800",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 350900,
        addressName: "宁德市",
        parentId: "350000",
        updateTime: null,
        addressList: [
          {
            id: 350901,
            addressName: "市辖区",
            parentId: "350900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350902,
            addressName: "蕉城区",
            parentId: "350900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350921,
            addressName: "霞浦县",
            parentId: "350900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350922,
            addressName: "古田县",
            parentId: "350900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350923,
            addressName: "屏南县",
            parentId: "350900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350924,
            addressName: "寿宁县",
            parentId: "350900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350925,
            addressName: "周宁县",
            parentId: "350900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350926,
            addressName: "柘荣县",
            parentId: "350900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350981,
            addressName: "福安市",
            parentId: "350900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 350982,
            addressName: "福鼎市",
            parentId: "350900",
            updateTime: null,
            addressList: null,
          },
        ],
      },
    ],
  },
  {
    id: 360000,
    addressName: "江西省",
    parentId: "86",
    updateTime: null,
    addressList: [
      {
        id: 360100,
        addressName: "南昌市",
        parentId: "360000",
        updateTime: null,
        addressList: [
          {
            id: 360101,
            addressName: "市辖区",
            parentId: "360100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360102,
            addressName: "东湖区",
            parentId: "360100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360103,
            addressName: "西湖区",
            parentId: "360100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360104,
            addressName: "青云谱区",
            parentId: "360100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360105,
            addressName: "湾里区",
            parentId: "360100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360111,
            addressName: "青山湖区",
            parentId: "360100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360112,
            addressName: "新建区",
            parentId: "360100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360121,
            addressName: "南昌县",
            parentId: "360100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360123,
            addressName: "安义县",
            parentId: "360100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360124,
            addressName: "进贤县",
            parentId: "360100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 360200,
        addressName: "景德镇市",
        parentId: "360000",
        updateTime: null,
        addressList: [
          {
            id: 360201,
            addressName: "市辖区",
            parentId: "360200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360202,
            addressName: "昌江区",
            parentId: "360200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360203,
            addressName: "珠山区",
            parentId: "360200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360222,
            addressName: "浮梁县",
            parentId: "360200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360281,
            addressName: "乐平市",
            parentId: "360200",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 360300,
        addressName: "萍乡市",
        parentId: "360000",
        updateTime: null,
        addressList: [
          {
            id: 360301,
            addressName: "市辖区",
            parentId: "360300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360302,
            addressName: "安源区",
            parentId: "360300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360313,
            addressName: "湘东区",
            parentId: "360300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360321,
            addressName: "莲花县",
            parentId: "360300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360322,
            addressName: "上栗县",
            parentId: "360300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360323,
            addressName: "芦溪县",
            parentId: "360300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 360400,
        addressName: "九江市",
        parentId: "360000",
        updateTime: null,
        addressList: [
          {
            id: 360401,
            addressName: "市辖区",
            parentId: "360400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360402,
            addressName: "濂溪区",
            parentId: "360400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360403,
            addressName: "浔阳区",
            parentId: "360400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360404,
            addressName: "柴桑区",
            parentId: "360400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360423,
            addressName: "武宁县",
            parentId: "360400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360424,
            addressName: "修水县",
            parentId: "360400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360425,
            addressName: "永修县",
            parentId: "360400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360426,
            addressName: "德安县",
            parentId: "360400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360428,
            addressName: "都昌县",
            parentId: "360400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360429,
            addressName: "湖口县",
            parentId: "360400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360430,
            addressName: "彭泽县",
            parentId: "360400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360481,
            addressName: "瑞昌市",
            parentId: "360400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360482,
            addressName: "共青城市",
            parentId: "360400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360483,
            addressName: "庐山市",
            parentId: "360400",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 360500,
        addressName: "新余市",
        parentId: "360000",
        updateTime: null,
        addressList: [
          {
            id: 360501,
            addressName: "市辖区",
            parentId: "360500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360502,
            addressName: "渝水区",
            parentId: "360500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360521,
            addressName: "分宜县",
            parentId: "360500",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 360600,
        addressName: "鹰潭市",
        parentId: "360000",
        updateTime: null,
        addressList: [
          {
            id: 360601,
            addressName: "市辖区",
            parentId: "360600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360602,
            addressName: "月湖区",
            parentId: "360600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360603,
            addressName: "余江区",
            parentId: "360600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360681,
            addressName: "贵溪市",
            parentId: "360600",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 360700,
        addressName: "赣州市",
        parentId: "360000",
        updateTime: null,
        addressList: [
          {
            id: 360701,
            addressName: "市辖区",
            parentId: "360700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360702,
            addressName: "章贡区",
            parentId: "360700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360703,
            addressName: "南康区",
            parentId: "360700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360704,
            addressName: "赣县区",
            parentId: "360700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360722,
            addressName: "信丰县",
            parentId: "360700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360723,
            addressName: "大余县",
            parentId: "360700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360724,
            addressName: "上犹县",
            parentId: "360700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360725,
            addressName: "崇义县",
            parentId: "360700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360726,
            addressName: "安远县",
            parentId: "360700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360727,
            addressName: "龙南县",
            parentId: "360700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360728,
            addressName: "定南县",
            parentId: "360700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360729,
            addressName: "全南县",
            parentId: "360700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360730,
            addressName: "宁都县",
            parentId: "360700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360731,
            addressName: "于都县",
            parentId: "360700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360732,
            addressName: "兴国县",
            parentId: "360700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360733,
            addressName: "会昌县",
            parentId: "360700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360734,
            addressName: "寻乌县",
            parentId: "360700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360735,
            addressName: "石城县",
            parentId: "360700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360781,
            addressName: "瑞金市",
            parentId: "360700",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 360800,
        addressName: "吉安市",
        parentId: "360000",
        updateTime: null,
        addressList: [
          {
            id: 360801,
            addressName: "市辖区",
            parentId: "360800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360802,
            addressName: "吉州区",
            parentId: "360800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360803,
            addressName: "青原区",
            parentId: "360800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360821,
            addressName: "吉安县",
            parentId: "360800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360822,
            addressName: "吉水县",
            parentId: "360800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360823,
            addressName: "峡江县",
            parentId: "360800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360824,
            addressName: "新干县",
            parentId: "360800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360825,
            addressName: "永丰县",
            parentId: "360800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360826,
            addressName: "泰和县",
            parentId: "360800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360827,
            addressName: "遂川县",
            parentId: "360800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360828,
            addressName: "万安县",
            parentId: "360800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360829,
            addressName: "安福县",
            parentId: "360800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360830,
            addressName: "永新县",
            parentId: "360800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360881,
            addressName: "井冈山市",
            parentId: "360800",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 360900,
        addressName: "宜春市",
        parentId: "360000",
        updateTime: null,
        addressList: [
          {
            id: 360901,
            addressName: "市辖区",
            parentId: "360900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360902,
            addressName: "袁州区",
            parentId: "360900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360921,
            addressName: "奉新县",
            parentId: "360900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360922,
            addressName: "万载县",
            parentId: "360900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360923,
            addressName: "上高县",
            parentId: "360900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360924,
            addressName: "宜丰县",
            parentId: "360900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360925,
            addressName: "靖安县",
            parentId: "360900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360926,
            addressName: "铜鼓县",
            parentId: "360900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360981,
            addressName: "丰城市",
            parentId: "360900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360982,
            addressName: "樟树市",
            parentId: "360900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 360983,
            addressName: "高安市",
            parentId: "360900",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 361000,
        addressName: "抚州市",
        parentId: "360000",
        updateTime: null,
        addressList: [
          {
            id: 361001,
            addressName: "市辖区",
            parentId: "361000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 361002,
            addressName: "临川区",
            parentId: "361000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 361003,
            addressName: "东乡区",
            parentId: "361000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 361021,
            addressName: "南城县",
            parentId: "361000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 361022,
            addressName: "黎川县",
            parentId: "361000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 361023,
            addressName: "南丰县",
            parentId: "361000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 361024,
            addressName: "崇仁县",
            parentId: "361000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 361025,
            addressName: "乐安县",
            parentId: "361000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 361026,
            addressName: "宜黄县",
            parentId: "361000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 361027,
            addressName: "金溪县",
            parentId: "361000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 361028,
            addressName: "资溪县",
            parentId: "361000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 361030,
            addressName: "广昌县",
            parentId: "361000",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 361100,
        addressName: "上饶市",
        parentId: "360000",
        updateTime: null,
        addressList: [
          {
            id: 361101,
            addressName: "市辖区",
            parentId: "361100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 361102,
            addressName: "信州区",
            parentId: "361100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 361103,
            addressName: "广丰区",
            parentId: "361100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 361104,
            addressName: "广信区",
            parentId: "361100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 361123,
            addressName: "玉山县",
            parentId: "361100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 361124,
            addressName: "铅山县",
            parentId: "361100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 361125,
            addressName: "横峰县",
            parentId: "361100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 361126,
            addressName: "弋阳县",
            parentId: "361100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 361127,
            addressName: "余干县",
            parentId: "361100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 361128,
            addressName: "鄱阳县",
            parentId: "361100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 361129,
            addressName: "万年县",
            parentId: "361100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 361130,
            addressName: "婺源县",
            parentId: "361100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 361181,
            addressName: "德兴市",
            parentId: "361100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
    ],
  },
  {
    id: 370000,
    addressName: "山东省",
    parentId: "86",
    updateTime: null,
    addressList: [
      {
        id: 370100,
        addressName: "济南市",
        parentId: "370000",
        updateTime: null,
        addressList: [
          {
            id: 370101,
            addressName: "市辖区",
            parentId: "370100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370102,
            addressName: "历下区",
            parentId: "370100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370103,
            addressName: "市中区",
            parentId: "370100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370104,
            addressName: "槐荫区",
            parentId: "370100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370105,
            addressName: "天桥区",
            parentId: "370100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370112,
            addressName: "历城区",
            parentId: "370100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370113,
            addressName: "长清区",
            parentId: "370100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370114,
            addressName: "章丘区",
            parentId: "370100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370115,
            addressName: "济阳区",
            parentId: "370100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370116,
            addressName: "莱芜区",
            parentId: "370100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370117,
            addressName: "钢城区",
            parentId: "370100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370124,
            addressName: "平阴县",
            parentId: "370100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370126,
            addressName: "商河县",
            parentId: "370100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370171,
            addressName: "济南高新技术产业开发区",
            parentId: "370100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 370200,
        addressName: "青岛市",
        parentId: "370000",
        updateTime: null,
        addressList: [
          {
            id: 370201,
            addressName: "市辖区",
            parentId: "370200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370202,
            addressName: "市南区",
            parentId: "370200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370203,
            addressName: "市北区",
            parentId: "370200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370211,
            addressName: "黄岛区",
            parentId: "370200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370212,
            addressName: "崂山区",
            parentId: "370200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370213,
            addressName: "李沧区",
            parentId: "370200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370214,
            addressName: "城阳区",
            parentId: "370200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370215,
            addressName: "即墨区",
            parentId: "370200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370271,
            addressName: "青岛高新技术产业开发区",
            parentId: "370200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370281,
            addressName: "胶州市",
            parentId: "370200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370283,
            addressName: "平度市",
            parentId: "370200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370285,
            addressName: "莱西市",
            parentId: "370200",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 370300,
        addressName: "淄博市",
        parentId: "370000",
        updateTime: null,
        addressList: [
          {
            id: 370301,
            addressName: "市辖区",
            parentId: "370300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370302,
            addressName: "淄川区",
            parentId: "370300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370303,
            addressName: "张店区",
            parentId: "370300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370304,
            addressName: "博山区",
            parentId: "370300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370305,
            addressName: "临淄区",
            parentId: "370300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370306,
            addressName: "周村区",
            parentId: "370300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370321,
            addressName: "桓台县",
            parentId: "370300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370322,
            addressName: "高青县",
            parentId: "370300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370323,
            addressName: "沂源县",
            parentId: "370300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 370400,
        addressName: "枣庄市",
        parentId: "370000",
        updateTime: null,
        addressList: [
          {
            id: 370401,
            addressName: "市辖区",
            parentId: "370400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370402,
            addressName: "市中区",
            parentId: "370400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370403,
            addressName: "薛城区",
            parentId: "370400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370404,
            addressName: "峄城区",
            parentId: "370400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370405,
            addressName: "台儿庄区",
            parentId: "370400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370406,
            addressName: "山亭区",
            parentId: "370400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370481,
            addressName: "滕州市",
            parentId: "370400",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 370500,
        addressName: "东营市",
        parentId: "370000",
        updateTime: null,
        addressList: [
          {
            id: 370501,
            addressName: "市辖区",
            parentId: "370500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370502,
            addressName: "东营区",
            parentId: "370500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370503,
            addressName: "河口区",
            parentId: "370500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370505,
            addressName: "垦利区",
            parentId: "370500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370522,
            addressName: "利津县",
            parentId: "370500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370523,
            addressName: "广饶县",
            parentId: "370500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370571,
            addressName: "东营经济技术开发区",
            parentId: "370500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370572,
            addressName: "东营港经济开发区",
            parentId: "370500",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 370600,
        addressName: "烟台市",
        parentId: "370000",
        updateTime: null,
        addressList: [
          {
            id: 370601,
            addressName: "市辖区",
            parentId: "370600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370602,
            addressName: "芝罘区",
            parentId: "370600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370611,
            addressName: "福山区",
            parentId: "370600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370612,
            addressName: "牟平区",
            parentId: "370600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370613,
            addressName: "莱山区",
            parentId: "370600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370634,
            addressName: "长岛县",
            parentId: "370600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370671,
            addressName: "烟台高新技术产业开发区",
            parentId: "370600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370672,
            addressName: "烟台经济技术开发区",
            parentId: "370600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370681,
            addressName: "龙口市",
            parentId: "370600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370682,
            addressName: "莱阳市",
            parentId: "370600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370683,
            addressName: "莱州市",
            parentId: "370600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370684,
            addressName: "蓬莱市",
            parentId: "370600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370685,
            addressName: "招远市",
            parentId: "370600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370686,
            addressName: "栖霞市",
            parentId: "370600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370687,
            addressName: "海阳市",
            parentId: "370600",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 370700,
        addressName: "潍坊市",
        parentId: "370000",
        updateTime: null,
        addressList: [
          {
            id: 370701,
            addressName: "市辖区",
            parentId: "370700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370702,
            addressName: "潍城区",
            parentId: "370700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370703,
            addressName: "寒亭区",
            parentId: "370700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370704,
            addressName: "坊子区",
            parentId: "370700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370705,
            addressName: "奎文区",
            parentId: "370700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370724,
            addressName: "临朐县",
            parentId: "370700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370725,
            addressName: "昌乐县",
            parentId: "370700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370772,
            addressName: "潍坊滨海经济技术开发区",
            parentId: "370700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370781,
            addressName: "青州市",
            parentId: "370700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370782,
            addressName: "诸城市",
            parentId: "370700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370783,
            addressName: "寿光市",
            parentId: "370700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370784,
            addressName: "安丘市",
            parentId: "370700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370785,
            addressName: "高密市",
            parentId: "370700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370786,
            addressName: "昌邑市",
            parentId: "370700",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 370800,
        addressName: "济宁市",
        parentId: "370000",
        updateTime: null,
        addressList: [
          {
            id: 370801,
            addressName: "市辖区",
            parentId: "370800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370811,
            addressName: "任城区",
            parentId: "370800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370812,
            addressName: "兖州区",
            parentId: "370800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370826,
            addressName: "微山县",
            parentId: "370800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370827,
            addressName: "鱼台县",
            parentId: "370800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370828,
            addressName: "金乡县",
            parentId: "370800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370829,
            addressName: "嘉祥县",
            parentId: "370800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370830,
            addressName: "汶上县",
            parentId: "370800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370831,
            addressName: "泗水县",
            parentId: "370800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370832,
            addressName: "梁山县",
            parentId: "370800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370871,
            addressName: "济宁高新技术产业开发区",
            parentId: "370800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370881,
            addressName: "曲阜市",
            parentId: "370800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370883,
            addressName: "邹城市",
            parentId: "370800",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 370900,
        addressName: "泰安市",
        parentId: "370000",
        updateTime: null,
        addressList: [
          {
            id: 370901,
            addressName: "市辖区",
            parentId: "370900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370902,
            addressName: "泰山区",
            parentId: "370900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370911,
            addressName: "岱岳区",
            parentId: "370900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370921,
            addressName: "宁阳县",
            parentId: "370900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370923,
            addressName: "东平县",
            parentId: "370900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370982,
            addressName: "新泰市",
            parentId: "370900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 370983,
            addressName: "肥城市",
            parentId: "370900",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 371000,
        addressName: "威海市",
        parentId: "370000",
        updateTime: null,
        addressList: [
          {
            id: 371001,
            addressName: "市辖区",
            parentId: "371000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371002,
            addressName: "环翠区",
            parentId: "371000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371003,
            addressName: "文登区",
            parentId: "371000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371071,
            addressName: "威海火炬高技术产业开发区",
            parentId: "371000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371072,
            addressName: "威海经济技术开发区",
            parentId: "371000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371073,
            addressName: "威海临港经济技术开发区",
            parentId: "371000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371082,
            addressName: "荣成市",
            parentId: "371000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371083,
            addressName: "乳山市",
            parentId: "371000",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 371100,
        addressName: "日照市",
        parentId: "370000",
        updateTime: null,
        addressList: [
          {
            id: 371101,
            addressName: "市辖区",
            parentId: "371100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371102,
            addressName: "东港区",
            parentId: "371100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371103,
            addressName: "岚山区",
            parentId: "371100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371121,
            addressName: "五莲县",
            parentId: "371100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371122,
            addressName: "莒县",
            parentId: "371100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371171,
            addressName: "日照经济技术开发区",
            parentId: "371100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 371300,
        addressName: "临沂市",
        parentId: "370000",
        updateTime: null,
        addressList: [
          {
            id: 371301,
            addressName: "市辖区",
            parentId: "371300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371302,
            addressName: "兰山区",
            parentId: "371300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371311,
            addressName: "罗庄区",
            parentId: "371300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371312,
            addressName: "河东区",
            parentId: "371300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371321,
            addressName: "沂南县",
            parentId: "371300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371322,
            addressName: "郯城县",
            parentId: "371300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371323,
            addressName: "沂水县",
            parentId: "371300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371324,
            addressName: "兰陵县",
            parentId: "371300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371325,
            addressName: "费县",
            parentId: "371300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371326,
            addressName: "平邑县",
            parentId: "371300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371327,
            addressName: "莒南县",
            parentId: "371300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371328,
            addressName: "蒙阴县",
            parentId: "371300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371329,
            addressName: "临沭县",
            parentId: "371300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371371,
            addressName: "临沂高新技术产业开发区",
            parentId: "371300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371372,
            addressName: "临沂经济技术开发区",
            parentId: "371300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371373,
            addressName: "临沂临港经济开发区",
            parentId: "371300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 371400,
        addressName: "德州市",
        parentId: "370000",
        updateTime: null,
        addressList: [
          {
            id: 371401,
            addressName: "市辖区",
            parentId: "371400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371402,
            addressName: "德城区",
            parentId: "371400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371403,
            addressName: "陵城区",
            parentId: "371400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371422,
            addressName: "宁津县",
            parentId: "371400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371423,
            addressName: "庆云县",
            parentId: "371400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371424,
            addressName: "临邑县",
            parentId: "371400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371425,
            addressName: "齐河县",
            parentId: "371400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371426,
            addressName: "平原县",
            parentId: "371400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371427,
            addressName: "夏津县",
            parentId: "371400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371428,
            addressName: "武城县",
            parentId: "371400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371471,
            addressName: "德州经济技术开发区",
            parentId: "371400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371472,
            addressName: "德州运河经济开发区",
            parentId: "371400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371481,
            addressName: "乐陵市",
            parentId: "371400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371482,
            addressName: "禹城市",
            parentId: "371400",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 371500,
        addressName: "聊城市",
        parentId: "370000",
        updateTime: null,
        addressList: [
          {
            id: 371501,
            addressName: "市辖区",
            parentId: "371500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371502,
            addressName: "东昌府区",
            parentId: "371500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371503,
            addressName: "茌平区",
            parentId: "371500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371521,
            addressName: "阳谷县",
            parentId: "371500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371522,
            addressName: "莘县",
            parentId: "371500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371524,
            addressName: "东阿县",
            parentId: "371500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371525,
            addressName: "冠县",
            parentId: "371500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371526,
            addressName: "高唐县",
            parentId: "371500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371581,
            addressName: "临清市",
            parentId: "371500",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 371600,
        addressName: "滨州市",
        parentId: "370000",
        updateTime: null,
        addressList: [
          {
            id: 371601,
            addressName: "市辖区",
            parentId: "371600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371602,
            addressName: "滨城区",
            parentId: "371600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371603,
            addressName: "沾化区",
            parentId: "371600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371621,
            addressName: "惠民县",
            parentId: "371600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371622,
            addressName: "阳信县",
            parentId: "371600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371623,
            addressName: "无棣县",
            parentId: "371600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371625,
            addressName: "博兴县",
            parentId: "371600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371681,
            addressName: "邹平市",
            parentId: "371600",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 371700,
        addressName: "菏泽市",
        parentId: "370000",
        updateTime: null,
        addressList: [
          {
            id: 371701,
            addressName: "市辖区",
            parentId: "371700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371702,
            addressName: "牡丹区",
            parentId: "371700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371703,
            addressName: "定陶区",
            parentId: "371700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371721,
            addressName: "曹县",
            parentId: "371700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371722,
            addressName: "单县",
            parentId: "371700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371723,
            addressName: "成武县",
            parentId: "371700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371724,
            addressName: "巨野县",
            parentId: "371700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371725,
            addressName: "郓城县",
            parentId: "371700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371726,
            addressName: "鄄城县",
            parentId: "371700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371728,
            addressName: "东明县",
            parentId: "371700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371771,
            addressName: "菏泽经济技术开发区",
            parentId: "371700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 371772,
            addressName: "菏泽高新技术开发区",
            parentId: "371700",
            updateTime: null,
            addressList: null,
          },
        ],
      },
    ],
  },
  {
    id: 410000,
    addressName: "河南省",
    parentId: "86",
    updateTime: null,
    addressList: [
      {
        id: 410100,
        addressName: "郑州市",
        parentId: "410000",
        updateTime: null,
        addressList: [
          {
            id: 410101,
            addressName: "市辖区",
            parentId: "410100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410102,
            addressName: "中原区",
            parentId: "410100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410103,
            addressName: "二七区",
            parentId: "410100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410104,
            addressName: "管城回族区",
            parentId: "410100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410105,
            addressName: "金水区",
            parentId: "410100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410106,
            addressName: "上街区",
            parentId: "410100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410108,
            addressName: "惠济区",
            parentId: "410100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410122,
            addressName: "中牟县",
            parentId: "410100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410171,
            addressName: "郑州经济技术开发区",
            parentId: "410100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410172,
            addressName: "郑州高新技术产业开发区",
            parentId: "410100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410173,
            addressName: "郑州航空港经济综合实验区",
            parentId: "410100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410181,
            addressName: "巩义市",
            parentId: "410100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410182,
            addressName: "荥阳市",
            parentId: "410100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410183,
            addressName: "新密市",
            parentId: "410100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410184,
            addressName: "新郑市",
            parentId: "410100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410185,
            addressName: "登封市",
            parentId: "410100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 410200,
        addressName: "开封市",
        parentId: "410000",
        updateTime: null,
        addressList: [
          {
            id: 410201,
            addressName: "市辖区",
            parentId: "410200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410202,
            addressName: "龙亭区",
            parentId: "410200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410203,
            addressName: "顺河回族区",
            parentId: "410200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410204,
            addressName: "鼓楼区",
            parentId: "410200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410205,
            addressName: "禹王台区",
            parentId: "410200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410212,
            addressName: "祥符区",
            parentId: "410200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410221,
            addressName: "杞县",
            parentId: "410200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410222,
            addressName: "通许县",
            parentId: "410200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410223,
            addressName: "尉氏县",
            parentId: "410200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410225,
            addressName: "兰考县",
            parentId: "410200",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 410300,
        addressName: "洛阳市",
        parentId: "410000",
        updateTime: null,
        addressList: [
          {
            id: 410301,
            addressName: "市辖区",
            parentId: "410300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410302,
            addressName: "老城区",
            parentId: "410300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410303,
            addressName: "西工区",
            parentId: "410300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410304,
            addressName: "瀍河回族区",
            parentId: "410300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410305,
            addressName: "涧西区",
            parentId: "410300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410306,
            addressName: "吉利区",
            parentId: "410300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410311,
            addressName: "洛龙区",
            parentId: "410300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410322,
            addressName: "孟津县",
            parentId: "410300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410323,
            addressName: "新安县",
            parentId: "410300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410324,
            addressName: "栾川县",
            parentId: "410300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410325,
            addressName: "嵩县",
            parentId: "410300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410326,
            addressName: "汝阳县",
            parentId: "410300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410327,
            addressName: "宜阳县",
            parentId: "410300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410328,
            addressName: "洛宁县",
            parentId: "410300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410329,
            addressName: "伊川县",
            parentId: "410300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410371,
            addressName: "洛阳高新技术产业开发区",
            parentId: "410300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410381,
            addressName: "偃师市",
            parentId: "410300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 410400,
        addressName: "平顶山市",
        parentId: "410000",
        updateTime: null,
        addressList: [
          {
            id: 410401,
            addressName: "市辖区",
            parentId: "410400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410402,
            addressName: "新华区",
            parentId: "410400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410403,
            addressName: "卫东区",
            parentId: "410400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410404,
            addressName: "石龙区",
            parentId: "410400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410411,
            addressName: "湛河区",
            parentId: "410400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410421,
            addressName: "宝丰县",
            parentId: "410400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410422,
            addressName: "叶县",
            parentId: "410400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410423,
            addressName: "鲁山县",
            parentId: "410400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410425,
            addressName: "郏县",
            parentId: "410400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410471,
            addressName: "平顶山高新技术产业开发区",
            parentId: "410400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410472,
            addressName: "平顶山市城乡一体化示范区",
            parentId: "410400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410481,
            addressName: "舞钢市",
            parentId: "410400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410482,
            addressName: "汝州市",
            parentId: "410400",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 410500,
        addressName: "安阳市",
        parentId: "410000",
        updateTime: null,
        addressList: [
          {
            id: 410501,
            addressName: "市辖区",
            parentId: "410500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410502,
            addressName: "文峰区",
            parentId: "410500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410503,
            addressName: "北关区",
            parentId: "410500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410505,
            addressName: "殷都区",
            parentId: "410500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410506,
            addressName: "龙安区",
            parentId: "410500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410522,
            addressName: "安阳县",
            parentId: "410500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410523,
            addressName: "汤阴县",
            parentId: "410500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410526,
            addressName: "滑县",
            parentId: "410500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410527,
            addressName: "内黄县",
            parentId: "410500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410571,
            addressName: "安阳高新技术产业开发区",
            parentId: "410500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410581,
            addressName: "林州市",
            parentId: "410500",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 410600,
        addressName: "鹤壁市",
        parentId: "410000",
        updateTime: null,
        addressList: [
          {
            id: 410601,
            addressName: "市辖区",
            parentId: "410600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410602,
            addressName: "鹤山区",
            parentId: "410600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410603,
            addressName: "山城区",
            parentId: "410600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410611,
            addressName: "淇滨区",
            parentId: "410600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410621,
            addressName: "浚县",
            parentId: "410600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410622,
            addressName: "淇县",
            parentId: "410600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410671,
            addressName: "鹤壁经济技术开发区",
            parentId: "410600",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 410700,
        addressName: "新乡市",
        parentId: "410000",
        updateTime: null,
        addressList: [
          {
            id: 410701,
            addressName: "市辖区",
            parentId: "410700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410702,
            addressName: "红旗区",
            parentId: "410700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410703,
            addressName: "卫滨区",
            parentId: "410700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410704,
            addressName: "凤泉区",
            parentId: "410700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410711,
            addressName: "牧野区",
            parentId: "410700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410721,
            addressName: "新乡县",
            parentId: "410700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410724,
            addressName: "获嘉县",
            parentId: "410700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410725,
            addressName: "原阳县",
            parentId: "410700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410726,
            addressName: "延津县",
            parentId: "410700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410727,
            addressName: "封丘县",
            parentId: "410700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410771,
            addressName: "新乡高新技术产业开发区",
            parentId: "410700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410772,
            addressName: "新乡经济技术开发区",
            parentId: "410700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410773,
            addressName: "新乡市平原城乡一体化示范区",
            parentId: "410700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410781,
            addressName: "卫辉市",
            parentId: "410700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410782,
            addressName: "辉县市",
            parentId: "410700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410783,
            addressName: "长垣市",
            parentId: "410700",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 410800,
        addressName: "焦作市",
        parentId: "410000",
        updateTime: null,
        addressList: [
          {
            id: 410801,
            addressName: "市辖区",
            parentId: "410800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410802,
            addressName: "解放区",
            parentId: "410800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410803,
            addressName: "中站区",
            parentId: "410800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410804,
            addressName: "马村区",
            parentId: "410800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410811,
            addressName: "山阳区",
            parentId: "410800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410821,
            addressName: "修武县",
            parentId: "410800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410822,
            addressName: "博爱县",
            parentId: "410800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410823,
            addressName: "武陟县",
            parentId: "410800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410825,
            addressName: "温县",
            parentId: "410800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410871,
            addressName: "焦作城乡一体化示范区",
            parentId: "410800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410882,
            addressName: "沁阳市",
            parentId: "410800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410883,
            addressName: "孟州市",
            parentId: "410800",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 410900,
        addressName: "濮阳市",
        parentId: "410000",
        updateTime: null,
        addressList: [
          {
            id: 410901,
            addressName: "市辖区",
            parentId: "410900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410902,
            addressName: "华龙区",
            parentId: "410900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410922,
            addressName: "清丰县",
            parentId: "410900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410923,
            addressName: "南乐县",
            parentId: "410900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410926,
            addressName: "范县",
            parentId: "410900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410927,
            addressName: "台前县",
            parentId: "410900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410928,
            addressName: "濮阳县",
            parentId: "410900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410971,
            addressName: "河南濮阳工业园区",
            parentId: "410900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 410972,
            addressName: "濮阳经济技术开发区",
            parentId: "410900",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 411000,
        addressName: "许昌市",
        parentId: "410000",
        updateTime: null,
        addressList: [
          {
            id: 411001,
            addressName: "市辖区",
            parentId: "411000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411002,
            addressName: "魏都区",
            parentId: "411000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411003,
            addressName: "建安区",
            parentId: "411000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411024,
            addressName: "鄢陵县",
            parentId: "411000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411025,
            addressName: "襄城县",
            parentId: "411000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411071,
            addressName: "许昌经济技术开发区",
            parentId: "411000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411081,
            addressName: "禹州市",
            parentId: "411000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411082,
            addressName: "长葛市",
            parentId: "411000",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 411100,
        addressName: "漯河市",
        parentId: "410000",
        updateTime: null,
        addressList: [
          {
            id: 411101,
            addressName: "市辖区",
            parentId: "411100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411102,
            addressName: "源汇区",
            parentId: "411100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411103,
            addressName: "郾城区",
            parentId: "411100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411104,
            addressName: "召陵区",
            parentId: "411100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411121,
            addressName: "舞阳县",
            parentId: "411100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411122,
            addressName: "临颍县",
            parentId: "411100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411171,
            addressName: "漯河经济技术开发区",
            parentId: "411100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 411200,
        addressName: "三门峡市",
        parentId: "410000",
        updateTime: null,
        addressList: [
          {
            id: 411201,
            addressName: "市辖区",
            parentId: "411200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411202,
            addressName: "湖滨区",
            parentId: "411200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411203,
            addressName: "陕州区",
            parentId: "411200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411221,
            addressName: "渑池县",
            parentId: "411200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411224,
            addressName: "卢氏县",
            parentId: "411200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411271,
            addressName: "河南三门峡经济开发区",
            parentId: "411200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411281,
            addressName: "义马市",
            parentId: "411200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411282,
            addressName: "灵宝市",
            parentId: "411200",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 411300,
        addressName: "南阳市",
        parentId: "410000",
        updateTime: null,
        addressList: [
          {
            id: 411301,
            addressName: "市辖区",
            parentId: "411300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411302,
            addressName: "宛城区",
            parentId: "411300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411303,
            addressName: "卧龙区",
            parentId: "411300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411321,
            addressName: "南召县",
            parentId: "411300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411322,
            addressName: "方城县",
            parentId: "411300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411323,
            addressName: "西峡县",
            parentId: "411300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411324,
            addressName: "镇平县",
            parentId: "411300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411325,
            addressName: "内乡县",
            parentId: "411300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411326,
            addressName: "淅川县",
            parentId: "411300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411327,
            addressName: "社旗县",
            parentId: "411300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411328,
            addressName: "唐河县",
            parentId: "411300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411329,
            addressName: "新野县",
            parentId: "411300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411330,
            addressName: "桐柏县",
            parentId: "411300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411371,
            addressName: "南阳高新技术产业开发区",
            parentId: "411300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411372,
            addressName: "南阳市城乡一体化示范区",
            parentId: "411300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411381,
            addressName: "邓州市",
            parentId: "411300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 411400,
        addressName: "商丘市",
        parentId: "410000",
        updateTime: null,
        addressList: [
          {
            id: 411401,
            addressName: "市辖区",
            parentId: "411400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411402,
            addressName: "梁园区",
            parentId: "411400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411403,
            addressName: "睢阳区",
            parentId: "411400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411421,
            addressName: "民权县",
            parentId: "411400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411422,
            addressName: "睢县",
            parentId: "411400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411423,
            addressName: "宁陵县",
            parentId: "411400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411424,
            addressName: "柘城县",
            parentId: "411400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411425,
            addressName: "虞城县",
            parentId: "411400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411426,
            addressName: "夏邑县",
            parentId: "411400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411471,
            addressName: "豫东综合物流产业聚集区",
            parentId: "411400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411472,
            addressName: "河南商丘经济开发区",
            parentId: "411400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411481,
            addressName: "永城市",
            parentId: "411400",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 411500,
        addressName: "信阳市",
        parentId: "410000",
        updateTime: null,
        addressList: [
          {
            id: 411501,
            addressName: "市辖区",
            parentId: "411500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411502,
            addressName: "浉河区",
            parentId: "411500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411503,
            addressName: "平桥区",
            parentId: "411500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411521,
            addressName: "罗山县",
            parentId: "411500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411522,
            addressName: "光山县",
            parentId: "411500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411523,
            addressName: "新县",
            parentId: "411500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411524,
            addressName: "商城县",
            parentId: "411500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411525,
            addressName: "固始县",
            parentId: "411500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411526,
            addressName: "潢川县",
            parentId: "411500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411527,
            addressName: "淮滨县",
            parentId: "411500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411528,
            addressName: "息县",
            parentId: "411500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411571,
            addressName: "信阳高新技术产业开发区",
            parentId: "411500",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 411600,
        addressName: "周口市",
        parentId: "410000",
        updateTime: null,
        addressList: [
          {
            id: 411601,
            addressName: "市辖区",
            parentId: "411600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411602,
            addressName: "川汇区",
            parentId: "411600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411603,
            addressName: "淮阳区",
            parentId: "411600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411621,
            addressName: "扶沟县",
            parentId: "411600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411622,
            addressName: "西华县",
            parentId: "411600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411623,
            addressName: "商水县",
            parentId: "411600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411624,
            addressName: "沈丘县",
            parentId: "411600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411625,
            addressName: "郸城县",
            parentId: "411600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411627,
            addressName: "太康县",
            parentId: "411600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411628,
            addressName: "鹿邑县",
            parentId: "411600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411671,
            addressName: "河南周口经济开发区",
            parentId: "411600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411681,
            addressName: "项城市",
            parentId: "411600",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 411700,
        addressName: "驻马店市",
        parentId: "410000",
        updateTime: null,
        addressList: [
          {
            id: 411701,
            addressName: "市辖区",
            parentId: "411700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411702,
            addressName: "驿城区",
            parentId: "411700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411721,
            addressName: "西平县",
            parentId: "411700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411722,
            addressName: "上蔡县",
            parentId: "411700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411723,
            addressName: "平舆县",
            parentId: "411700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411724,
            addressName: "正阳县",
            parentId: "411700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411725,
            addressName: "确山县",
            parentId: "411700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411726,
            addressName: "泌阳县",
            parentId: "411700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411727,
            addressName: "汝南县",
            parentId: "411700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411728,
            addressName: "遂平县",
            parentId: "411700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411729,
            addressName: "新蔡县",
            parentId: "411700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 411771,
            addressName: "河南驻马店经济开发区",
            parentId: "411700",
            updateTime: null,
            addressList: null,
          },
        ],
      },
    ],
  },
  {
    id: 420000,
    addressName: "湖北省",
    parentId: "86",
    updateTime: null,
    addressList: [
      {
        id: 420100,
        addressName: "武汉市",
        parentId: "420000",
        updateTime: null,
        addressList: [
          {
            id: 420101,
            addressName: "市辖区",
            parentId: "420100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420102,
            addressName: "江岸区",
            parentId: "420100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420103,
            addressName: "江汉区",
            parentId: "420100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420104,
            addressName: "硚口区",
            parentId: "420100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420105,
            addressName: "汉阳区",
            parentId: "420100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420106,
            addressName: "武昌区",
            parentId: "420100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420107,
            addressName: "青山区",
            parentId: "420100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420111,
            addressName: "洪山区",
            parentId: "420100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420112,
            addressName: "东西湖区",
            parentId: "420100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420113,
            addressName: "汉南区",
            parentId: "420100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420114,
            addressName: "蔡甸区",
            parentId: "420100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420115,
            addressName: "江夏区",
            parentId: "420100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420116,
            addressName: "黄陂区",
            parentId: "420100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420117,
            addressName: "新洲区",
            parentId: "420100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 420200,
        addressName: "黄石市",
        parentId: "420000",
        updateTime: null,
        addressList: [
          {
            id: 420201,
            addressName: "市辖区",
            parentId: "420200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420202,
            addressName: "黄石港区",
            parentId: "420200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420203,
            addressName: "西塞山区",
            parentId: "420200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420204,
            addressName: "下陆区",
            parentId: "420200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420205,
            addressName: "铁山区",
            parentId: "420200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420222,
            addressName: "阳新县",
            parentId: "420200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420281,
            addressName: "大冶市",
            parentId: "420200",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 420300,
        addressName: "十堰市",
        parentId: "420000",
        updateTime: null,
        addressList: [
          {
            id: 420301,
            addressName: "市辖区",
            parentId: "420300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420302,
            addressName: "茅箭区",
            parentId: "420300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420303,
            addressName: "张湾区",
            parentId: "420300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420304,
            addressName: "郧阳区",
            parentId: "420300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420322,
            addressName: "郧西县",
            parentId: "420300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420323,
            addressName: "竹山县",
            parentId: "420300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420324,
            addressName: "竹溪县",
            parentId: "420300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420325,
            addressName: "房县",
            parentId: "420300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420381,
            addressName: "丹江口市",
            parentId: "420300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 420500,
        addressName: "宜昌市",
        parentId: "420000",
        updateTime: null,
        addressList: [
          {
            id: 420501,
            addressName: "市辖区",
            parentId: "420500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420502,
            addressName: "西陵区",
            parentId: "420500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420503,
            addressName: "伍家岗区",
            parentId: "420500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420504,
            addressName: "点军区",
            parentId: "420500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420505,
            addressName: "猇亭区",
            parentId: "420500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420506,
            addressName: "夷陵区",
            parentId: "420500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420525,
            addressName: "远安县",
            parentId: "420500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420526,
            addressName: "兴山县",
            parentId: "420500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420527,
            addressName: "秭归县",
            parentId: "420500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420528,
            addressName: "长阳土家族自治县",
            parentId: "420500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420529,
            addressName: "五峰土家族自治县",
            parentId: "420500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420581,
            addressName: "宜都市",
            parentId: "420500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420582,
            addressName: "当阳市",
            parentId: "420500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420583,
            addressName: "枝江市",
            parentId: "420500",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 420600,
        addressName: "襄阳市",
        parentId: "420000",
        updateTime: null,
        addressList: [
          {
            id: 420601,
            addressName: "市辖区",
            parentId: "420600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420602,
            addressName: "襄城区",
            parentId: "420600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420606,
            addressName: "樊城区",
            parentId: "420600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420607,
            addressName: "襄州区",
            parentId: "420600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420624,
            addressName: "南漳县",
            parentId: "420600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420625,
            addressName: "谷城县",
            parentId: "420600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420626,
            addressName: "保康县",
            parentId: "420600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420682,
            addressName: "老河口市",
            parentId: "420600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420683,
            addressName: "枣阳市",
            parentId: "420600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420684,
            addressName: "宜城市",
            parentId: "420600",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 420700,
        addressName: "鄂州市",
        parentId: "420000",
        updateTime: null,
        addressList: [
          {
            id: 420701,
            addressName: "市辖区",
            parentId: "420700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420702,
            addressName: "梁子湖区",
            parentId: "420700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420703,
            addressName: "华容区",
            parentId: "420700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420704,
            addressName: "鄂城区",
            parentId: "420700",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 420800,
        addressName: "荆门市",
        parentId: "420000",
        updateTime: null,
        addressList: [
          {
            id: 420801,
            addressName: "市辖区",
            parentId: "420800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420802,
            addressName: "东宝区",
            parentId: "420800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420804,
            addressName: "掇刀区",
            parentId: "420800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420822,
            addressName: "沙洋县",
            parentId: "420800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420881,
            addressName: "钟祥市",
            parentId: "420800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420882,
            addressName: "京山市",
            parentId: "420800",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 420900,
        addressName: "孝感市",
        parentId: "420000",
        updateTime: null,
        addressList: [
          {
            id: 420901,
            addressName: "市辖区",
            parentId: "420900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420902,
            addressName: "孝南区",
            parentId: "420900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420921,
            addressName: "孝昌县",
            parentId: "420900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420922,
            addressName: "大悟县",
            parentId: "420900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420923,
            addressName: "云梦县",
            parentId: "420900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420981,
            addressName: "应城市",
            parentId: "420900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420982,
            addressName: "安陆市",
            parentId: "420900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 420984,
            addressName: "汉川市",
            parentId: "420900",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 421000,
        addressName: "荆州市",
        parentId: "420000",
        updateTime: null,
        addressList: [
          {
            id: 421001,
            addressName: "市辖区",
            parentId: "421000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 421002,
            addressName: "沙市区",
            parentId: "421000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 421003,
            addressName: "荆州区",
            parentId: "421000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 421022,
            addressName: "公安县",
            parentId: "421000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 421023,
            addressName: "监利县",
            parentId: "421000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 421024,
            addressName: "江陵县",
            parentId: "421000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 421071,
            addressName: "荆州经济技术开发区",
            parentId: "421000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 421081,
            addressName: "石首市",
            parentId: "421000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 421083,
            addressName: "洪湖市",
            parentId: "421000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 421087,
            addressName: "松滋市",
            parentId: "421000",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 421100,
        addressName: "黄冈市",
        parentId: "420000",
        updateTime: null,
        addressList: [
          {
            id: 421101,
            addressName: "市辖区",
            parentId: "421100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 421102,
            addressName: "黄州区",
            parentId: "421100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 421121,
            addressName: "团风县",
            parentId: "421100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 421122,
            addressName: "红安县",
            parentId: "421100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 421123,
            addressName: "罗田县",
            parentId: "421100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 421124,
            addressName: "英山县",
            parentId: "421100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 421125,
            addressName: "浠水县",
            parentId: "421100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 421126,
            addressName: "蕲春县",
            parentId: "421100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 421127,
            addressName: "黄梅县",
            parentId: "421100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 421171,
            addressName: "龙感湖管理区",
            parentId: "421100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 421181,
            addressName: "麻城市",
            parentId: "421100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 421182,
            addressName: "武穴市",
            parentId: "421100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 421200,
        addressName: "咸宁市",
        parentId: "420000",
        updateTime: null,
        addressList: [
          {
            id: 421201,
            addressName: "市辖区",
            parentId: "421200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 421202,
            addressName: "咸安区",
            parentId: "421200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 421221,
            addressName: "嘉鱼县",
            parentId: "421200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 421222,
            addressName: "通城县",
            parentId: "421200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 421223,
            addressName: "崇阳县",
            parentId: "421200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 421224,
            addressName: "通山县",
            parentId: "421200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 421281,
            addressName: "赤壁市",
            parentId: "421200",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 421300,
        addressName: "随州市",
        parentId: "420000",
        updateTime: null,
        addressList: [
          {
            id: 421301,
            addressName: "市辖区",
            parentId: "421300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 421303,
            addressName: "曾都区",
            parentId: "421300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 421321,
            addressName: "随县",
            parentId: "421300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 421381,
            addressName: "广水市",
            parentId: "421300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 422800,
        addressName: "恩施土家族苗族自治州",
        parentId: "420000",
        updateTime: null,
        addressList: [
          {
            id: 422801,
            addressName: "恩施市",
            parentId: "422800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 422802,
            addressName: "利川市",
            parentId: "422800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 422822,
            addressName: "建始县",
            parentId: "422800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 422823,
            addressName: "巴东县",
            parentId: "422800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 422825,
            addressName: "宣恩县",
            parentId: "422800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 422826,
            addressName: "咸丰县",
            parentId: "422800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 422827,
            addressName: "来凤县",
            parentId: "422800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 422828,
            addressName: "鹤峰县",
            parentId: "422800",
            updateTime: null,
            addressList: null,
          },
        ],
      },
    ],
  },
  {
    id: 430000,
    addressName: "湖南省",
    parentId: "86",
    updateTime: null,
    addressList: [
      {
        id: 430100,
        addressName: "长沙市",
        parentId: "430000",
        updateTime: null,
        addressList: [
          {
            id: 430101,
            addressName: "市辖区",
            parentId: "430100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430102,
            addressName: "芙蓉区",
            parentId: "430100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430103,
            addressName: "天心区",
            parentId: "430100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430104,
            addressName: "岳麓区",
            parentId: "430100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430105,
            addressName: "开福区",
            parentId: "430100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430111,
            addressName: "雨花区",
            parentId: "430100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430112,
            addressName: "望城区",
            parentId: "430100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430121,
            addressName: "长沙县",
            parentId: "430100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430181,
            addressName: "浏阳市",
            parentId: "430100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430182,
            addressName: "宁乡市",
            parentId: "430100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 430200,
        addressName: "株洲市",
        parentId: "430000",
        updateTime: null,
        addressList: [
          {
            id: 430201,
            addressName: "市辖区",
            parentId: "430200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430202,
            addressName: "荷塘区",
            parentId: "430200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430203,
            addressName: "芦淞区",
            parentId: "430200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430204,
            addressName: "石峰区",
            parentId: "430200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430211,
            addressName: "天元区",
            parentId: "430200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430212,
            addressName: "渌口区",
            parentId: "430200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430223,
            addressName: "攸县",
            parentId: "430200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430224,
            addressName: "茶陵县",
            parentId: "430200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430225,
            addressName: "炎陵县",
            parentId: "430200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430271,
            addressName: "云龙示范区",
            parentId: "430200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430281,
            addressName: "醴陵市",
            parentId: "430200",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 430300,
        addressName: "湘潭市",
        parentId: "430000",
        updateTime: null,
        addressList: [
          {
            id: 430301,
            addressName: "市辖区",
            parentId: "430300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430302,
            addressName: "雨湖区",
            parentId: "430300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430304,
            addressName: "岳塘区",
            parentId: "430300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430321,
            addressName: "湘潭县",
            parentId: "430300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430371,
            addressName: "湖南湘潭高新技术产业园区",
            parentId: "430300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430372,
            addressName: "湘潭昭山示范区",
            parentId: "430300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430373,
            addressName: "湘潭九华示范区",
            parentId: "430300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430381,
            addressName: "湘乡市",
            parentId: "430300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430382,
            addressName: "韶山市",
            parentId: "430300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 430400,
        addressName: "衡阳市",
        parentId: "430000",
        updateTime: null,
        addressList: [
          {
            id: 430401,
            addressName: "市辖区",
            parentId: "430400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430405,
            addressName: "珠晖区",
            parentId: "430400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430406,
            addressName: "雁峰区",
            parentId: "430400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430407,
            addressName: "石鼓区",
            parentId: "430400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430408,
            addressName: "蒸湘区",
            parentId: "430400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430412,
            addressName: "南岳区",
            parentId: "430400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430421,
            addressName: "衡阳县",
            parentId: "430400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430422,
            addressName: "衡南县",
            parentId: "430400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430423,
            addressName: "衡山县",
            parentId: "430400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430424,
            addressName: "衡东县",
            parentId: "430400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430426,
            addressName: "祁东县",
            parentId: "430400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430471,
            addressName: "衡阳综合保税区",
            parentId: "430400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430472,
            addressName: "湖南衡阳高新技术产业园区",
            parentId: "430400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430473,
            addressName: "湖南衡阳松木经济开发区",
            parentId: "430400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430481,
            addressName: "耒阳市",
            parentId: "430400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430482,
            addressName: "常宁市",
            parentId: "430400",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 430500,
        addressName: "邵阳市",
        parentId: "430000",
        updateTime: null,
        addressList: [
          {
            id: 430501,
            addressName: "市辖区",
            parentId: "430500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430502,
            addressName: "双清区",
            parentId: "430500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430503,
            addressName: "大祥区",
            parentId: "430500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430511,
            addressName: "北塔区",
            parentId: "430500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430522,
            addressName: "新邵县",
            parentId: "430500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430523,
            addressName: "邵阳县",
            parentId: "430500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430524,
            addressName: "隆回县",
            parentId: "430500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430525,
            addressName: "洞口县",
            parentId: "430500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430527,
            addressName: "绥宁县",
            parentId: "430500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430528,
            addressName: "新宁县",
            parentId: "430500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430529,
            addressName: "城步苗族自治县",
            parentId: "430500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430581,
            addressName: "武冈市",
            parentId: "430500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430582,
            addressName: "邵东市",
            parentId: "430500",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 430600,
        addressName: "岳阳市",
        parentId: "430000",
        updateTime: null,
        addressList: [
          {
            id: 430601,
            addressName: "市辖区",
            parentId: "430600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430602,
            addressName: "岳阳楼区",
            parentId: "430600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430603,
            addressName: "云溪区",
            parentId: "430600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430611,
            addressName: "君山区",
            parentId: "430600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430621,
            addressName: "岳阳县",
            parentId: "430600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430623,
            addressName: "华容县",
            parentId: "430600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430624,
            addressName: "湘阴县",
            parentId: "430600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430626,
            addressName: "平江县",
            parentId: "430600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430671,
            addressName: "岳阳市屈原管理区",
            parentId: "430600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430681,
            addressName: "汨罗市",
            parentId: "430600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430682,
            addressName: "临湘市",
            parentId: "430600",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 430700,
        addressName: "常德市",
        parentId: "430000",
        updateTime: null,
        addressList: [
          {
            id: 430701,
            addressName: "市辖区",
            parentId: "430700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430702,
            addressName: "武陵区",
            parentId: "430700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430703,
            addressName: "鼎城区",
            parentId: "430700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430721,
            addressName: "安乡县",
            parentId: "430700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430722,
            addressName: "汉寿县",
            parentId: "430700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430723,
            addressName: "澧县",
            parentId: "430700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430724,
            addressName: "临澧县",
            parentId: "430700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430725,
            addressName: "桃源县",
            parentId: "430700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430726,
            addressName: "石门县",
            parentId: "430700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430771,
            addressName: "常德市西洞庭管理区",
            parentId: "430700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430781,
            addressName: "津市市",
            parentId: "430700",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 430800,
        addressName: "张家界市",
        parentId: "430000",
        updateTime: null,
        addressList: [
          {
            id: 430801,
            addressName: "市辖区",
            parentId: "430800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430802,
            addressName: "永定区",
            parentId: "430800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430811,
            addressName: "武陵源区",
            parentId: "430800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430821,
            addressName: "慈利县",
            parentId: "430800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430822,
            addressName: "桑植县",
            parentId: "430800",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 430900,
        addressName: "益阳市",
        parentId: "430000",
        updateTime: null,
        addressList: [
          {
            id: 430901,
            addressName: "市辖区",
            parentId: "430900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430902,
            addressName: "资阳区",
            parentId: "430900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430903,
            addressName: "赫山区",
            parentId: "430900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430921,
            addressName: "南县",
            parentId: "430900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430922,
            addressName: "桃江县",
            parentId: "430900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430923,
            addressName: "安化县",
            parentId: "430900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430971,
            addressName: "益阳市大通湖管理区",
            parentId: "430900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430972,
            addressName: "湖南益阳高新技术产业园区",
            parentId: "430900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 430981,
            addressName: "沅江市",
            parentId: "430900",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 431000,
        addressName: "郴州市",
        parentId: "430000",
        updateTime: null,
        addressList: [
          {
            id: 431001,
            addressName: "市辖区",
            parentId: "431000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 431002,
            addressName: "北湖区",
            parentId: "431000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 431003,
            addressName: "苏仙区",
            parentId: "431000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 431021,
            addressName: "桂阳县",
            parentId: "431000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 431022,
            addressName: "宜章县",
            parentId: "431000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 431023,
            addressName: "永兴县",
            parentId: "431000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 431024,
            addressName: "嘉禾县",
            parentId: "431000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 431025,
            addressName: "临武县",
            parentId: "431000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 431026,
            addressName: "汝城县",
            parentId: "431000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 431027,
            addressName: "桂东县",
            parentId: "431000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 431028,
            addressName: "安仁县",
            parentId: "431000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 431081,
            addressName: "资兴市",
            parentId: "431000",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 431100,
        addressName: "永州市",
        parentId: "430000",
        updateTime: null,
        addressList: [
          {
            id: 431101,
            addressName: "市辖区",
            parentId: "431100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 431102,
            addressName: "零陵区",
            parentId: "431100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 431103,
            addressName: "冷水滩区",
            parentId: "431100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 431121,
            addressName: "祁阳县",
            parentId: "431100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 431122,
            addressName: "东安县",
            parentId: "431100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 431123,
            addressName: "双牌县",
            parentId: "431100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 431124,
            addressName: "道县",
            parentId: "431100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 431125,
            addressName: "江永县",
            parentId: "431100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 431126,
            addressName: "宁远县",
            parentId: "431100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 431127,
            addressName: "蓝山县",
            parentId: "431100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 431128,
            addressName: "新田县",
            parentId: "431100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 431129,
            addressName: "江华瑶族自治县",
            parentId: "431100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 431171,
            addressName: "永州经济技术开发区",
            parentId: "431100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 431172,
            addressName: "永州市金洞管理区",
            parentId: "431100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 431173,
            addressName: "永州市回龙圩管理区",
            parentId: "431100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 431200,
        addressName: "怀化市",
        parentId: "430000",
        updateTime: null,
        addressList: [
          {
            id: 431201,
            addressName: "市辖区",
            parentId: "431200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 431202,
            addressName: "鹤城区",
            parentId: "431200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 431221,
            addressName: "中方县",
            parentId: "431200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 431222,
            addressName: "沅陵县",
            parentId: "431200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 431223,
            addressName: "辰溪县",
            parentId: "431200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 431224,
            addressName: "溆浦县",
            parentId: "431200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 431225,
            addressName: "会同县",
            parentId: "431200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 431226,
            addressName: "麻阳苗族自治县",
            parentId: "431200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 431227,
            addressName: "新晃侗族自治县",
            parentId: "431200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 431228,
            addressName: "芷江侗族自治县",
            parentId: "431200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 431229,
            addressName: "靖州苗族侗族自治县",
            parentId: "431200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 431230,
            addressName: "通道侗族自治县",
            parentId: "431200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 431271,
            addressName: "怀化市洪江管理区",
            parentId: "431200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 431281,
            addressName: "洪江市",
            parentId: "431200",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 431300,
        addressName: "娄底市",
        parentId: "430000",
        updateTime: null,
        addressList: [
          {
            id: 431301,
            addressName: "市辖区",
            parentId: "431300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 431302,
            addressName: "娄星区",
            parentId: "431300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 431321,
            addressName: "双峰县",
            parentId: "431300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 431322,
            addressName: "新化县",
            parentId: "431300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 431381,
            addressName: "冷水江市",
            parentId: "431300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 431382,
            addressName: "涟源市",
            parentId: "431300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 433100,
        addressName: "湘西土家族苗族自治州",
        parentId: "430000",
        updateTime: null,
        addressList: [
          {
            id: 433101,
            addressName: "吉首市",
            parentId: "433100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 433122,
            addressName: "泸溪县",
            parentId: "433100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 433123,
            addressName: "凤凰县",
            parentId: "433100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 433124,
            addressName: "花垣县",
            parentId: "433100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 433125,
            addressName: "保靖县",
            parentId: "433100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 433126,
            addressName: "古丈县",
            parentId: "433100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 433127,
            addressName: "永顺县",
            parentId: "433100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 433130,
            addressName: "龙山县",
            parentId: "433100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 433173,
            addressName: "湖南永顺经济开发区",
            parentId: "433100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
    ],
  },
  {
    id: 440000,
    addressName: "广东省",
    parentId: "86",
    updateTime: null,
    addressList: [
      {
        id: 440100,
        addressName: "广州市",
        parentId: "440000",
        updateTime: null,
        addressList: [
          {
            id: 440101,
            addressName: "市辖区",
            parentId: "440100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440103,
            addressName: "荔湾区",
            parentId: "440100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440104,
            addressName: "越秀区",
            parentId: "440100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440105,
            addressName: "海珠区",
            parentId: "440100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440106,
            addressName: "天河区",
            parentId: "440100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440111,
            addressName: "白云区",
            parentId: "440100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440112,
            addressName: "黄埔区",
            parentId: "440100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440113,
            addressName: "番禺区",
            parentId: "440100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440114,
            addressName: "花都区",
            parentId: "440100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440115,
            addressName: "南沙区",
            parentId: "440100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440117,
            addressName: "从化区",
            parentId: "440100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440118,
            addressName: "增城区",
            parentId: "440100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 440200,
        addressName: "韶关市",
        parentId: "440000",
        updateTime: null,
        addressList: [
          {
            id: 440201,
            addressName: "市辖区",
            parentId: "440200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440203,
            addressName: "武江区",
            parentId: "440200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440204,
            addressName: "浈江区",
            parentId: "440200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440205,
            addressName: "曲江区",
            parentId: "440200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440222,
            addressName: "始兴县",
            parentId: "440200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440224,
            addressName: "仁化县",
            parentId: "440200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440229,
            addressName: "翁源县",
            parentId: "440200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440232,
            addressName: "乳源瑶族自治县",
            parentId: "440200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440233,
            addressName: "新丰县",
            parentId: "440200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440281,
            addressName: "乐昌市",
            parentId: "440200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440282,
            addressName: "南雄市",
            parentId: "440200",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 440300,
        addressName: "深圳市",
        parentId: "440000",
        updateTime: null,
        addressList: [
          {
            id: 440301,
            addressName: "市辖区",
            parentId: "440300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440303,
            addressName: "罗湖区",
            parentId: "440300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440304,
            addressName: "福田区",
            parentId: "440300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440305,
            addressName: "南山区",
            parentId: "440300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440306,
            addressName: "宝安区",
            parentId: "440300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440307,
            addressName: "龙岗区",
            parentId: "440300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440308,
            addressName: "盐田区",
            parentId: "440300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440309,
            addressName: "龙华区",
            parentId: "440300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440310,
            addressName: "坪山区",
            parentId: "440300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440311,
            addressName: "光明区",
            parentId: "440300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 440400,
        addressName: "珠海市",
        parentId: "440000",
        updateTime: null,
        addressList: [
          {
            id: 440401,
            addressName: "市辖区",
            parentId: "440400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440402,
            addressName: "香洲区",
            parentId: "440400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440403,
            addressName: "斗门区",
            parentId: "440400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440404,
            addressName: "金湾区",
            parentId: "440400",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 440500,
        addressName: "汕头市",
        parentId: "440000",
        updateTime: null,
        addressList: [
          {
            id: 440501,
            addressName: "市辖区",
            parentId: "440500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440507,
            addressName: "龙湖区",
            parentId: "440500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440511,
            addressName: "金平区",
            parentId: "440500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440512,
            addressName: "濠江区",
            parentId: "440500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440513,
            addressName: "潮阳区",
            parentId: "440500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440514,
            addressName: "潮南区",
            parentId: "440500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440515,
            addressName: "澄海区",
            parentId: "440500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440523,
            addressName: "南澳县",
            parentId: "440500",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 440600,
        addressName: "佛山市",
        parentId: "440000",
        updateTime: null,
        addressList: [
          {
            id: 440601,
            addressName: "市辖区",
            parentId: "440600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440604,
            addressName: "禅城区",
            parentId: "440600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440605,
            addressName: "南海区",
            parentId: "440600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440606,
            addressName: "顺德区",
            parentId: "440600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440607,
            addressName: "三水区",
            parentId: "440600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440608,
            addressName: "高明区",
            parentId: "440600",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 440700,
        addressName: "江门市",
        parentId: "440000",
        updateTime: null,
        addressList: [
          {
            id: 440701,
            addressName: "市辖区",
            parentId: "440700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440703,
            addressName: "蓬江区",
            parentId: "440700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440704,
            addressName: "江海区",
            parentId: "440700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440705,
            addressName: "新会区",
            parentId: "440700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440781,
            addressName: "台山市",
            parentId: "440700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440783,
            addressName: "开平市",
            parentId: "440700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440784,
            addressName: "鹤山市",
            parentId: "440700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440785,
            addressName: "恩平市",
            parentId: "440700",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 440800,
        addressName: "湛江市",
        parentId: "440000",
        updateTime: null,
        addressList: [
          {
            id: 440801,
            addressName: "市辖区",
            parentId: "440800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440802,
            addressName: "赤坎区",
            parentId: "440800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440803,
            addressName: "霞山区",
            parentId: "440800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440804,
            addressName: "坡头区",
            parentId: "440800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440811,
            addressName: "麻章区",
            parentId: "440800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440823,
            addressName: "遂溪县",
            parentId: "440800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440825,
            addressName: "徐闻县",
            parentId: "440800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440881,
            addressName: "廉江市",
            parentId: "440800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440882,
            addressName: "雷州市",
            parentId: "440800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440883,
            addressName: "吴川市",
            parentId: "440800",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 440900,
        addressName: "茂名市",
        parentId: "440000",
        updateTime: null,
        addressList: [
          {
            id: 440901,
            addressName: "市辖区",
            parentId: "440900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440902,
            addressName: "茂南区",
            parentId: "440900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440904,
            addressName: "电白区",
            parentId: "440900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440981,
            addressName: "高州市",
            parentId: "440900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440982,
            addressName: "化州市",
            parentId: "440900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 440983,
            addressName: "信宜市",
            parentId: "440900",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 441200,
        addressName: "肇庆市",
        parentId: "440000",
        updateTime: null,
        addressList: [
          {
            id: 441201,
            addressName: "市辖区",
            parentId: "441200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441202,
            addressName: "端州区",
            parentId: "441200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441203,
            addressName: "鼎湖区",
            parentId: "441200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441204,
            addressName: "高要区",
            parentId: "441200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441223,
            addressName: "广宁县",
            parentId: "441200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441224,
            addressName: "怀集县",
            parentId: "441200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441225,
            addressName: "封开县",
            parentId: "441200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441226,
            addressName: "德庆县",
            parentId: "441200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441284,
            addressName: "四会市",
            parentId: "441200",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 441300,
        addressName: "惠州市",
        parentId: "440000",
        updateTime: null,
        addressList: [
          {
            id: 441301,
            addressName: "市辖区",
            parentId: "441300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441302,
            addressName: "惠城区",
            parentId: "441300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441303,
            addressName: "惠阳区",
            parentId: "441300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441322,
            addressName: "博罗县",
            parentId: "441300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441323,
            addressName: "惠东县",
            parentId: "441300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441324,
            addressName: "龙门县",
            parentId: "441300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 441400,
        addressName: "梅州市",
        parentId: "440000",
        updateTime: null,
        addressList: [
          {
            id: 441401,
            addressName: "市辖区",
            parentId: "441400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441402,
            addressName: "梅江区",
            parentId: "441400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441403,
            addressName: "梅县区",
            parentId: "441400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441422,
            addressName: "大埔县",
            parentId: "441400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441423,
            addressName: "丰顺县",
            parentId: "441400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441424,
            addressName: "五华县",
            parentId: "441400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441426,
            addressName: "平远县",
            parentId: "441400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441427,
            addressName: "蕉岭县",
            parentId: "441400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441481,
            addressName: "兴宁市",
            parentId: "441400",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 441500,
        addressName: "汕尾市",
        parentId: "440000",
        updateTime: null,
        addressList: [
          {
            id: 441501,
            addressName: "市辖区",
            parentId: "441500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441502,
            addressName: "城区",
            parentId: "441500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441521,
            addressName: "海丰县",
            parentId: "441500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441523,
            addressName: "陆河县",
            parentId: "441500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441581,
            addressName: "陆丰市",
            parentId: "441500",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 441600,
        addressName: "河源市",
        parentId: "440000",
        updateTime: null,
        addressList: [
          {
            id: 441601,
            addressName: "市辖区",
            parentId: "441600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441602,
            addressName: "源城区",
            parentId: "441600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441621,
            addressName: "紫金县",
            parentId: "441600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441622,
            addressName: "龙川县",
            parentId: "441600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441623,
            addressName: "连平县",
            parentId: "441600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441624,
            addressName: "和平县",
            parentId: "441600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441625,
            addressName: "东源县",
            parentId: "441600",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 441700,
        addressName: "阳江市",
        parentId: "440000",
        updateTime: null,
        addressList: [
          {
            id: 441701,
            addressName: "市辖区",
            parentId: "441700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441702,
            addressName: "江城区",
            parentId: "441700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441704,
            addressName: "阳东区",
            parentId: "441700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441721,
            addressName: "阳西县",
            parentId: "441700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441781,
            addressName: "阳春市",
            parentId: "441700",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 441800,
        addressName: "清远市",
        parentId: "440000",
        updateTime: null,
        addressList: [
          {
            id: 441801,
            addressName: "市辖区",
            parentId: "441800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441802,
            addressName: "清城区",
            parentId: "441800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441803,
            addressName: "清新区",
            parentId: "441800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441821,
            addressName: "佛冈县",
            parentId: "441800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441823,
            addressName: "阳山县",
            parentId: "441800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441825,
            addressName: "连山壮族瑶族自治县",
            parentId: "441800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441826,
            addressName: "连南瑶族自治县",
            parentId: "441800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441881,
            addressName: "英德市",
            parentId: "441800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441882,
            addressName: "连州市",
            parentId: "441800",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 441900,
        addressName: "东莞市",
        parentId: "440000",
        updateTime: null,
        addressList: [
          {
            id: 441900003,
            addressName: "东城街道",
            parentId: "441900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441900004,
            addressName: "南城街道",
            parentId: "441900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441900005,
            addressName: "万江街道",
            parentId: "441900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441900006,
            addressName: "莞城街道",
            parentId: "441900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441900101,
            addressName: "石碣镇",
            parentId: "441900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441900102,
            addressName: "石龙镇",
            parentId: "441900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441900103,
            addressName: "茶山镇",
            parentId: "441900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441900104,
            addressName: "石排镇",
            parentId: "441900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441900105,
            addressName: "企石镇",
            parentId: "441900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441900106,
            addressName: "横沥镇",
            parentId: "441900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441900107,
            addressName: "桥头镇",
            parentId: "441900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441900108,
            addressName: "谢岗镇",
            parentId: "441900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441900109,
            addressName: "东坑镇",
            parentId: "441900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441900110,
            addressName: "常平镇",
            parentId: "441900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441900111,
            addressName: "寮步镇",
            parentId: "441900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441900112,
            addressName: "樟木头镇",
            parentId: "441900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441900113,
            addressName: "大朗镇",
            parentId: "441900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441900114,
            addressName: "黄江镇",
            parentId: "441900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441900115,
            addressName: "清溪镇",
            parentId: "441900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441900116,
            addressName: "塘厦镇",
            parentId: "441900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441900117,
            addressName: "凤岗镇",
            parentId: "441900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441900118,
            addressName: "大岭山镇",
            parentId: "441900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441900119,
            addressName: "长安镇",
            parentId: "441900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441900121,
            addressName: "虎门镇",
            parentId: "441900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441900122,
            addressName: "厚街镇",
            parentId: "441900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441900123,
            addressName: "沙田镇",
            parentId: "441900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441900124,
            addressName: "道滘镇",
            parentId: "441900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441900125,
            addressName: "洪梅镇",
            parentId: "441900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441900126,
            addressName: "麻涌镇",
            parentId: "441900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441900127,
            addressName: "望牛墩镇",
            parentId: "441900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441900128,
            addressName: "中堂镇",
            parentId: "441900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441900129,
            addressName: "高埗镇",
            parentId: "441900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441900401,
            addressName: "松山湖",
            parentId: "441900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441900402,
            addressName: "东莞港",
            parentId: "441900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 441900403,
            addressName: "东莞生态园",
            parentId: "441900",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 442000,
        addressName: "中山市",
        parentId: "440000",
        updateTime: null,
        addressList: [
          {
            id: 442000001,
            addressName: "石岐街道",
            parentId: "442000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 442000002,
            addressName: "东区街道",
            parentId: "442000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 442000003,
            addressName: "中山港街道",
            parentId: "442000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 442000004,
            addressName: "西区街道",
            parentId: "442000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 442000005,
            addressName: "南区街道",
            parentId: "442000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 442000006,
            addressName: "五桂山街道",
            parentId: "442000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 442000100,
            addressName: "小榄镇",
            parentId: "442000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 442000101,
            addressName: "黄圃镇",
            parentId: "442000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 442000102,
            addressName: "民众镇",
            parentId: "442000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 442000103,
            addressName: "东凤镇",
            parentId: "442000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 442000104,
            addressName: "东升镇",
            parentId: "442000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 442000105,
            addressName: "古镇镇",
            parentId: "442000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 442000106,
            addressName: "沙溪镇",
            parentId: "442000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 442000107,
            addressName: "坦洲镇",
            parentId: "442000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 442000108,
            addressName: "港口镇",
            parentId: "442000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 442000109,
            addressName: "三角镇",
            parentId: "442000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 442000110,
            addressName: "横栏镇",
            parentId: "442000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 442000111,
            addressName: "南头镇",
            parentId: "442000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 442000112,
            addressName: "阜沙镇",
            parentId: "442000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 442000113,
            addressName: "南朗镇",
            parentId: "442000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 442000114,
            addressName: "三乡镇",
            parentId: "442000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 442000115,
            addressName: "板芙镇",
            parentId: "442000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 442000116,
            addressName: "大涌镇",
            parentId: "442000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 442000117,
            addressName: "神湾镇",
            parentId: "442000",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 445100,
        addressName: "潮州市",
        parentId: "440000",
        updateTime: null,
        addressList: [
          {
            id: 445101,
            addressName: "市辖区",
            parentId: "445100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 445102,
            addressName: "湘桥区",
            parentId: "445100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 445103,
            addressName: "潮安区",
            parentId: "445100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 445122,
            addressName: "饶平县",
            parentId: "445100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 445200,
        addressName: "揭阳市",
        parentId: "440000",
        updateTime: null,
        addressList: [
          {
            id: 445201,
            addressName: "市辖区",
            parentId: "445200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 445202,
            addressName: "榕城区",
            parentId: "445200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 445203,
            addressName: "揭东区",
            parentId: "445200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 445222,
            addressName: "揭西县",
            parentId: "445200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 445224,
            addressName: "惠来县",
            parentId: "445200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 445281,
            addressName: "普宁市",
            parentId: "445200",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 445300,
        addressName: "云浮市",
        parentId: "440000",
        updateTime: null,
        addressList: [
          {
            id: 445301,
            addressName: "市辖区",
            parentId: "445300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 445302,
            addressName: "云城区",
            parentId: "445300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 445303,
            addressName: "云安区",
            parentId: "445300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 445321,
            addressName: "新兴县",
            parentId: "445300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 445322,
            addressName: "郁南县",
            parentId: "445300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 445381,
            addressName: "罗定市",
            parentId: "445300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
    ],
  },
  {
    id: 450000,
    addressName: "广西壮族自治区",
    parentId: "86",
    updateTime: null,
    addressList: [
      {
        id: 450100,
        addressName: "南宁市",
        parentId: "450000",
        updateTime: null,
        addressList: [
          {
            id: 450101,
            addressName: "市辖区",
            parentId: "450100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450102,
            addressName: "兴宁区",
            parentId: "450100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450103,
            addressName: "青秀区",
            parentId: "450100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450105,
            addressName: "江南区",
            parentId: "450100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450107,
            addressName: "西乡塘区",
            parentId: "450100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450108,
            addressName: "良庆区",
            parentId: "450100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450109,
            addressName: "邕宁区",
            parentId: "450100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450110,
            addressName: "武鸣区",
            parentId: "450100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450123,
            addressName: "隆安县",
            parentId: "450100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450124,
            addressName: "马山县",
            parentId: "450100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450125,
            addressName: "上林县",
            parentId: "450100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450126,
            addressName: "宾阳县",
            parentId: "450100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450127,
            addressName: "横县",
            parentId: "450100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 450200,
        addressName: "柳州市",
        parentId: "450000",
        updateTime: null,
        addressList: [
          {
            id: 450201,
            addressName: "市辖区",
            parentId: "450200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450202,
            addressName: "城中区",
            parentId: "450200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450203,
            addressName: "鱼峰区",
            parentId: "450200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450204,
            addressName: "柳南区",
            parentId: "450200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450205,
            addressName: "柳北区",
            parentId: "450200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450206,
            addressName: "柳江区",
            parentId: "450200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450222,
            addressName: "柳城县",
            parentId: "450200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450223,
            addressName: "鹿寨县",
            parentId: "450200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450224,
            addressName: "融安县",
            parentId: "450200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450225,
            addressName: "融水苗族自治县",
            parentId: "450200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450226,
            addressName: "三江侗族自治县",
            parentId: "450200",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 450300,
        addressName: "桂林市",
        parentId: "450000",
        updateTime: null,
        addressList: [
          {
            id: 450301,
            addressName: "市辖区",
            parentId: "450300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450302,
            addressName: "秀峰区",
            parentId: "450300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450303,
            addressName: "叠彩区",
            parentId: "450300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450304,
            addressName: "象山区",
            parentId: "450300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450305,
            addressName: "七星区",
            parentId: "450300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450311,
            addressName: "雁山区",
            parentId: "450300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450312,
            addressName: "临桂区",
            parentId: "450300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450321,
            addressName: "阳朔县",
            parentId: "450300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450323,
            addressName: "灵川县",
            parentId: "450300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450324,
            addressName: "全州县",
            parentId: "450300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450325,
            addressName: "兴安县",
            parentId: "450300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450326,
            addressName: "永福县",
            parentId: "450300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450327,
            addressName: "灌阳县",
            parentId: "450300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450328,
            addressName: "龙胜各族自治县",
            parentId: "450300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450329,
            addressName: "资源县",
            parentId: "450300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450330,
            addressName: "平乐县",
            parentId: "450300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450332,
            addressName: "恭城瑶族自治县",
            parentId: "450300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450381,
            addressName: "荔浦市",
            parentId: "450300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 450400,
        addressName: "梧州市",
        parentId: "450000",
        updateTime: null,
        addressList: [
          {
            id: 450401,
            addressName: "市辖区",
            parentId: "450400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450403,
            addressName: "万秀区",
            parentId: "450400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450405,
            addressName: "长洲区",
            parentId: "450400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450406,
            addressName: "龙圩区",
            parentId: "450400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450421,
            addressName: "苍梧县",
            parentId: "450400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450422,
            addressName: "藤县",
            parentId: "450400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450423,
            addressName: "蒙山县",
            parentId: "450400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450481,
            addressName: "岑溪市",
            parentId: "450400",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 450500,
        addressName: "北海市",
        parentId: "450000",
        updateTime: null,
        addressList: [
          {
            id: 450501,
            addressName: "市辖区",
            parentId: "450500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450502,
            addressName: "海城区",
            parentId: "450500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450503,
            addressName: "银海区",
            parentId: "450500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450512,
            addressName: "铁山港区",
            parentId: "450500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450521,
            addressName: "合浦县",
            parentId: "450500",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 450600,
        addressName: "防城港市",
        parentId: "450000",
        updateTime: null,
        addressList: [
          {
            id: 450601,
            addressName: "市辖区",
            parentId: "450600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450602,
            addressName: "港口区",
            parentId: "450600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450603,
            addressName: "防城区",
            parentId: "450600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450621,
            addressName: "上思县",
            parentId: "450600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450681,
            addressName: "东兴市",
            parentId: "450600",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 450700,
        addressName: "钦州市",
        parentId: "450000",
        updateTime: null,
        addressList: [
          {
            id: 450701,
            addressName: "市辖区",
            parentId: "450700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450702,
            addressName: "钦南区",
            parentId: "450700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450703,
            addressName: "钦北区",
            parentId: "450700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450721,
            addressName: "灵山县",
            parentId: "450700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450722,
            addressName: "浦北县",
            parentId: "450700",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 450800,
        addressName: "贵港市",
        parentId: "450000",
        updateTime: null,
        addressList: [
          {
            id: 450801,
            addressName: "市辖区",
            parentId: "450800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450802,
            addressName: "港北区",
            parentId: "450800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450803,
            addressName: "港南区",
            parentId: "450800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450804,
            addressName: "覃塘区",
            parentId: "450800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450821,
            addressName: "平南县",
            parentId: "450800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450881,
            addressName: "桂平市",
            parentId: "450800",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 450900,
        addressName: "玉林市",
        parentId: "450000",
        updateTime: null,
        addressList: [
          {
            id: 450901,
            addressName: "市辖区",
            parentId: "450900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450902,
            addressName: "玉州区",
            parentId: "450900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450903,
            addressName: "福绵区",
            parentId: "450900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450921,
            addressName: "容县",
            parentId: "450900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450922,
            addressName: "陆川县",
            parentId: "450900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450923,
            addressName: "博白县",
            parentId: "450900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450924,
            addressName: "兴业县",
            parentId: "450900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 450981,
            addressName: "北流市",
            parentId: "450900",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 451000,
        addressName: "百色市",
        parentId: "450000",
        updateTime: null,
        addressList: [
          {
            id: 451001,
            addressName: "市辖区",
            parentId: "451000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 451002,
            addressName: "右江区",
            parentId: "451000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 451003,
            addressName: "田阳区",
            parentId: "451000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 451022,
            addressName: "田东县",
            parentId: "451000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 451023,
            addressName: "平果县",
            parentId: "451000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 451024,
            addressName: "德保县",
            parentId: "451000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 451026,
            addressName: "那坡县",
            parentId: "451000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 451027,
            addressName: "凌云县",
            parentId: "451000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 451028,
            addressName: "乐业县",
            parentId: "451000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 451029,
            addressName: "田林县",
            parentId: "451000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 451030,
            addressName: "西林县",
            parentId: "451000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 451031,
            addressName: "隆林各族自治县",
            parentId: "451000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 451081,
            addressName: "靖西市",
            parentId: "451000",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 451100,
        addressName: "贺州市",
        parentId: "450000",
        updateTime: null,
        addressList: [
          {
            id: 451101,
            addressName: "市辖区",
            parentId: "451100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 451102,
            addressName: "八步区",
            parentId: "451100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 451103,
            addressName: "平桂区",
            parentId: "451100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 451121,
            addressName: "昭平县",
            parentId: "451100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 451122,
            addressName: "钟山县",
            parentId: "451100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 451123,
            addressName: "富川瑶族自治县",
            parentId: "451100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 451200,
        addressName: "河池市",
        parentId: "450000",
        updateTime: null,
        addressList: [
          {
            id: 451201,
            addressName: "市辖区",
            parentId: "451200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 451202,
            addressName: "金城江区",
            parentId: "451200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 451203,
            addressName: "宜州区",
            parentId: "451200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 451221,
            addressName: "南丹县",
            parentId: "451200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 451222,
            addressName: "天峨县",
            parentId: "451200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 451223,
            addressName: "凤山县",
            parentId: "451200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 451224,
            addressName: "东兰县",
            parentId: "451200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 451225,
            addressName: "罗城仫佬族自治县",
            parentId: "451200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 451226,
            addressName: "环江毛南族自治县",
            parentId: "451200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 451227,
            addressName: "巴马瑶族自治县",
            parentId: "451200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 451228,
            addressName: "都安瑶族自治县",
            parentId: "451200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 451229,
            addressName: "大化瑶族自治县",
            parentId: "451200",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 451300,
        addressName: "来宾市",
        parentId: "450000",
        updateTime: null,
        addressList: [
          {
            id: 451301,
            addressName: "市辖区",
            parentId: "451300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 451302,
            addressName: "兴宾区",
            parentId: "451300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 451321,
            addressName: "忻城县",
            parentId: "451300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 451322,
            addressName: "象州县",
            parentId: "451300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 451323,
            addressName: "武宣县",
            parentId: "451300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 451324,
            addressName: "金秀瑶族自治县",
            parentId: "451300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 451381,
            addressName: "合山市",
            parentId: "451300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 451400,
        addressName: "崇左市",
        parentId: "450000",
        updateTime: null,
        addressList: [
          {
            id: 451401,
            addressName: "市辖区",
            parentId: "451400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 451402,
            addressName: "江州区",
            parentId: "451400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 451421,
            addressName: "扶绥县",
            parentId: "451400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 451422,
            addressName: "宁明县",
            parentId: "451400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 451423,
            addressName: "龙州县",
            parentId: "451400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 451424,
            addressName: "大新县",
            parentId: "451400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 451425,
            addressName: "天等县",
            parentId: "451400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 451481,
            addressName: "凭祥市",
            parentId: "451400",
            updateTime: null,
            addressList: null,
          },
        ],
      },
    ],
  },
  {
    id: 460000,
    addressName: "海南省",
    parentId: "86",
    updateTime: null,
    addressList: [
      {
        id: 460100,
        addressName: "海口市",
        parentId: "460000",
        updateTime: null,
        addressList: [
          {
            id: 460101,
            addressName: "市辖区",
            parentId: "460100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 460105,
            addressName: "秀英区",
            parentId: "460100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 460106,
            addressName: "龙华区",
            parentId: "460100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 460107,
            addressName: "琼山区",
            parentId: "460100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 460108,
            addressName: "美兰区",
            parentId: "460100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 460200,
        addressName: "三亚市",
        parentId: "460000",
        updateTime: null,
        addressList: [
          {
            id: 460201,
            addressName: "市辖区",
            parentId: "460200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 460202,
            addressName: "海棠区",
            parentId: "460200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 460203,
            addressName: "吉阳区",
            parentId: "460200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 460204,
            addressName: "天涯区",
            parentId: "460200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 460205,
            addressName: "崖州区",
            parentId: "460200",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 460300,
        addressName: "三沙市",
        parentId: "460000",
        updateTime: null,
        addressList: [
          {
            id: 460321,
            addressName: "西沙群岛",
            parentId: "460300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 460322,
            addressName: "南沙群岛",
            parentId: "460300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 460323,
            addressName: "中沙群岛的岛礁及其海域",
            parentId: "460300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 460400,
        addressName: "儋州市",
        parentId: "460000",
        updateTime: null,
        addressList: [
          {
            id: 460400100,
            addressName: "那大镇",
            parentId: "460400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 460400101,
            addressName: "和庆镇",
            parentId: "460400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 460400102,
            addressName: "南丰镇",
            parentId: "460400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 460400103,
            addressName: "大成镇",
            parentId: "460400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 460400104,
            addressName: "雅星镇",
            parentId: "460400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 460400105,
            addressName: "兰洋镇",
            parentId: "460400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 460400106,
            addressName: "光村镇",
            parentId: "460400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 460400107,
            addressName: "木棠镇",
            parentId: "460400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 460400108,
            addressName: "海头镇",
            parentId: "460400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 460400109,
            addressName: "峨蔓镇",
            parentId: "460400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 460400111,
            addressName: "王五镇",
            parentId: "460400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 460400112,
            addressName: "白马井镇",
            parentId: "460400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 460400113,
            addressName: "中和镇",
            parentId: "460400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 460400114,
            addressName: "排浦镇",
            parentId: "460400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 460400115,
            addressName: "东成镇",
            parentId: "460400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 460400116,
            addressName: "新州镇",
            parentId: "460400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 460400499,
            addressName: "洋浦经济开发区",
            parentId: "460400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 460400500,
            addressName: "华南热作学院",
            parentId: "460400",
            updateTime: null,
            addressList: null,
          },
        ],
      },
    ],
  },
  {
    id: 500000,
    addressName: "重庆市",
    parentId: "86",
    updateTime: null,
    addressList: [
      {
        id: 500100,
        addressName: "市辖区",
        parentId: "500000",
        updateTime: null,
        addressList: [
          {
            id: 500101,
            addressName: "万州区",
            parentId: "500100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 500102,
            addressName: "涪陵区",
            parentId: "500100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 500103,
            addressName: "渝中区",
            parentId: "500100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 500104,
            addressName: "大渡口区",
            parentId: "500100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 500105,
            addressName: "江北区",
            parentId: "500100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 500106,
            addressName: "沙坪坝区",
            parentId: "500100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 500107,
            addressName: "九龙坡区",
            parentId: "500100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 500108,
            addressName: "南岸区",
            parentId: "500100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 500109,
            addressName: "北碚区",
            parentId: "500100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 500110,
            addressName: "綦江区",
            parentId: "500100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 500111,
            addressName: "大足区",
            parentId: "500100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 500112,
            addressName: "渝北区",
            parentId: "500100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 500113,
            addressName: "巴南区",
            parentId: "500100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 500114,
            addressName: "黔江区",
            parentId: "500100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 500115,
            addressName: "长寿区",
            parentId: "500100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 500116,
            addressName: "江津区",
            parentId: "500100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 500117,
            addressName: "合川区",
            parentId: "500100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 500118,
            addressName: "永川区",
            parentId: "500100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 500119,
            addressName: "南川区",
            parentId: "500100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 500120,
            addressName: "璧山区",
            parentId: "500100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 500151,
            addressName: "铜梁区",
            parentId: "500100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 500152,
            addressName: "潼南区",
            parentId: "500100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 500153,
            addressName: "荣昌区",
            parentId: "500100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 500154,
            addressName: "开州区",
            parentId: "500100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 500155,
            addressName: "梁平区",
            parentId: "500100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 500156,
            addressName: "武隆区",
            parentId: "500100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 500200,
        addressName: "县",
        parentId: "500000",
        updateTime: null,
        addressList: [
          {
            id: 500229,
            addressName: "城口县",
            parentId: "500200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 500230,
            addressName: "丰都县",
            parentId: "500200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 500231,
            addressName: "垫江县",
            parentId: "500200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 500233,
            addressName: "忠县",
            parentId: "500200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 500235,
            addressName: "云阳县",
            parentId: "500200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 500236,
            addressName: "奉节县",
            parentId: "500200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 500237,
            addressName: "巫山县",
            parentId: "500200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 500238,
            addressName: "巫溪县",
            parentId: "500200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 500240,
            addressName: "石柱土家族自治县",
            parentId: "500200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 500241,
            addressName: "秀山土家族苗族自治县",
            parentId: "500200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 500242,
            addressName: "酉阳土家族苗族自治县",
            parentId: "500200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 500243,
            addressName: "彭水苗族土家族自治县",
            parentId: "500200",
            updateTime: null,
            addressList: null,
          },
        ],
      },
    ],
  },
  {
    id: 510000,
    addressName: "四川省",
    parentId: "86",
    updateTime: null,
    addressList: [
      {
        id: 510100,
        addressName: "成都市",
        parentId: "510000",
        updateTime: null,
        addressList: [
          {
            id: 510101,
            addressName: "市辖区",
            parentId: "510100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510104,
            addressName: "锦江区",
            parentId: "510100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510105,
            addressName: "青羊区",
            parentId: "510100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510106,
            addressName: "金牛区",
            parentId: "510100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510107,
            addressName: "武侯区",
            parentId: "510100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510108,
            addressName: "成华区",
            parentId: "510100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510112,
            addressName: "龙泉驿区",
            parentId: "510100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510113,
            addressName: "青白江区",
            parentId: "510100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510114,
            addressName: "新都区",
            parentId: "510100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510115,
            addressName: "温江区",
            parentId: "510100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510116,
            addressName: "双流区",
            parentId: "510100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510117,
            addressName: "郫都区",
            parentId: "510100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510121,
            addressName: "金堂县",
            parentId: "510100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510129,
            addressName: "大邑县",
            parentId: "510100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510131,
            addressName: "蒲江县",
            parentId: "510100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510132,
            addressName: "新津县",
            parentId: "510100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510181,
            addressName: "都江堰市",
            parentId: "510100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510182,
            addressName: "彭州市",
            parentId: "510100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510183,
            addressName: "邛崃市",
            parentId: "510100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510184,
            addressName: "崇州市",
            parentId: "510100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510185,
            addressName: "简阳市",
            parentId: "510100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 510300,
        addressName: "自贡市",
        parentId: "510000",
        updateTime: null,
        addressList: [
          {
            id: 510301,
            addressName: "市辖区",
            parentId: "510300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510302,
            addressName: "自流井区",
            parentId: "510300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510303,
            addressName: "贡井区",
            parentId: "510300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510304,
            addressName: "大安区",
            parentId: "510300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510311,
            addressName: "沿滩区",
            parentId: "510300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510321,
            addressName: "荣县",
            parentId: "510300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510322,
            addressName: "富顺县",
            parentId: "510300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 510400,
        addressName: "攀枝花市",
        parentId: "510000",
        updateTime: null,
        addressList: [
          {
            id: 510401,
            addressName: "市辖区",
            parentId: "510400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510402,
            addressName: "东区",
            parentId: "510400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510403,
            addressName: "西区",
            parentId: "510400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510411,
            addressName: "仁和区",
            parentId: "510400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510421,
            addressName: "米易县",
            parentId: "510400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510422,
            addressName: "盐边县",
            parentId: "510400",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 510500,
        addressName: "泸州市",
        parentId: "510000",
        updateTime: null,
        addressList: [
          {
            id: 510501,
            addressName: "市辖区",
            parentId: "510500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510502,
            addressName: "江阳区",
            parentId: "510500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510503,
            addressName: "纳溪区",
            parentId: "510500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510504,
            addressName: "龙马潭区",
            parentId: "510500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510521,
            addressName: "泸县",
            parentId: "510500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510522,
            addressName: "合江县",
            parentId: "510500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510524,
            addressName: "叙永县",
            parentId: "510500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510525,
            addressName: "古蔺县",
            parentId: "510500",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 510600,
        addressName: "德阳市",
        parentId: "510000",
        updateTime: null,
        addressList: [
          {
            id: 510601,
            addressName: "市辖区",
            parentId: "510600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510603,
            addressName: "旌阳区",
            parentId: "510600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510604,
            addressName: "罗江区",
            parentId: "510600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510623,
            addressName: "中江县",
            parentId: "510600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510681,
            addressName: "广汉市",
            parentId: "510600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510682,
            addressName: "什邡市",
            parentId: "510600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510683,
            addressName: "绵竹市",
            parentId: "510600",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 510700,
        addressName: "绵阳市",
        parentId: "510000",
        updateTime: null,
        addressList: [
          {
            id: 510701,
            addressName: "市辖区",
            parentId: "510700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510703,
            addressName: "涪城区",
            parentId: "510700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510704,
            addressName: "游仙区",
            parentId: "510700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510705,
            addressName: "安州区",
            parentId: "510700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510722,
            addressName: "三台县",
            parentId: "510700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510723,
            addressName: "盐亭县",
            parentId: "510700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510725,
            addressName: "梓潼县",
            parentId: "510700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510726,
            addressName: "北川羌族自治县",
            parentId: "510700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510727,
            addressName: "平武县",
            parentId: "510700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510781,
            addressName: "江油市",
            parentId: "510700",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 510800,
        addressName: "广元市",
        parentId: "510000",
        updateTime: null,
        addressList: [
          {
            id: 510801,
            addressName: "市辖区",
            parentId: "510800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510802,
            addressName: "利州区",
            parentId: "510800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510811,
            addressName: "昭化区",
            parentId: "510800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510812,
            addressName: "朝天区",
            parentId: "510800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510821,
            addressName: "旺苍县",
            parentId: "510800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510822,
            addressName: "青川县",
            parentId: "510800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510823,
            addressName: "剑阁县",
            parentId: "510800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510824,
            addressName: "苍溪县",
            parentId: "510800",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 510900,
        addressName: "遂宁市",
        parentId: "510000",
        updateTime: null,
        addressList: [
          {
            id: 510901,
            addressName: "市辖区",
            parentId: "510900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510903,
            addressName: "船山区",
            parentId: "510900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510904,
            addressName: "安居区",
            parentId: "510900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510921,
            addressName: "蓬溪县",
            parentId: "510900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510923,
            addressName: "大英县",
            parentId: "510900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 510981,
            addressName: "射洪市",
            parentId: "510900",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 511000,
        addressName: "内江市",
        parentId: "510000",
        updateTime: null,
        addressList: [
          {
            id: 511001,
            addressName: "市辖区",
            parentId: "511000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511002,
            addressName: "市中区",
            parentId: "511000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511011,
            addressName: "东兴区",
            parentId: "511000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511024,
            addressName: "威远县",
            parentId: "511000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511025,
            addressName: "资中县",
            parentId: "511000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511071,
            addressName: "内江经济开发区",
            parentId: "511000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511083,
            addressName: "隆昌市",
            parentId: "511000",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 511100,
        addressName: "乐山市",
        parentId: "510000",
        updateTime: null,
        addressList: [
          {
            id: 511101,
            addressName: "市辖区",
            parentId: "511100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511102,
            addressName: "市中区",
            parentId: "511100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511111,
            addressName: "沙湾区",
            parentId: "511100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511112,
            addressName: "五通桥区",
            parentId: "511100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511113,
            addressName: "金口河区",
            parentId: "511100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511123,
            addressName: "犍为县",
            parentId: "511100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511124,
            addressName: "井研县",
            parentId: "511100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511126,
            addressName: "夹江县",
            parentId: "511100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511129,
            addressName: "沐川县",
            parentId: "511100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511132,
            addressName: "峨边彝族自治县",
            parentId: "511100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511133,
            addressName: "马边彝族自治县",
            parentId: "511100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511181,
            addressName: "峨眉山市",
            parentId: "511100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 511300,
        addressName: "南充市",
        parentId: "510000",
        updateTime: null,
        addressList: [
          {
            id: 511301,
            addressName: "市辖区",
            parentId: "511300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511302,
            addressName: "顺庆区",
            parentId: "511300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511303,
            addressName: "高坪区",
            parentId: "511300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511304,
            addressName: "嘉陵区",
            parentId: "511300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511321,
            addressName: "南部县",
            parentId: "511300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511322,
            addressName: "营山县",
            parentId: "511300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511323,
            addressName: "蓬安县",
            parentId: "511300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511324,
            addressName: "仪陇县",
            parentId: "511300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511325,
            addressName: "西充县",
            parentId: "511300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511381,
            addressName: "阆中市",
            parentId: "511300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 511400,
        addressName: "眉山市",
        parentId: "510000",
        updateTime: null,
        addressList: [
          {
            id: 511401,
            addressName: "市辖区",
            parentId: "511400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511402,
            addressName: "东坡区",
            parentId: "511400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511403,
            addressName: "彭山区",
            parentId: "511400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511421,
            addressName: "仁寿县",
            parentId: "511400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511423,
            addressName: "洪雅县",
            parentId: "511400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511424,
            addressName: "丹棱县",
            parentId: "511400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511425,
            addressName: "青神县",
            parentId: "511400",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 511500,
        addressName: "宜宾市",
        parentId: "510000",
        updateTime: null,
        addressList: [
          {
            id: 511501,
            addressName: "市辖区",
            parentId: "511500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511502,
            addressName: "翠屏区",
            parentId: "511500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511503,
            addressName: "南溪区",
            parentId: "511500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511504,
            addressName: "叙州区",
            parentId: "511500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511523,
            addressName: "江安县",
            parentId: "511500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511524,
            addressName: "长宁县",
            parentId: "511500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511525,
            addressName: "高县",
            parentId: "511500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511526,
            addressName: "珙县",
            parentId: "511500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511527,
            addressName: "筠连县",
            parentId: "511500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511528,
            addressName: "兴文县",
            parentId: "511500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511529,
            addressName: "屏山县",
            parentId: "511500",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 511600,
        addressName: "广安市",
        parentId: "510000",
        updateTime: null,
        addressList: [
          {
            id: 511601,
            addressName: "市辖区",
            parentId: "511600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511602,
            addressName: "广安区",
            parentId: "511600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511603,
            addressName: "前锋区",
            parentId: "511600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511621,
            addressName: "岳池县",
            parentId: "511600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511622,
            addressName: "武胜县",
            parentId: "511600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511623,
            addressName: "邻水县",
            parentId: "511600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511681,
            addressName: "华蓥市",
            parentId: "511600",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 511700,
        addressName: "达州市",
        parentId: "510000",
        updateTime: null,
        addressList: [
          {
            id: 511701,
            addressName: "市辖区",
            parentId: "511700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511702,
            addressName: "通川区",
            parentId: "511700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511703,
            addressName: "达川区",
            parentId: "511700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511722,
            addressName: "宣汉县",
            parentId: "511700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511723,
            addressName: "开江县",
            parentId: "511700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511724,
            addressName: "大竹县",
            parentId: "511700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511725,
            addressName: "渠县",
            parentId: "511700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511771,
            addressName: "达州经济开发区",
            parentId: "511700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511781,
            addressName: "万源市",
            parentId: "511700",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 511800,
        addressName: "雅安市",
        parentId: "510000",
        updateTime: null,
        addressList: [
          {
            id: 511801,
            addressName: "市辖区",
            parentId: "511800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511802,
            addressName: "雨城区",
            parentId: "511800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511803,
            addressName: "名山区",
            parentId: "511800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511822,
            addressName: "荥经县",
            parentId: "511800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511823,
            addressName: "汉源县",
            parentId: "511800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511824,
            addressName: "石棉县",
            parentId: "511800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511825,
            addressName: "天全县",
            parentId: "511800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511826,
            addressName: "芦山县",
            parentId: "511800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511827,
            addressName: "宝兴县",
            parentId: "511800",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 511900,
        addressName: "巴中市",
        parentId: "510000",
        updateTime: null,
        addressList: [
          {
            id: 511901,
            addressName: "市辖区",
            parentId: "511900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511902,
            addressName: "巴州区",
            parentId: "511900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511903,
            addressName: "恩阳区",
            parentId: "511900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511921,
            addressName: "通江县",
            parentId: "511900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511922,
            addressName: "南江县",
            parentId: "511900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511923,
            addressName: "平昌县",
            parentId: "511900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 511971,
            addressName: "巴中经济开发区",
            parentId: "511900",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 512000,
        addressName: "资阳市",
        parentId: "510000",
        updateTime: null,
        addressList: [
          {
            id: 512001,
            addressName: "市辖区",
            parentId: "512000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 512002,
            addressName: "雁江区",
            parentId: "512000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 512021,
            addressName: "安岳县",
            parentId: "512000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 512022,
            addressName: "乐至县",
            parentId: "512000",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 513200,
        addressName: "阿坝藏族羌族自治州",
        parentId: "510000",
        updateTime: null,
        addressList: [
          {
            id: 513201,
            addressName: "马尔康市",
            parentId: "513200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513221,
            addressName: "汶川县",
            parentId: "513200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513222,
            addressName: "理县",
            parentId: "513200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513223,
            addressName: "茂县",
            parentId: "513200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513224,
            addressName: "松潘县",
            parentId: "513200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513225,
            addressName: "九寨沟县",
            parentId: "513200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513226,
            addressName: "金川县",
            parentId: "513200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513227,
            addressName: "小金县",
            parentId: "513200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513228,
            addressName: "黑水县",
            parentId: "513200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513230,
            addressName: "壤塘县",
            parentId: "513200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513231,
            addressName: "阿坝县",
            parentId: "513200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513232,
            addressName: "若尔盖县",
            parentId: "513200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513233,
            addressName: "红原县",
            parentId: "513200",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 513300,
        addressName: "甘孜藏族自治州",
        parentId: "510000",
        updateTime: null,
        addressList: [
          {
            id: 513301,
            addressName: "康定市",
            parentId: "513300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513322,
            addressName: "泸定县",
            parentId: "513300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513323,
            addressName: "丹巴县",
            parentId: "513300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513324,
            addressName: "九龙县",
            parentId: "513300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513325,
            addressName: "雅江县",
            parentId: "513300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513326,
            addressName: "道孚县",
            parentId: "513300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513327,
            addressName: "炉霍县",
            parentId: "513300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513328,
            addressName: "甘孜县",
            parentId: "513300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513329,
            addressName: "新龙县",
            parentId: "513300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513330,
            addressName: "德格县",
            parentId: "513300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513331,
            addressName: "白玉县",
            parentId: "513300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513332,
            addressName: "石渠县",
            parentId: "513300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513333,
            addressName: "色达县",
            parentId: "513300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513334,
            addressName: "理塘县",
            parentId: "513300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513335,
            addressName: "巴塘县",
            parentId: "513300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513336,
            addressName: "乡城县",
            parentId: "513300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513337,
            addressName: "稻城县",
            parentId: "513300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513338,
            addressName: "得荣县",
            parentId: "513300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 513400,
        addressName: "凉山彝族自治州",
        parentId: "510000",
        updateTime: null,
        addressList: [
          {
            id: 513401,
            addressName: "西昌市",
            parentId: "513400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513422,
            addressName: "木里藏族自治县",
            parentId: "513400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513423,
            addressName: "盐源县",
            parentId: "513400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513424,
            addressName: "德昌县",
            parentId: "513400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513425,
            addressName: "会理县",
            parentId: "513400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513426,
            addressName: "会东县",
            parentId: "513400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513427,
            addressName: "宁南县",
            parentId: "513400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513428,
            addressName: "普格县",
            parentId: "513400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513429,
            addressName: "布拖县",
            parentId: "513400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513430,
            addressName: "金阳县",
            parentId: "513400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513431,
            addressName: "昭觉县",
            parentId: "513400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513432,
            addressName: "喜德县",
            parentId: "513400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513433,
            addressName: "冕宁县",
            parentId: "513400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513434,
            addressName: "越西县",
            parentId: "513400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513435,
            addressName: "甘洛县",
            parentId: "513400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513436,
            addressName: "美姑县",
            parentId: "513400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 513437,
            addressName: "雷波县",
            parentId: "513400",
            updateTime: null,
            addressList: null,
          },
        ],
      },
    ],
  },
  {
    id: 520000,
    addressName: "贵州省",
    parentId: "86",
    updateTime: null,
    addressList: [
      {
        id: 520100,
        addressName: "贵阳市",
        parentId: "520000",
        updateTime: null,
        addressList: [
          {
            id: 520101,
            addressName: "市辖区",
            parentId: "520100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520102,
            addressName: "南明区",
            parentId: "520100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520103,
            addressName: "云岩区",
            parentId: "520100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520111,
            addressName: "花溪区",
            parentId: "520100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520112,
            addressName: "乌当区",
            parentId: "520100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520113,
            addressName: "白云区",
            parentId: "520100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520115,
            addressName: "观山湖区",
            parentId: "520100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520121,
            addressName: "开阳县",
            parentId: "520100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520122,
            addressName: "息烽县",
            parentId: "520100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520123,
            addressName: "修文县",
            parentId: "520100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520181,
            addressName: "清镇市",
            parentId: "520100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 520200,
        addressName: "六盘水市",
        parentId: "520000",
        updateTime: null,
        addressList: [
          {
            id: 520201,
            addressName: "钟山区",
            parentId: "520200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520203,
            addressName: "六枝特区",
            parentId: "520200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520221,
            addressName: "水城县",
            parentId: "520200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520281,
            addressName: "盘州市",
            parentId: "520200",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 520300,
        addressName: "遵义市",
        parentId: "520000",
        updateTime: null,
        addressList: [
          {
            id: 520301,
            addressName: "市辖区",
            parentId: "520300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520302,
            addressName: "红花岗区",
            parentId: "520300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520303,
            addressName: "汇川区",
            parentId: "520300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520304,
            addressName: "播州区",
            parentId: "520300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520322,
            addressName: "桐梓县",
            parentId: "520300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520323,
            addressName: "绥阳县",
            parentId: "520300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520324,
            addressName: "正安县",
            parentId: "520300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520325,
            addressName: "道真仡佬族苗族自治县",
            parentId: "520300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520326,
            addressName: "务川仡佬族苗族自治县",
            parentId: "520300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520327,
            addressName: "凤冈县",
            parentId: "520300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520328,
            addressName: "湄潭县",
            parentId: "520300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520329,
            addressName: "余庆县",
            parentId: "520300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520330,
            addressName: "习水县",
            parentId: "520300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520381,
            addressName: "赤水市",
            parentId: "520300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520382,
            addressName: "仁怀市",
            parentId: "520300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 520400,
        addressName: "安顺市",
        parentId: "520000",
        updateTime: null,
        addressList: [
          {
            id: 520401,
            addressName: "市辖区",
            parentId: "520400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520402,
            addressName: "西秀区",
            parentId: "520400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520403,
            addressName: "平坝区",
            parentId: "520400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520422,
            addressName: "普定县",
            parentId: "520400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520423,
            addressName: "镇宁布依族苗族自治县",
            parentId: "520400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520424,
            addressName: "关岭布依族苗族自治县",
            parentId: "520400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520425,
            addressName: "紫云苗族布依族自治县",
            parentId: "520400",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 520500,
        addressName: "毕节市",
        parentId: "520000",
        updateTime: null,
        addressList: [
          {
            id: 520501,
            addressName: "市辖区",
            parentId: "520500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520502,
            addressName: "七星关区",
            parentId: "520500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520521,
            addressName: "大方县",
            parentId: "520500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520522,
            addressName: "黔西县",
            parentId: "520500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520523,
            addressName: "金沙县",
            parentId: "520500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520524,
            addressName: "织金县",
            parentId: "520500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520525,
            addressName: "纳雍县",
            parentId: "520500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520526,
            addressName: "威宁彝族回族苗族自治县",
            parentId: "520500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520527,
            addressName: "赫章县",
            parentId: "520500",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 520600,
        addressName: "铜仁市",
        parentId: "520000",
        updateTime: null,
        addressList: [
          {
            id: 520601,
            addressName: "市辖区",
            parentId: "520600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520602,
            addressName: "碧江区",
            parentId: "520600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520603,
            addressName: "万山区",
            parentId: "520600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520621,
            addressName: "江口县",
            parentId: "520600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520622,
            addressName: "玉屏侗族自治县",
            parentId: "520600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520623,
            addressName: "石阡县",
            parentId: "520600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520624,
            addressName: "思南县",
            parentId: "520600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520625,
            addressName: "印江土家族苗族自治县",
            parentId: "520600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520626,
            addressName: "德江县",
            parentId: "520600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520627,
            addressName: "沿河土家族自治县",
            parentId: "520600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 520628,
            addressName: "松桃苗族自治县",
            parentId: "520600",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 522300,
        addressName: "黔西南布依族苗族自治州",
        parentId: "520000",
        updateTime: null,
        addressList: [
          {
            id: 522301,
            addressName: "兴义市",
            parentId: "522300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 522302,
            addressName: "兴仁市",
            parentId: "522300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 522323,
            addressName: "普安县",
            parentId: "522300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 522324,
            addressName: "晴隆县",
            parentId: "522300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 522325,
            addressName: "贞丰县",
            parentId: "522300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 522326,
            addressName: "望谟县",
            parentId: "522300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 522327,
            addressName: "册亨县",
            parentId: "522300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 522328,
            addressName: "安龙县",
            parentId: "522300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 522600,
        addressName: "黔东南苗族侗族自治州",
        parentId: "520000",
        updateTime: null,
        addressList: [
          {
            id: 522601,
            addressName: "凯里市",
            parentId: "522600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 522622,
            addressName: "黄平县",
            parentId: "522600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 522623,
            addressName: "施秉县",
            parentId: "522600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 522624,
            addressName: "三穗县",
            parentId: "522600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 522625,
            addressName: "镇远县",
            parentId: "522600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 522626,
            addressName: "岑巩县",
            parentId: "522600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 522627,
            addressName: "天柱县",
            parentId: "522600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 522628,
            addressName: "锦屏县",
            parentId: "522600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 522629,
            addressName: "剑河县",
            parentId: "522600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 522630,
            addressName: "台江县",
            parentId: "522600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 522631,
            addressName: "黎平县",
            parentId: "522600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 522632,
            addressName: "榕江县",
            parentId: "522600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 522633,
            addressName: "从江县",
            parentId: "522600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 522634,
            addressName: "雷山县",
            parentId: "522600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 522635,
            addressName: "麻江县",
            parentId: "522600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 522636,
            addressName: "丹寨县",
            parentId: "522600",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 522700,
        addressName: "黔南布依族苗族自治州",
        parentId: "520000",
        updateTime: null,
        addressList: [
          {
            id: 522701,
            addressName: "都匀市",
            parentId: "522700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 522702,
            addressName: "福泉市",
            parentId: "522700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 522722,
            addressName: "荔波县",
            parentId: "522700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 522723,
            addressName: "贵定县",
            parentId: "522700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 522725,
            addressName: "瓮安县",
            parentId: "522700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 522726,
            addressName: "独山县",
            parentId: "522700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 522727,
            addressName: "平塘县",
            parentId: "522700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 522728,
            addressName: "罗甸县",
            parentId: "522700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 522729,
            addressName: "长顺县",
            parentId: "522700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 522730,
            addressName: "龙里县",
            parentId: "522700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 522731,
            addressName: "惠水县",
            parentId: "522700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 522732,
            addressName: "三都水族自治县",
            parentId: "522700",
            updateTime: null,
            addressList: null,
          },
        ],
      },
    ],
  },
  {
    id: 530000,
    addressName: "云南省",
    parentId: "86",
    updateTime: null,
    addressList: [
      {
        id: 530100,
        addressName: "昆明市",
        parentId: "530000",
        updateTime: null,
        addressList: [
          {
            id: 530101,
            addressName: "市辖区",
            parentId: "530100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530102,
            addressName: "五华区",
            parentId: "530100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530103,
            addressName: "盘龙区",
            parentId: "530100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530111,
            addressName: "官渡区",
            parentId: "530100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530112,
            addressName: "西山区",
            parentId: "530100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530113,
            addressName: "东川区",
            parentId: "530100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530114,
            addressName: "呈贡区",
            parentId: "530100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530115,
            addressName: "晋宁区",
            parentId: "530100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530124,
            addressName: "富民县",
            parentId: "530100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530125,
            addressName: "宜良县",
            parentId: "530100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530126,
            addressName: "石林彝族自治县",
            parentId: "530100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530127,
            addressName: "嵩明县",
            parentId: "530100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530128,
            addressName: "禄劝彝族苗族自治县",
            parentId: "530100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530129,
            addressName: "寻甸回族彝族自治县",
            parentId: "530100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530181,
            addressName: "安宁市",
            parentId: "530100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 530300,
        addressName: "曲靖市",
        parentId: "530000",
        updateTime: null,
        addressList: [
          {
            id: 530301,
            addressName: "市辖区",
            parentId: "530300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530302,
            addressName: "麒麟区",
            parentId: "530300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530303,
            addressName: "沾益区",
            parentId: "530300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530304,
            addressName: "马龙区",
            parentId: "530300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530322,
            addressName: "陆良县",
            parentId: "530300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530323,
            addressName: "师宗县",
            parentId: "530300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530324,
            addressName: "罗平县",
            parentId: "530300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530325,
            addressName: "富源县",
            parentId: "530300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530326,
            addressName: "会泽县",
            parentId: "530300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530381,
            addressName: "宣威市",
            parentId: "530300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 530400,
        addressName: "玉溪市",
        parentId: "530000",
        updateTime: null,
        addressList: [
          {
            id: 530401,
            addressName: "市辖区",
            parentId: "530400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530402,
            addressName: "红塔区",
            parentId: "530400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530403,
            addressName: "江川区",
            parentId: "530400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530422,
            addressName: "澄江县",
            parentId: "530400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530423,
            addressName: "通海县",
            parentId: "530400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530424,
            addressName: "华宁县",
            parentId: "530400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530425,
            addressName: "易门县",
            parentId: "530400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530426,
            addressName: "峨山彝族自治县",
            parentId: "530400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530427,
            addressName: "新平彝族傣族自治县",
            parentId: "530400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530428,
            addressName: "元江哈尼族彝族傣族自治县",
            parentId: "530400",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 530500,
        addressName: "保山市",
        parentId: "530000",
        updateTime: null,
        addressList: [
          {
            id: 530501,
            addressName: "市辖区",
            parentId: "530500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530502,
            addressName: "隆阳区",
            parentId: "530500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530521,
            addressName: "施甸县",
            parentId: "530500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530523,
            addressName: "龙陵县",
            parentId: "530500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530524,
            addressName: "昌宁县",
            parentId: "530500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530581,
            addressName: "腾冲市",
            parentId: "530500",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 530600,
        addressName: "昭通市",
        parentId: "530000",
        updateTime: null,
        addressList: [
          {
            id: 530601,
            addressName: "市辖区",
            parentId: "530600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530602,
            addressName: "昭阳区",
            parentId: "530600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530621,
            addressName: "鲁甸县",
            parentId: "530600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530622,
            addressName: "巧家县",
            parentId: "530600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530623,
            addressName: "盐津县",
            parentId: "530600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530624,
            addressName: "大关县",
            parentId: "530600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530625,
            addressName: "永善县",
            parentId: "530600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530626,
            addressName: "绥江县",
            parentId: "530600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530627,
            addressName: "镇雄县",
            parentId: "530600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530628,
            addressName: "彝良县",
            parentId: "530600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530629,
            addressName: "威信县",
            parentId: "530600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530681,
            addressName: "水富市",
            parentId: "530600",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 530700,
        addressName: "丽江市",
        parentId: "530000",
        updateTime: null,
        addressList: [
          {
            id: 530701,
            addressName: "市辖区",
            parentId: "530700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530702,
            addressName: "古城区",
            parentId: "530700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530721,
            addressName: "玉龙纳西族自治县",
            parentId: "530700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530722,
            addressName: "永胜县",
            parentId: "530700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530723,
            addressName: "华坪县",
            parentId: "530700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530724,
            addressName: "宁蒗彝族自治县",
            parentId: "530700",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 530800,
        addressName: "普洱市",
        parentId: "530000",
        updateTime: null,
        addressList: [
          {
            id: 530801,
            addressName: "市辖区",
            parentId: "530800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530802,
            addressName: "思茅区",
            parentId: "530800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530821,
            addressName: "宁洱哈尼族彝族自治县",
            parentId: "530800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530822,
            addressName: "墨江哈尼族自治县",
            parentId: "530800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530823,
            addressName: "景东彝族自治县",
            parentId: "530800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530824,
            addressName: "景谷傣族彝族自治县",
            parentId: "530800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530825,
            addressName: "镇沅彝族哈尼族拉祜族自治县",
            parentId: "530800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530826,
            addressName: "江城哈尼族彝族自治县",
            parentId: "530800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530827,
            addressName: "孟连傣族拉祜族佤族自治县",
            parentId: "530800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530828,
            addressName: "澜沧拉祜族自治县",
            parentId: "530800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530829,
            addressName: "西盟佤族自治县",
            parentId: "530800",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 530900,
        addressName: "临沧市",
        parentId: "530000",
        updateTime: null,
        addressList: [
          {
            id: 530901,
            addressName: "市辖区",
            parentId: "530900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530902,
            addressName: "临翔区",
            parentId: "530900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530921,
            addressName: "凤庆县",
            parentId: "530900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530922,
            addressName: "云县",
            parentId: "530900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530923,
            addressName: "永德县",
            parentId: "530900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530924,
            addressName: "镇康县",
            parentId: "530900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530925,
            addressName: "双江拉祜族佤族布朗族傣族自治县",
            parentId: "530900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530926,
            addressName: "耿马傣族佤族自治县",
            parentId: "530900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 530927,
            addressName: "沧源佤族自治县",
            parentId: "530900",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 532300,
        addressName: "楚雄彝族自治州",
        parentId: "530000",
        updateTime: null,
        addressList: [
          {
            id: 532301,
            addressName: "楚雄市",
            parentId: "532300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 532322,
            addressName: "双柏县",
            parentId: "532300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 532323,
            addressName: "牟定县",
            parentId: "532300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 532324,
            addressName: "南华县",
            parentId: "532300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 532325,
            addressName: "姚安县",
            parentId: "532300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 532326,
            addressName: "大姚县",
            parentId: "532300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 532327,
            addressName: "永仁县",
            parentId: "532300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 532328,
            addressName: "元谋县",
            parentId: "532300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 532329,
            addressName: "武定县",
            parentId: "532300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 532331,
            addressName: "禄丰县",
            parentId: "532300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 532500,
        addressName: "红河哈尼族彝族自治州",
        parentId: "530000",
        updateTime: null,
        addressList: [
          {
            id: 532501,
            addressName: "个旧市",
            parentId: "532500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 532502,
            addressName: "开远市",
            parentId: "532500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 532503,
            addressName: "蒙自市",
            parentId: "532500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 532504,
            addressName: "弥勒市",
            parentId: "532500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 532523,
            addressName: "屏边苗族自治县",
            parentId: "532500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 532524,
            addressName: "建水县",
            parentId: "532500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 532525,
            addressName: "石屏县",
            parentId: "532500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 532527,
            addressName: "泸西县",
            parentId: "532500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 532528,
            addressName: "元阳县",
            parentId: "532500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 532529,
            addressName: "红河县",
            parentId: "532500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 532530,
            addressName: "金平苗族瑶族傣族自治县",
            parentId: "532500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 532531,
            addressName: "绿春县",
            parentId: "532500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 532532,
            addressName: "河口瑶族自治县",
            parentId: "532500",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 532600,
        addressName: "文山壮族苗族自治州",
        parentId: "530000",
        updateTime: null,
        addressList: [
          {
            id: 532601,
            addressName: "文山市",
            parentId: "532600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 532622,
            addressName: "砚山县",
            parentId: "532600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 532623,
            addressName: "西畴县",
            parentId: "532600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 532624,
            addressName: "麻栗坡县",
            parentId: "532600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 532625,
            addressName: "马关县",
            parentId: "532600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 532626,
            addressName: "丘北县",
            parentId: "532600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 532627,
            addressName: "广南县",
            parentId: "532600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 532628,
            addressName: "富宁县",
            parentId: "532600",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 532800,
        addressName: "西双版纳傣族自治州",
        parentId: "530000",
        updateTime: null,
        addressList: [
          {
            id: 532801,
            addressName: "景洪市",
            parentId: "532800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 532822,
            addressName: "勐海县",
            parentId: "532800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 532823,
            addressName: "勐腊县",
            parentId: "532800",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 532900,
        addressName: "大理白族自治州",
        parentId: "530000",
        updateTime: null,
        addressList: [
          {
            id: 532901,
            addressName: "大理市",
            parentId: "532900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 532922,
            addressName: "漾濞彝族自治县",
            parentId: "532900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 532923,
            addressName: "祥云县",
            parentId: "532900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 532924,
            addressName: "宾川县",
            parentId: "532900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 532925,
            addressName: "弥渡县",
            parentId: "532900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 532926,
            addressName: "南涧彝族自治县",
            parentId: "532900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 532927,
            addressName: "巍山彝族回族自治县",
            parentId: "532900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 532928,
            addressName: "永平县",
            parentId: "532900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 532929,
            addressName: "云龙县",
            parentId: "532900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 532930,
            addressName: "洱源县",
            parentId: "532900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 532931,
            addressName: "剑川县",
            parentId: "532900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 532932,
            addressName: "鹤庆县",
            parentId: "532900",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 533100,
        addressName: "德宏傣族景颇族自治州",
        parentId: "530000",
        updateTime: null,
        addressList: [
          {
            id: 533102,
            addressName: "瑞丽市",
            parentId: "533100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 533103,
            addressName: "芒市",
            parentId: "533100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 533122,
            addressName: "梁河县",
            parentId: "533100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 533123,
            addressName: "盈江县",
            parentId: "533100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 533124,
            addressName: "陇川县",
            parentId: "533100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 533300,
        addressName: "怒江傈僳族自治州",
        parentId: "530000",
        updateTime: null,
        addressList: [
          {
            id: 533301,
            addressName: "泸水市",
            parentId: "533300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 533323,
            addressName: "福贡县",
            parentId: "533300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 533324,
            addressName: "贡山独龙族怒族自治县",
            parentId: "533300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 533325,
            addressName: "兰坪白族普米族自治县",
            parentId: "533300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 533400,
        addressName: "迪庆藏族自治州",
        parentId: "530000",
        updateTime: null,
        addressList: [
          {
            id: 533401,
            addressName: "香格里拉市",
            parentId: "533400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 533422,
            addressName: "德钦县",
            parentId: "533400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 533423,
            addressName: "维西傈僳族自治县",
            parentId: "533400",
            updateTime: null,
            addressList: null,
          },
        ],
      },
    ],
  },
  {
    id: 540000,
    addressName: "西藏自治区",
    parentId: "86",
    updateTime: null,
    addressList: [
      {
        id: 540100,
        addressName: "拉萨市",
        parentId: "540000",
        updateTime: null,
        addressList: [
          {
            id: 540101,
            addressName: "市辖区",
            parentId: "540100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540102,
            addressName: "城关区",
            parentId: "540100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540103,
            addressName: "堆龙德庆区",
            parentId: "540100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540104,
            addressName: "达孜区",
            parentId: "540100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540121,
            addressName: "林周县",
            parentId: "540100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540122,
            addressName: "当雄县",
            parentId: "540100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540123,
            addressName: "尼木县",
            parentId: "540100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540124,
            addressName: "曲水县",
            parentId: "540100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540127,
            addressName: "墨竹工卡县",
            parentId: "540100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540171,
            addressName: "格尔木藏青工业园区",
            parentId: "540100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540172,
            addressName: "拉萨经济技术开发区",
            parentId: "540100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540173,
            addressName: "西藏文化旅游创意园区",
            parentId: "540100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540174,
            addressName: "达孜工业园区",
            parentId: "540100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 540200,
        addressName: "日喀则市",
        parentId: "540000",
        updateTime: null,
        addressList: [
          {
            id: 540202,
            addressName: "桑珠孜区",
            parentId: "540200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540221,
            addressName: "南木林县",
            parentId: "540200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540222,
            addressName: "江孜县",
            parentId: "540200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540223,
            addressName: "定日县",
            parentId: "540200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540224,
            addressName: "萨迦县",
            parentId: "540200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540225,
            addressName: "拉孜县",
            parentId: "540200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540226,
            addressName: "昂仁县",
            parentId: "540200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540227,
            addressName: "谢通门县",
            parentId: "540200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540228,
            addressName: "白朗县",
            parentId: "540200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540229,
            addressName: "仁布县",
            parentId: "540200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540230,
            addressName: "康马县",
            parentId: "540200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540231,
            addressName: "定结县",
            parentId: "540200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540232,
            addressName: "仲巴县",
            parentId: "540200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540233,
            addressName: "亚东县",
            parentId: "540200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540234,
            addressName: "吉隆县",
            parentId: "540200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540235,
            addressName: "聂拉木县",
            parentId: "540200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540236,
            addressName: "萨嘎县",
            parentId: "540200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540237,
            addressName: "岗巴县",
            parentId: "540200",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 540300,
        addressName: "昌都市",
        parentId: "540000",
        updateTime: null,
        addressList: [
          {
            id: 540302,
            addressName: "卡若区",
            parentId: "540300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540321,
            addressName: "江达县",
            parentId: "540300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540322,
            addressName: "贡觉县",
            parentId: "540300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540323,
            addressName: "类乌齐县",
            parentId: "540300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540324,
            addressName: "丁青县",
            parentId: "540300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540325,
            addressName: "察雅县",
            parentId: "540300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540326,
            addressName: "八宿县",
            parentId: "540300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540327,
            addressName: "左贡县",
            parentId: "540300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540328,
            addressName: "芒康县",
            parentId: "540300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540329,
            addressName: "洛隆县",
            parentId: "540300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540330,
            addressName: "边坝县",
            parentId: "540300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 540400,
        addressName: "林芝市",
        parentId: "540000",
        updateTime: null,
        addressList: [
          {
            id: 540402,
            addressName: "巴宜区",
            parentId: "540400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540421,
            addressName: "工布江达县",
            parentId: "540400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540422,
            addressName: "米林县",
            parentId: "540400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540423,
            addressName: "墨脱县",
            parentId: "540400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540424,
            addressName: "波密县",
            parentId: "540400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540425,
            addressName: "察隅县",
            parentId: "540400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540426,
            addressName: "朗县",
            parentId: "540400",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 540500,
        addressName: "山南市",
        parentId: "540000",
        updateTime: null,
        addressList: [
          {
            id: 540501,
            addressName: "市辖区",
            parentId: "540500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540502,
            addressName: "乃东区",
            parentId: "540500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540521,
            addressName: "扎囊县",
            parentId: "540500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540522,
            addressName: "贡嘎县",
            parentId: "540500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540523,
            addressName: "桑日县",
            parentId: "540500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540524,
            addressName: "琼结县",
            parentId: "540500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540525,
            addressName: "曲松县",
            parentId: "540500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540526,
            addressName: "措美县",
            parentId: "540500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540527,
            addressName: "洛扎县",
            parentId: "540500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540528,
            addressName: "加查县",
            parentId: "540500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540529,
            addressName: "隆子县",
            parentId: "540500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540530,
            addressName: "错那县",
            parentId: "540500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540531,
            addressName: "浪卡子县",
            parentId: "540500",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 540600,
        addressName: "那曲市",
        parentId: "540000",
        updateTime: null,
        addressList: [
          {
            id: 540602,
            addressName: "色尼区",
            parentId: "540600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540621,
            addressName: "嘉黎县",
            parentId: "540600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540622,
            addressName: "比如县",
            parentId: "540600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540623,
            addressName: "聂荣县",
            parentId: "540600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540624,
            addressName: "安多县",
            parentId: "540600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540625,
            addressName: "申扎县",
            parentId: "540600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540626,
            addressName: "索县",
            parentId: "540600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540627,
            addressName: "班戈县",
            parentId: "540600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540628,
            addressName: "巴青县",
            parentId: "540600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540629,
            addressName: "尼玛县",
            parentId: "540600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 540630,
            addressName: "双湖县",
            parentId: "540600",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 542500,
        addressName: "阿里地区",
        parentId: "540000",
        updateTime: null,
        addressList: [
          {
            id: 542521,
            addressName: "普兰县",
            parentId: "542500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 542522,
            addressName: "札达县",
            parentId: "542500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 542523,
            addressName: "噶尔县",
            parentId: "542500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 542524,
            addressName: "日土县",
            parentId: "542500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 542525,
            addressName: "革吉县",
            parentId: "542500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 542526,
            addressName: "改则县",
            parentId: "542500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 542527,
            addressName: "措勤县",
            parentId: "542500",
            updateTime: null,
            addressList: null,
          },
        ],
      },
    ],
  },
  {
    id: 610000,
    addressName: "陕西省",
    parentId: "86",
    updateTime: null,
    addressList: [
      {
        id: 610100,
        addressName: "西安市",
        parentId: "610000",
        updateTime: null,
        addressList: [
          {
            id: 610101,
            addressName: "市辖区",
            parentId: "610100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610102,
            addressName: "新城区",
            parentId: "610100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610103,
            addressName: "碑林区",
            parentId: "610100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610104,
            addressName: "莲湖区",
            parentId: "610100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610111,
            addressName: "灞桥区",
            parentId: "610100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610112,
            addressName: "未央区",
            parentId: "610100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610113,
            addressName: "雁塔区",
            parentId: "610100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610114,
            addressName: "阎良区",
            parentId: "610100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610115,
            addressName: "临潼区",
            parentId: "610100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610116,
            addressName: "长安区",
            parentId: "610100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610117,
            addressName: "高陵区",
            parentId: "610100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610118,
            addressName: "鄠邑区",
            parentId: "610100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610122,
            addressName: "蓝田县",
            parentId: "610100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610124,
            addressName: "周至县",
            parentId: "610100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 610200,
        addressName: "铜川市",
        parentId: "610000",
        updateTime: null,
        addressList: [
          {
            id: 610201,
            addressName: "市辖区",
            parentId: "610200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610202,
            addressName: "王益区",
            parentId: "610200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610203,
            addressName: "印台区",
            parentId: "610200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610204,
            addressName: "耀州区",
            parentId: "610200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610222,
            addressName: "宜君县",
            parentId: "610200",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 610300,
        addressName: "宝鸡市",
        parentId: "610000",
        updateTime: null,
        addressList: [
          {
            id: 610301,
            addressName: "市辖区",
            parentId: "610300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610302,
            addressName: "渭滨区",
            parentId: "610300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610303,
            addressName: "金台区",
            parentId: "610300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610304,
            addressName: "陈仓区",
            parentId: "610300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610322,
            addressName: "凤翔县",
            parentId: "610300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610323,
            addressName: "岐山县",
            parentId: "610300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610324,
            addressName: "扶风县",
            parentId: "610300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610326,
            addressName: "眉县",
            parentId: "610300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610327,
            addressName: "陇县",
            parentId: "610300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610328,
            addressName: "千阳县",
            parentId: "610300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610329,
            addressName: "麟游县",
            parentId: "610300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610330,
            addressName: "凤县",
            parentId: "610300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610331,
            addressName: "太白县",
            parentId: "610300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 610400,
        addressName: "咸阳市",
        parentId: "610000",
        updateTime: null,
        addressList: [
          {
            id: 610401,
            addressName: "市辖区",
            parentId: "610400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610402,
            addressName: "秦都区",
            parentId: "610400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610403,
            addressName: "杨陵区",
            parentId: "610400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610404,
            addressName: "渭城区",
            parentId: "610400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610422,
            addressName: "三原县",
            parentId: "610400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610423,
            addressName: "泾阳县",
            parentId: "610400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610424,
            addressName: "乾县",
            parentId: "610400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610425,
            addressName: "礼泉县",
            parentId: "610400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610426,
            addressName: "永寿县",
            parentId: "610400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610428,
            addressName: "长武县",
            parentId: "610400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610429,
            addressName: "旬邑县",
            parentId: "610400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610430,
            addressName: "淳化县",
            parentId: "610400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610431,
            addressName: "武功县",
            parentId: "610400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610481,
            addressName: "兴平市",
            parentId: "610400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610482,
            addressName: "彬州市",
            parentId: "610400",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 610500,
        addressName: "渭南市",
        parentId: "610000",
        updateTime: null,
        addressList: [
          {
            id: 610501,
            addressName: "市辖区",
            parentId: "610500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610502,
            addressName: "临渭区",
            parentId: "610500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610503,
            addressName: "华州区",
            parentId: "610500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610522,
            addressName: "潼关县",
            parentId: "610500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610523,
            addressName: "大荔县",
            parentId: "610500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610524,
            addressName: "合阳县",
            parentId: "610500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610525,
            addressName: "澄城县",
            parentId: "610500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610526,
            addressName: "蒲城县",
            parentId: "610500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610527,
            addressName: "白水县",
            parentId: "610500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610528,
            addressName: "富平县",
            parentId: "610500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610581,
            addressName: "韩城市",
            parentId: "610500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610582,
            addressName: "华阴市",
            parentId: "610500",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 610600,
        addressName: "延安市",
        parentId: "610000",
        updateTime: null,
        addressList: [
          {
            id: 610601,
            addressName: "市辖区",
            parentId: "610600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610602,
            addressName: "宝塔区",
            parentId: "610600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610603,
            addressName: "安塞区",
            parentId: "610600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610621,
            addressName: "延长县",
            parentId: "610600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610622,
            addressName: "延川县",
            parentId: "610600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610625,
            addressName: "志丹县",
            parentId: "610600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610626,
            addressName: "吴起县",
            parentId: "610600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610627,
            addressName: "甘泉县",
            parentId: "610600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610628,
            addressName: "富县",
            parentId: "610600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610629,
            addressName: "洛川县",
            parentId: "610600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610630,
            addressName: "宜川县",
            parentId: "610600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610631,
            addressName: "黄龙县",
            parentId: "610600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610632,
            addressName: "黄陵县",
            parentId: "610600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610681,
            addressName: "子长市",
            parentId: "610600",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 610700,
        addressName: "汉中市",
        parentId: "610000",
        updateTime: null,
        addressList: [
          {
            id: 610701,
            addressName: "市辖区",
            parentId: "610700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610702,
            addressName: "汉台区",
            parentId: "610700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610703,
            addressName: "南郑区",
            parentId: "610700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610722,
            addressName: "城固县",
            parentId: "610700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610723,
            addressName: "洋县",
            parentId: "610700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610724,
            addressName: "西乡县",
            parentId: "610700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610725,
            addressName: "勉县",
            parentId: "610700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610726,
            addressName: "宁强县",
            parentId: "610700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610727,
            addressName: "略阳县",
            parentId: "610700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610728,
            addressName: "镇巴县",
            parentId: "610700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610729,
            addressName: "留坝县",
            parentId: "610700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610730,
            addressName: "佛坪县",
            parentId: "610700",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 610800,
        addressName: "榆林市",
        parentId: "610000",
        updateTime: null,
        addressList: [
          {
            id: 610801,
            addressName: "市辖区",
            parentId: "610800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610802,
            addressName: "榆阳区",
            parentId: "610800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610803,
            addressName: "横山区",
            parentId: "610800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610822,
            addressName: "府谷县",
            parentId: "610800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610824,
            addressName: "靖边县",
            parentId: "610800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610825,
            addressName: "定边县",
            parentId: "610800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610826,
            addressName: "绥德县",
            parentId: "610800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610827,
            addressName: "米脂县",
            parentId: "610800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610828,
            addressName: "佳县",
            parentId: "610800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610829,
            addressName: "吴堡县",
            parentId: "610800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610830,
            addressName: "清涧县",
            parentId: "610800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610831,
            addressName: "子洲县",
            parentId: "610800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610881,
            addressName: "神木市",
            parentId: "610800",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 610900,
        addressName: "安康市",
        parentId: "610000",
        updateTime: null,
        addressList: [
          {
            id: 610901,
            addressName: "市辖区",
            parentId: "610900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610902,
            addressName: "汉滨区",
            parentId: "610900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610921,
            addressName: "汉阴县",
            parentId: "610900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610922,
            addressName: "石泉县",
            parentId: "610900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610923,
            addressName: "宁陕县",
            parentId: "610900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610924,
            addressName: "紫阳县",
            parentId: "610900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610925,
            addressName: "岚皋县",
            parentId: "610900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610926,
            addressName: "平利县",
            parentId: "610900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610927,
            addressName: "镇坪县",
            parentId: "610900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610928,
            addressName: "旬阳县",
            parentId: "610900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 610929,
            addressName: "白河县",
            parentId: "610900",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 611000,
        addressName: "商洛市",
        parentId: "610000",
        updateTime: null,
        addressList: [
          {
            id: 611001,
            addressName: "市辖区",
            parentId: "611000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 611002,
            addressName: "商州区",
            parentId: "611000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 611021,
            addressName: "洛南县",
            parentId: "611000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 611022,
            addressName: "丹凤县",
            parentId: "611000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 611023,
            addressName: "商南县",
            parentId: "611000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 611024,
            addressName: "山阳县",
            parentId: "611000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 611025,
            addressName: "镇安县",
            parentId: "611000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 611026,
            addressName: "柞水县",
            parentId: "611000",
            updateTime: null,
            addressList: null,
          },
        ],
      },
    ],
  },
  {
    id: 620000,
    addressName: "甘肃省",
    parentId: "86",
    updateTime: null,
    addressList: [
      {
        id: 620100,
        addressName: "兰州市",
        parentId: "620000",
        updateTime: null,
        addressList: [
          {
            id: 620101,
            addressName: "市辖区",
            parentId: "620100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620102,
            addressName: "城关区",
            parentId: "620100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620103,
            addressName: "七里河区",
            parentId: "620100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620104,
            addressName: "西固区",
            parentId: "620100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620105,
            addressName: "安宁区",
            parentId: "620100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620111,
            addressName: "红古区",
            parentId: "620100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620121,
            addressName: "永登县",
            parentId: "620100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620122,
            addressName: "皋兰县",
            parentId: "620100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620123,
            addressName: "榆中县",
            parentId: "620100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620171,
            addressName: "兰州新区",
            parentId: "620100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 620200,
        addressName: "嘉峪关市",
        parentId: "620000",
        updateTime: null,
        addressList: [
          {
            id: 620201,
            addressName: "市辖区",
            parentId: "620200",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 620300,
        addressName: "金昌市",
        parentId: "620000",
        updateTime: null,
        addressList: [
          {
            id: 620301,
            addressName: "市辖区",
            parentId: "620300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620302,
            addressName: "金川区",
            parentId: "620300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620321,
            addressName: "永昌县",
            parentId: "620300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 620400,
        addressName: "白银市",
        parentId: "620000",
        updateTime: null,
        addressList: [
          {
            id: 620401,
            addressName: "市辖区",
            parentId: "620400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620402,
            addressName: "白银区",
            parentId: "620400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620403,
            addressName: "平川区",
            parentId: "620400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620421,
            addressName: "靖远县",
            parentId: "620400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620422,
            addressName: "会宁县",
            parentId: "620400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620423,
            addressName: "景泰县",
            parentId: "620400",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 620500,
        addressName: "天水市",
        parentId: "620000",
        updateTime: null,
        addressList: [
          {
            id: 620501,
            addressName: "市辖区",
            parentId: "620500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620502,
            addressName: "秦州区",
            parentId: "620500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620503,
            addressName: "麦积区",
            parentId: "620500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620521,
            addressName: "清水县",
            parentId: "620500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620522,
            addressName: "秦安县",
            parentId: "620500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620523,
            addressName: "甘谷县",
            parentId: "620500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620524,
            addressName: "武山县",
            parentId: "620500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620525,
            addressName: "张家川回族自治县",
            parentId: "620500",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 620600,
        addressName: "武威市",
        parentId: "620000",
        updateTime: null,
        addressList: [
          {
            id: 620601,
            addressName: "市辖区",
            parentId: "620600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620602,
            addressName: "凉州区",
            parentId: "620600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620621,
            addressName: "民勤县",
            parentId: "620600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620622,
            addressName: "古浪县",
            parentId: "620600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620623,
            addressName: "天祝藏族自治县",
            parentId: "620600",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 620700,
        addressName: "张掖市",
        parentId: "620000",
        updateTime: null,
        addressList: [
          {
            id: 620701,
            addressName: "市辖区",
            parentId: "620700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620702,
            addressName: "甘州区",
            parentId: "620700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620721,
            addressName: "肃南裕固族自治县",
            parentId: "620700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620722,
            addressName: "民乐县",
            parentId: "620700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620723,
            addressName: "临泽县",
            parentId: "620700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620724,
            addressName: "高台县",
            parentId: "620700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620725,
            addressName: "山丹县",
            parentId: "620700",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 620800,
        addressName: "平凉市",
        parentId: "620000",
        updateTime: null,
        addressList: [
          {
            id: 620801,
            addressName: "市辖区",
            parentId: "620800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620802,
            addressName: "崆峒区",
            parentId: "620800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620821,
            addressName: "泾川县",
            parentId: "620800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620822,
            addressName: "灵台县",
            parentId: "620800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620823,
            addressName: "崇信县",
            parentId: "620800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620825,
            addressName: "庄浪县",
            parentId: "620800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620826,
            addressName: "静宁县",
            parentId: "620800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620881,
            addressName: "华亭市",
            parentId: "620800",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 620900,
        addressName: "酒泉市",
        parentId: "620000",
        updateTime: null,
        addressList: [
          {
            id: 620901,
            addressName: "市辖区",
            parentId: "620900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620902,
            addressName: "肃州区",
            parentId: "620900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620921,
            addressName: "金塔县",
            parentId: "620900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620922,
            addressName: "瓜州县",
            parentId: "620900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620923,
            addressName: "肃北蒙古族自治县",
            parentId: "620900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620924,
            addressName: "阿克塞哈萨克族自治县",
            parentId: "620900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620981,
            addressName: "玉门市",
            parentId: "620900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 620982,
            addressName: "敦煌市",
            parentId: "620900",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 621000,
        addressName: "庆阳市",
        parentId: "620000",
        updateTime: null,
        addressList: [
          {
            id: 621001,
            addressName: "市辖区",
            parentId: "621000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 621002,
            addressName: "西峰区",
            parentId: "621000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 621021,
            addressName: "庆城县",
            parentId: "621000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 621022,
            addressName: "环县",
            parentId: "621000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 621023,
            addressName: "华池县",
            parentId: "621000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 621024,
            addressName: "合水县",
            parentId: "621000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 621025,
            addressName: "正宁县",
            parentId: "621000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 621026,
            addressName: "宁县",
            parentId: "621000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 621027,
            addressName: "镇原县",
            parentId: "621000",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 621100,
        addressName: "定西市",
        parentId: "620000",
        updateTime: null,
        addressList: [
          {
            id: 621101,
            addressName: "市辖区",
            parentId: "621100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 621102,
            addressName: "安定区",
            parentId: "621100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 621121,
            addressName: "通渭县",
            parentId: "621100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 621122,
            addressName: "陇西县",
            parentId: "621100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 621123,
            addressName: "渭源县",
            parentId: "621100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 621124,
            addressName: "临洮县",
            parentId: "621100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 621125,
            addressName: "漳县",
            parentId: "621100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 621126,
            addressName: "岷县",
            parentId: "621100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 621200,
        addressName: "陇南市",
        parentId: "620000",
        updateTime: null,
        addressList: [
          {
            id: 621201,
            addressName: "市辖区",
            parentId: "621200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 621202,
            addressName: "武都区",
            parentId: "621200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 621221,
            addressName: "成县",
            parentId: "621200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 621222,
            addressName: "文县",
            parentId: "621200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 621223,
            addressName: "宕昌县",
            parentId: "621200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 621224,
            addressName: "康县",
            parentId: "621200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 621225,
            addressName: "西和县",
            parentId: "621200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 621226,
            addressName: "礼县",
            parentId: "621200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 621227,
            addressName: "徽县",
            parentId: "621200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 621228,
            addressName: "两当县",
            parentId: "621200",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 622900,
        addressName: "临夏回族自治州",
        parentId: "620000",
        updateTime: null,
        addressList: [
          {
            id: 622901,
            addressName: "临夏市",
            parentId: "622900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 622921,
            addressName: "临夏县",
            parentId: "622900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 622922,
            addressName: "康乐县",
            parentId: "622900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 622923,
            addressName: "永靖县",
            parentId: "622900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 622924,
            addressName: "广河县",
            parentId: "622900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 622925,
            addressName: "和政县",
            parentId: "622900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 622926,
            addressName: "东乡族自治县",
            parentId: "622900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 622927,
            addressName: "积石山保安族东乡族撒拉族自治县",
            parentId: "622900",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 623000,
        addressName: "甘南藏族自治州",
        parentId: "620000",
        updateTime: null,
        addressList: [
          {
            id: 623001,
            addressName: "合作市",
            parentId: "623000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 623021,
            addressName: "临潭县",
            parentId: "623000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 623022,
            addressName: "卓尼县",
            parentId: "623000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 623023,
            addressName: "舟曲县",
            parentId: "623000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 623024,
            addressName: "迭部县",
            parentId: "623000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 623025,
            addressName: "玛曲县",
            parentId: "623000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 623026,
            addressName: "碌曲县",
            parentId: "623000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 623027,
            addressName: "夏河县",
            parentId: "623000",
            updateTime: null,
            addressList: null,
          },
        ],
      },
    ],
  },
  {
    id: 630000,
    addressName: "青海省",
    parentId: "86",
    updateTime: null,
    addressList: [
      {
        id: 630100,
        addressName: "西宁市",
        parentId: "630000",
        updateTime: null,
        addressList: [
          {
            id: 630101,
            addressName: "市辖区",
            parentId: "630100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 630102,
            addressName: "城东区",
            parentId: "630100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 630103,
            addressName: "城中区",
            parentId: "630100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 630104,
            addressName: "城西区",
            parentId: "630100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 630105,
            addressName: "城北区",
            parentId: "630100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 630121,
            addressName: "大通回族土族自治县",
            parentId: "630100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 630122,
            addressName: "湟中县",
            parentId: "630100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 630123,
            addressName: "湟源县",
            parentId: "630100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 630200,
        addressName: "海东市",
        parentId: "630000",
        updateTime: null,
        addressList: [
          {
            id: 630202,
            addressName: "乐都区",
            parentId: "630200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 630203,
            addressName: "平安区",
            parentId: "630200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 630222,
            addressName: "民和回族土族自治县",
            parentId: "630200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 630223,
            addressName: "互助土族自治县",
            parentId: "630200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 630224,
            addressName: "化隆回族自治县",
            parentId: "630200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 630225,
            addressName: "循化撒拉族自治县",
            parentId: "630200",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 632200,
        addressName: "海北藏族自治州",
        parentId: "630000",
        updateTime: null,
        addressList: [
          {
            id: 632221,
            addressName: "门源回族自治县",
            parentId: "632200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 632222,
            addressName: "祁连县",
            parentId: "632200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 632223,
            addressName: "海晏县",
            parentId: "632200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 632224,
            addressName: "刚察县",
            parentId: "632200",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 632300,
        addressName: "黄南藏族自治州",
        parentId: "630000",
        updateTime: null,
        addressList: [
          {
            id: 632321,
            addressName: "同仁县",
            parentId: "632300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 632322,
            addressName: "尖扎县",
            parentId: "632300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 632323,
            addressName: "泽库县",
            parentId: "632300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 632324,
            addressName: "河南蒙古族自治县",
            parentId: "632300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 632500,
        addressName: "海南藏族自治州",
        parentId: "630000",
        updateTime: null,
        addressList: [
          {
            id: 632521,
            addressName: "共和县",
            parentId: "632500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 632522,
            addressName: "同德县",
            parentId: "632500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 632523,
            addressName: "贵德县",
            parentId: "632500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 632524,
            addressName: "兴海县",
            parentId: "632500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 632525,
            addressName: "贵南县",
            parentId: "632500",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 632600,
        addressName: "果洛藏族自治州",
        parentId: "630000",
        updateTime: null,
        addressList: [
          {
            id: 632621,
            addressName: "玛沁县",
            parentId: "632600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 632622,
            addressName: "班玛县",
            parentId: "632600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 632623,
            addressName: "甘德县",
            parentId: "632600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 632624,
            addressName: "达日县",
            parentId: "632600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 632625,
            addressName: "久治县",
            parentId: "632600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 632626,
            addressName: "玛多县",
            parentId: "632600",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 632700,
        addressName: "玉树藏族自治州",
        parentId: "630000",
        updateTime: null,
        addressList: [
          {
            id: 632701,
            addressName: "玉树市",
            parentId: "632700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 632722,
            addressName: "杂多县",
            parentId: "632700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 632723,
            addressName: "称多县",
            parentId: "632700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 632724,
            addressName: "治多县",
            parentId: "632700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 632725,
            addressName: "囊谦县",
            parentId: "632700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 632726,
            addressName: "曲麻莱县",
            parentId: "632700",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 632800,
        addressName: "海西蒙古族藏族自治州",
        parentId: "630000",
        updateTime: null,
        addressList: [
          {
            id: 632801,
            addressName: "格尔木市",
            parentId: "632800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 632802,
            addressName: "德令哈市",
            parentId: "632800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 632803,
            addressName: "茫崖市",
            parentId: "632800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 632821,
            addressName: "乌兰县",
            parentId: "632800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 632822,
            addressName: "都兰县",
            parentId: "632800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 632823,
            addressName: "天峻县",
            parentId: "632800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 632857,
            addressName: "大柴旦行政委员会",
            parentId: "632800",
            updateTime: null,
            addressList: null,
          },
        ],
      },
    ],
  },
  {
    id: 640000,
    addressName: "宁夏回族自治区",
    parentId: "86",
    updateTime: null,
    addressList: [
      {
        id: 640100,
        addressName: "银川市",
        parentId: "640000",
        updateTime: null,
        addressList: [
          {
            id: 640101,
            addressName: "市辖区",
            parentId: "640100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 640104,
            addressName: "兴庆区",
            parentId: "640100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 640105,
            addressName: "西夏区",
            parentId: "640100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 640106,
            addressName: "金凤区",
            parentId: "640100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 640121,
            addressName: "永宁县",
            parentId: "640100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 640122,
            addressName: "贺兰县",
            parentId: "640100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 640181,
            addressName: "灵武市",
            parentId: "640100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 640200,
        addressName: "石嘴山市",
        parentId: "640000",
        updateTime: null,
        addressList: [
          {
            id: 640201,
            addressName: "市辖区",
            parentId: "640200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 640202,
            addressName: "大武口区",
            parentId: "640200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 640205,
            addressName: "惠农区",
            parentId: "640200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 640221,
            addressName: "平罗县",
            parentId: "640200",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 640300,
        addressName: "吴忠市",
        parentId: "640000",
        updateTime: null,
        addressList: [
          {
            id: 640301,
            addressName: "市辖区",
            parentId: "640300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 640302,
            addressName: "利通区",
            parentId: "640300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 640303,
            addressName: "红寺堡区",
            parentId: "640300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 640323,
            addressName: "盐池县",
            parentId: "640300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 640324,
            addressName: "同心县",
            parentId: "640300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 640381,
            addressName: "青铜峡市",
            parentId: "640300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 640400,
        addressName: "固原市",
        parentId: "640000",
        updateTime: null,
        addressList: [
          {
            id: 640401,
            addressName: "市辖区",
            parentId: "640400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 640402,
            addressName: "原州区",
            parentId: "640400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 640422,
            addressName: "西吉县",
            parentId: "640400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 640423,
            addressName: "隆德县",
            parentId: "640400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 640424,
            addressName: "泾源县",
            parentId: "640400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 640425,
            addressName: "彭阳县",
            parentId: "640400",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 640500,
        addressName: "中卫市",
        parentId: "640000",
        updateTime: null,
        addressList: [
          {
            id: 640501,
            addressName: "市辖区",
            parentId: "640500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 640502,
            addressName: "沙坡头区",
            parentId: "640500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 640521,
            addressName: "中宁县",
            parentId: "640500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 640522,
            addressName: "海原县",
            parentId: "640500",
            updateTime: null,
            addressList: null,
          },
        ],
      },
    ],
  },
  {
    id: 650000,
    addressName: "新疆维吾尔自治区",
    parentId: "86",
    updateTime: null,
    addressList: [
      {
        id: 650100,
        addressName: "乌鲁木齐市",
        parentId: "650000",
        updateTime: null,
        addressList: [
          {
            id: 650101,
            addressName: "市辖区",
            parentId: "650100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 650102,
            addressName: "天山区",
            parentId: "650100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 650103,
            addressName: "沙依巴克区",
            parentId: "650100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 650104,
            addressName: "新市区",
            parentId: "650100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 650105,
            addressName: "水磨沟区",
            parentId: "650100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 650106,
            addressName: "头屯河区",
            parentId: "650100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 650107,
            addressName: "达坂城区",
            parentId: "650100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 650109,
            addressName: "米东区",
            parentId: "650100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 650121,
            addressName: "乌鲁木齐县",
            parentId: "650100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 650200,
        addressName: "克拉玛依市",
        parentId: "650000",
        updateTime: null,
        addressList: [
          {
            id: 650201,
            addressName: "市辖区",
            parentId: "650200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 650202,
            addressName: "独山子区",
            parentId: "650200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 650203,
            addressName: "克拉玛依区",
            parentId: "650200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 650204,
            addressName: "白碱滩区",
            parentId: "650200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 650205,
            addressName: "乌尔禾区",
            parentId: "650200",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 650400,
        addressName: "吐鲁番市",
        parentId: "650000",
        updateTime: null,
        addressList: [
          {
            id: 650402,
            addressName: "高昌区",
            parentId: "650400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 650421,
            addressName: "鄯善县",
            parentId: "650400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 650422,
            addressName: "托克逊县",
            parentId: "650400",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 650500,
        addressName: "哈密市",
        parentId: "650000",
        updateTime: null,
        addressList: [
          {
            id: 650502,
            addressName: "伊州区",
            parentId: "650500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 650521,
            addressName: "巴里坤哈萨克自治县",
            parentId: "650500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 650522,
            addressName: "伊吾县",
            parentId: "650500",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 652300,
        addressName: "昌吉回族自治州",
        parentId: "650000",
        updateTime: null,
        addressList: [
          {
            id: 652301,
            addressName: "昌吉市",
            parentId: "652300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 652302,
            addressName: "阜康市",
            parentId: "652300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 652323,
            addressName: "呼图壁县",
            parentId: "652300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 652324,
            addressName: "玛纳斯县",
            parentId: "652300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 652325,
            addressName: "奇台县",
            parentId: "652300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 652327,
            addressName: "吉木萨尔县",
            parentId: "652300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 652328,
            addressName: "木垒哈萨克自治县",
            parentId: "652300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 652700,
        addressName: "博尔塔拉蒙古自治州",
        parentId: "650000",
        updateTime: null,
        addressList: [
          {
            id: 652701,
            addressName: "博乐市",
            parentId: "652700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 652702,
            addressName: "阿拉山口市",
            parentId: "652700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 652722,
            addressName: "精河县",
            parentId: "652700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 652723,
            addressName: "温泉县",
            parentId: "652700",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 652800,
        addressName: "巴音郭楞蒙古自治州",
        parentId: "650000",
        updateTime: null,
        addressList: [
          {
            id: 652801,
            addressName: "库尔勒市",
            parentId: "652800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 652822,
            addressName: "轮台县",
            parentId: "652800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 652823,
            addressName: "尉犁县",
            parentId: "652800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 652824,
            addressName: "若羌县",
            parentId: "652800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 652825,
            addressName: "且末县",
            parentId: "652800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 652826,
            addressName: "焉耆回族自治县",
            parentId: "652800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 652827,
            addressName: "和静县",
            parentId: "652800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 652828,
            addressName: "和硕县",
            parentId: "652800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 652829,
            addressName: "博湖县",
            parentId: "652800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 652871,
            addressName: "库尔勒经济技术开发区",
            parentId: "652800",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 652900,
        addressName: "阿克苏地区",
        parentId: "650000",
        updateTime: null,
        addressList: [
          {
            id: 652901,
            addressName: "阿克苏市",
            parentId: "652900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 652922,
            addressName: "温宿县",
            parentId: "652900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 652923,
            addressName: "库车县",
            parentId: "652900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 652924,
            addressName: "沙雅县",
            parentId: "652900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 652925,
            addressName: "新和县",
            parentId: "652900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 652926,
            addressName: "拜城县",
            parentId: "652900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 652927,
            addressName: "乌什县",
            parentId: "652900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 652928,
            addressName: "阿瓦提县",
            parentId: "652900",
            updateTime: null,
            addressList: null,
          },
          {
            id: 652929,
            addressName: "柯坪县",
            parentId: "652900",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 653000,
        addressName: "克孜勒苏柯尔克孜自治州",
        parentId: "650000",
        updateTime: null,
        addressList: [
          {
            id: 653001,
            addressName: "阿图什市",
            parentId: "653000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 653022,
            addressName: "阿克陶县",
            parentId: "653000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 653023,
            addressName: "阿合奇县",
            parentId: "653000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 653024,
            addressName: "乌恰县",
            parentId: "653000",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 653100,
        addressName: "喀什地区",
        parentId: "650000",
        updateTime: null,
        addressList: [
          {
            id: 653101,
            addressName: "喀什市",
            parentId: "653100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 653121,
            addressName: "疏附县",
            parentId: "653100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 653122,
            addressName: "疏勒县",
            parentId: "653100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 653123,
            addressName: "英吉沙县",
            parentId: "653100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 653124,
            addressName: "泽普县",
            parentId: "653100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 653125,
            addressName: "莎车县",
            parentId: "653100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 653126,
            addressName: "叶城县",
            parentId: "653100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 653127,
            addressName: "麦盖提县",
            parentId: "653100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 653128,
            addressName: "岳普湖县",
            parentId: "653100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 653129,
            addressName: "伽师县",
            parentId: "653100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 653130,
            addressName: "巴楚县",
            parentId: "653100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 653131,
            addressName: "塔什库尔干塔吉克自治县",
            parentId: "653100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 653200,
        addressName: "和田地区",
        parentId: "650000",
        updateTime: null,
        addressList: [
          {
            id: 653201,
            addressName: "和田市",
            parentId: "653200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 653221,
            addressName: "和田县",
            parentId: "653200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 653222,
            addressName: "墨玉县",
            parentId: "653200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 653223,
            addressName: "皮山县",
            parentId: "653200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 653224,
            addressName: "洛浦县",
            parentId: "653200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 653225,
            addressName: "策勒县",
            parentId: "653200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 653226,
            addressName: "于田县",
            parentId: "653200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 653227,
            addressName: "民丰县",
            parentId: "653200",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 654000,
        addressName: "伊犁哈萨克自治州",
        parentId: "650000",
        updateTime: null,
        addressList: [
          {
            id: 654002,
            addressName: "伊宁市",
            parentId: "654000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 654003,
            addressName: "奎屯市",
            parentId: "654000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 654004,
            addressName: "霍尔果斯市",
            parentId: "654000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 654021,
            addressName: "伊宁县",
            parentId: "654000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 654022,
            addressName: "察布查尔锡伯自治县",
            parentId: "654000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 654023,
            addressName: "霍城县",
            parentId: "654000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 654024,
            addressName: "巩留县",
            parentId: "654000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 654025,
            addressName: "新源县",
            parentId: "654000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 654026,
            addressName: "昭苏县",
            parentId: "654000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 654027,
            addressName: "特克斯县",
            parentId: "654000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 654028,
            addressName: "尼勒克县",
            parentId: "654000",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 654200,
        addressName: "塔城地区",
        parentId: "650000",
        updateTime: null,
        addressList: [
          {
            id: 654201,
            addressName: "塔城市",
            parentId: "654200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 654202,
            addressName: "乌苏市",
            parentId: "654200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 654221,
            addressName: "额敏县",
            parentId: "654200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 654223,
            addressName: "沙湾县",
            parentId: "654200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 654224,
            addressName: "托里县",
            parentId: "654200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 654225,
            addressName: "裕民县",
            parentId: "654200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 654226,
            addressName: "和布克赛尔蒙古自治县",
            parentId: "654200",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 654300,
        addressName: "阿勒泰地区",
        parentId: "650000",
        updateTime: null,
        addressList: [
          {
            id: 654301,
            addressName: "阿勒泰市",
            parentId: "654300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 654321,
            addressName: "布尔津县",
            parentId: "654300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 654322,
            addressName: "富蕴县",
            parentId: "654300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 654323,
            addressName: "福海县",
            parentId: "654300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 654324,
            addressName: "哈巴河县",
            parentId: "654300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 654325,
            addressName: "青河县",
            parentId: "654300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 654326,
            addressName: "吉木乃县",
            parentId: "654300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 659000,
        addressName: "自治区直辖县级行政区划",
        parentId: "650000",
        updateTime: null,
        addressList: [
          {
            id: 659001,
            addressName: "石河子市",
            parentId: "659000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 659002,
            addressName: "阿拉尔市",
            parentId: "659000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 659003,
            addressName: "图木舒克市",
            parentId: "659000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 659004,
            addressName: "五家渠市",
            parentId: "659000",
            updateTime: null,
            addressList: null,
          },
          {
            id: 659006,
            addressName: "铁门关市",
            parentId: "659000",
            updateTime: null,
            addressList: null,
          },
        ],
      },
    ],
  },
  {
    id: 710000,
    addressName: "台湾省",
    parentId: "86",
    updateTime: null,
    addressList: [
      {
        id: 710100,
        addressName: "台北市",
        parentId: "710000",
        updateTime: null,
        addressList: [
          {
            id: 710101,
            addressName: "内湖区",
            parentId: "710100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710102,
            addressName: "南港区",
            parentId: "710100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710103,
            addressName: "中正区",
            parentId: "710100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710104,
            addressName: "松山区",
            parentId: "710100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710105,
            addressName: "信义区",
            parentId: "710100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710106,
            addressName: "大安区",
            parentId: "710100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710107,
            addressName: "中山区",
            parentId: "710100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710108,
            addressName: "文山区",
            parentId: "710100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710109,
            addressName: "大同区",
            parentId: "710100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710110,
            addressName: "万华区",
            parentId: "710100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710111,
            addressName: "士林区",
            parentId: "710100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710112,
            addressName: "北投区",
            parentId: "710100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 710200,
        addressName: "高雄市",
        parentId: "710000",
        updateTime: null,
        addressList: [
          {
            id: 710201,
            addressName: "新兴区",
            parentId: "710200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710202,
            addressName: "前金区",
            parentId: "710200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710203,
            addressName: "芩雅区",
            parentId: "710200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710204,
            addressName: "盐埕区",
            parentId: "710200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710205,
            addressName: "鼓山区",
            parentId: "710200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710206,
            addressName: "旗津区",
            parentId: "710200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710207,
            addressName: "前镇区",
            parentId: "710200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710208,
            addressName: "三民区",
            parentId: "710200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710209,
            addressName: "左营区",
            parentId: "710200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710210,
            addressName: "楠梓区",
            parentId: "710200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710211,
            addressName: "小港区",
            parentId: "710200",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 710300,
        addressName: "基隆市",
        parentId: "710000",
        updateTime: null,
        addressList: [
          {
            id: 710301,
            addressName: "仁爱区",
            parentId: "710300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710302,
            addressName: "信义区",
            parentId: "710300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710303,
            addressName: "中正区",
            parentId: "710300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710304,
            addressName: "暖暖区",
            parentId: "710300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710305,
            addressName: "安乐区",
            parentId: "710300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710307,
            addressName: "七堵区",
            parentId: "710300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 710400,
        addressName: "台中市",
        parentId: "710000",
        updateTime: null,
        addressList: [
          {
            id: 710306,
            addressName: "北屯区",
            parentId: "710400",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710308,
            addressName: "南屯区",
            parentId: "710400",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 710500,
        addressName: "台南市",
        parentId: "710000",
        updateTime: null,
        addressList: [
          {
            id: 710501,
            addressName: "中西区",
            parentId: "710500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710502,
            addressName: "东区",
            parentId: "710500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710503,
            addressName: "南区",
            parentId: "710500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710504,
            addressName: "北区",
            parentId: "710500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710505,
            addressName: "安平区",
            parentId: "710500",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710506,
            addressName: "安南区",
            parentId: "710500",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 710600,
        addressName: "新竹市",
        parentId: "710000",
        updateTime: null,
        addressList: [
          {
            id: 710601,
            addressName: "东区",
            parentId: "710600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710602,
            addressName: "北区",
            parentId: "710600",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710603,
            addressName: "香山区",
            parentId: "710600",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 710700,
        addressName: "嘉义市",
        parentId: "710000",
        updateTime: null,
        addressList: [
          {
            id: 710701,
            addressName: "东区",
            parentId: "710700",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710702,
            addressName: "西区",
            parentId: "710700",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 710800,
        addressName: "新北市",
        parentId: "710000",
        updateTime: null,
        addressList: [
          {
            id: 710801,
            addressName: "板桥区",
            parentId: "710800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710802,
            addressName: "汐止区",
            parentId: "710800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710803,
            addressName: "新店区",
            parentId: "710800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710804,
            addressName: "永和区",
            parentId: "710800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710805,
            addressName: "中和区",
            parentId: "710800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710806,
            addressName: "土城区",
            parentId: "710800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710807,
            addressName: "树林区",
            parentId: "710800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710808,
            addressName: "三重区",
            parentId: "710800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710809,
            addressName: "新庄区",
            parentId: "710800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710810,
            addressName: "芦洲区",
            parentId: "710800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710811,
            addressName: "瑞芳区",
            parentId: "710800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710812,
            addressName: "三峡区",
            parentId: "710800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710813,
            addressName: "莺歌区",
            parentId: "710800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710814,
            addressName: "淡水区",
            parentId: "710800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710815,
            addressName: "万里区",
            parentId: "710800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710816,
            addressName: "金山区",
            parentId: "710800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710817,
            addressName: "深坑区",
            parentId: "710800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710818,
            addressName: "石碇区",
            parentId: "710800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710819,
            addressName: "平溪区",
            parentId: "710800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710820,
            addressName: "双溪区",
            parentId: "710800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710821,
            addressName: "贡寮区",
            parentId: "710800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710822,
            addressName: "坪林区",
            parentId: "710800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710823,
            addressName: "乌来区",
            parentId: "710800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710824,
            addressName: "泰山区",
            parentId: "710800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710825,
            addressName: "林口区",
            parentId: "710800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710826,
            addressName: "五股区",
            parentId: "710800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710827,
            addressName: "八里区",
            parentId: "710800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710828,
            addressName: "三芝区",
            parentId: "710800",
            updateTime: null,
            addressList: null,
          },
          {
            id: 710829,
            addressName: "石门区",
            parentId: "710800",
            updateTime: null,
            addressList: null,
          },
        ],
      },
    ],
  },
  {
    id: 810000,
    addressName: "香港特别行政区",
    parentId: "86",
    updateTime: null,
    addressList: [
      {
        id: 810100,
        addressName: "香港岛",
        parentId: "810000",
        updateTime: null,
        addressList: [
          {
            id: 810001,
            addressName: "中西區",
            parentId: "810100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 810002,
            addressName: "灣仔區",
            parentId: "810100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 810003,
            addressName: "東區",
            parentId: "810100",
            updateTime: null,
            addressList: null,
          },
          {
            id: 810004,
            addressName: "南區",
            parentId: "810100",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 810200,
        addressName: "九龙",
        parentId: "810000",
        updateTime: null,
        addressList: [
          {
            id: 810005,
            addressName: "油尖旺區",
            parentId: "810200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 810006,
            addressName: "深水埗區",
            parentId: "810200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 810007,
            addressName: "九龍城區",
            parentId: "810200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 810008,
            addressName: "黃大仙區",
            parentId: "810200",
            updateTime: null,
            addressList: null,
          },
          {
            id: 810009,
            addressName: "觀塘區",
            parentId: "810200",
            updateTime: null,
            addressList: null,
          },
        ],
      },
      {
        id: 810300,
        addressName: "新界",
        parentId: "810000",
        updateTime: null,
        addressList: [
          {
            id: 810010,
            addressName: "荃灣區",
            parentId: "810300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 810011,
            addressName: "屯門區",
            parentId: "810300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 810012,
            addressName: "元朗區",
            parentId: "810300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 810013,
            addressName: "北區",
            parentId: "810300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 810014,
            addressName: "大埔區",
            parentId: "810300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 810015,
            addressName: "西貢區",
            parentId: "810300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 810016,
            addressName: "沙田區",
            parentId: "810300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 810017,
            addressName: "葵青區",
            parentId: "810300",
            updateTime: null,
            addressList: null,
          },
          {
            id: 810018,
            addressName: "離島區",
            parentId: "810300",
            updateTime: null,
            addressList: null,
          },
        ],
      },
    ],
  },
  {
    id: 820000,
    addressName: "澳门特别行政区",
    parentId: "86",
    updateTime: null,
    addressList: [
      {
        id: 820001,
        addressName: "花地瑪堂區",
        parentId: "820000",
        updateTime: null,
        addressList: null,
      },
      {
        id: 820002,
        addressName: "花王堂區",
        parentId: "820000",
        updateTime: null,
        addressList: null,
      },
      {
        id: 820003,
        addressName: "望德堂區",
        parentId: "820000",
        updateTime: null,
        addressList: null,
      },
      {
        id: 820004,
        addressName: "大堂區",
        parentId: "820000",
        updateTime: null,
        addressList: null,
      },
      {
        id: 820005,
        addressName: "風順堂區",
        parentId: "820000",
        updateTime: null,
        addressList: null,
      },
      {
        id: 820006,
        addressName: "嘉模堂區",
        parentId: "820000",
        updateTime: null,
        addressList: null,
      },
      {
        id: 820007,
        addressName: "路氹填海區",
        parentId: "820000",
        updateTime: null,
        addressList: null,
      },
      {
        id: 820008,
        addressName: "聖方濟各堂區",
        parentId: "820000",
        updateTime: null,
        addressList: null,
      },
    ],
  },
];

export default addressTree;
