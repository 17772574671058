<template>
  <main>
    <!--<common-header />-->
    <div class="preOrder_body">
      <!--    样品清单    -->
      <div class="goods_list_div">
        <div>
          <span>{{ $t("sampleApply.preOrder.sampleList") }}:</span>
        </div>
        <el-table
          class="func-block"
          :data="orderGoodsList"
          stripe
        >
          <el-table-column
            prop="partNo"
            :label="$t('sampleApply.preOrder.partNo')"
            align="center"
          />
          <el-table-column
            prop="category"
            :label="$t('sampleApply.preOrder.category')"
            align="center"
          >
            <template slot-scope="scope">
              <div>{{ $translate(scope.row.category, scope.row.category) }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="amount"
            :label="$t('sampleApply.preOrder.quantity')"
            align="center"
          />
        </el-table>
      </div>
      <div class="project_info_div">
        <div>
          <span>{{ $t("sampleApply.preOrder.projectInfo") }}:</span>
        </div>
        <el-row class="func-block card">
          <el-col :span="24">
            <el-form
              ref="projectRef"
              :model="projectForm"
              :rules="projectRules"
            >
              <el-form-item
                :label="$t('sampleApply.preOrder.directCustomer')"
                :label-width="projectLabelWidth"
                prop="directCustomer"
              >
                <el-input
                  v-model="projectForm.directCustomer"
                  autocomplete="off"
                  :placeholder="$t('sampleApply.preOrder.directCustomerPlaceholder')"
                  maxlength="50"
                />
              </el-form-item>
              <el-row>
                <el-col :span="12">
                  <el-form-item
                    :label="$t('sampleApply.preOrder.mainFactory')"
                    :label-width="projectLabelWidth"
                  >
                    <el-input
                      v-model="projectForm.mainFactory"
                      autocomplete="off"
                      maxlength="50"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    :label="$t('sampleApply.preOrder.projectName')"
                    :label-width="projectLabelWidth"
                  >
                    <el-input
                      v-model="projectForm.projectName"
                      autocomplete="off"
                      maxlength="50"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item
                    :label="$t('sampleApply.preOrder.functionalPart')"
                    :label-width="projectLabelWidth"
                    prop="functionalPart"
                  >
                    <el-input
                      v-model="projectForm.functionalPart"
                      autocomplete="off"
                      maxlength="50"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    :label="$t('sampleApply.preOrder.annualConsumption')"
                    :label-width="projectLabelWidth"
                    prop="annualConsumption"
                  >
                    <el-input
                      v-model="projectForm.annualConsumption"
                      autocomplete="off"
                      maxlength="50"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item
                    :label="$t('sampleApply.preOrder.productionTime')"
                    :label-width="projectLabelWidth"
                    prop="productionTime"
                  >
                    <el-date-picker
                      v-model="projectForm.productionTime"
                      type="date"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      :placeholder="$t('sampleApply.preOrder.productionTimeTips')"
                      align="center"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    :label="$t('sampleApply.preOrder.closingTime')"
                    :label-width="projectLabelWidth"
                  >
                    <el-date-picker
                      v-model="projectForm.closingTime"
                      type="date"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      :placeholder="$t('sampleApply.preOrder.closingTimeTips')"
                      align="center"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item
                :label="$t('sampleApply.preOrder.remarks')"
                :label-width="projectLabelWidth"
              >
                <el-input
                  v-model="projectForm.remarks"
                  autocomplete="off"
                  :placeholder="$t('sampleApply.preOrder.remarkTips')"
                  maxlength="50"
                />
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </div>
      <!--    我的地址    -->
      <div class="address_div">
        <div>
          <span>{{ $t("sampleApply.preOrder.receiveInfo") }}:</span>
        </div>
        <div class="func-block card">
          <el-select
            v-model="tempAddress"
            :placeholder="$t('sampleApply.preOrder.addressPlaceholder')"
          >
            <el-option
              v-for="item in selectAddressList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
              <span style="float: left">{{ item.label }}</span>
            </el-option>
          </el-select>
          <el-button @click="editAddress(tempAddress)">
            {{ $t("sampleApply.preOrder.editAddress") }}
          </el-button>
          <el-button @click="addAddress()">
            {{ $t("sampleApply.preOrder.newAddress") }}
          </el-button>
        </div>
      </div>
      <!--    提交    -->
      <div class="submit_div">
        <el-button @click="submitOrder">
          {{ $t("sampleApply.preOrder.submit") }}
        </el-button>
      </div>
    </div>
    <div class="edit_address_dialog">
      <el-dialog
        :title="$t('sampleApply.accountInfo.receiveAddress')"
        :visible.sync="visitAddress"
        :close-on-click-modal="false"
        :before-close="cancel"
        width="40%"
      >
        <el-form
          ref="newAddress"
          :model="addressForm"
          :rules="rules"
        >
          <el-form-item
            :label="$t('sampleApply.accountInfo.receiver')"
            :label-width="formLabelWidth"
            prop="name"
          >
            <el-input
              v-model="addressForm.name"
              maxlength="50"
              autocomplete="off"
            />
          </el-form-item>
          <el-form-item
            :label="$t('sampleApply.accountInfo.telephone')"
            :label-width="formLabelWidth"
            prop="phone"
          >
            <el-input
              v-model="addressForm.phone"
              autocomplete="off"
              maxlength="11"
              show-word-limit
              oninput="value=value.replace(/[^\d]/g,'')"
            />
          </el-form-item>
          <el-form-item
            :label="$t('sampleApply.accountInfo.area')"
            :label-width="formLabelWidth"
            prop="area"
          >
            <div class="vselect">
              <v-select
                v-model="addressForm.province"
                :list="addressList"
                show-name="addressName"
                show-value="addressName"
                height="34"
                width="160"
                border
                @onChange="provinceChange"
              />
              <v-select
                v-model="addressForm.city"
                :list="cityList"
                show-name="addressName"
                show-value="addressName"
                height="34"
                width="160"
                border
                @onChange="cityChange"
              />
              <v-select
                v-model="addressForm.country"
                :list="countyList"
                show-name="addressName"
                show-value="addressName"
                height="34"
                width="160"
                border
              />
            </div>
          </el-form-item>
          <el-form-item
            :label="$t('sampleApply.accountInfo.address')"
            :label-width="formLabelWidth"
            prop="detailAddress"
          >
            <el-input
              v-model="addressForm.detailAddress"
              maxlength="150"
              autocomplete="off"
            />
          </el-form-item>
          <el-form-item
            :label="$t('sampleApply.accountInfo.postcode')"
            :label-width="formLabelWidth"
          >
            <el-input
              v-model="addressForm.mailCode"
              maxlength="6"
              autocomplete="off"
            />
          </el-form-item>
          <el-form-item
            :label="$t('sampleApply.accountInfo.email')"
            :label-width="formLabelWidth"
          >
            <el-input
              v-model="addressForm.email"
              maxlength="50"
              autocomplete="off"
            />
          </el-form-item>
          <el-form-item :label-width="formLabelWidth">
            <div class="check">
              <el-checkbox
                v-model="addressForm.defaultFlag"
                :label="$t('sampleApply.accountInfo.asDefaultAddress')"
                :true-label="1"
                :false-label="0"
                name="isDefault"
              />
            </div>
          </el-form-item>
        </el-form>
        <div
          slot="footer"
          class="dialog-footer"
        >
          <el-button @click="cancel()">
            {{ $t("common.cancel") }}
          </el-button>
          <el-button
            type="primary"
            @click="doAddorUpdateAddress()"
          >
            {{ $t("common.confirm") }}
          </el-button>
        </div>
      </el-dialog>
    </div>
    <!--<common-footer />-->
  </main>
</template>

<script>
import CommonHeader from "@/components/CommonHeader";
import CommonFooter from "@/components/CommonFooter";
import addressTree from "@/utils/address";

// 验证地址
const checkArea = (rule, value, callback) => {
  return callback();
};
export default {
  name: "ProOrder",
  // components: {CommonHeader, CommonFooter},
  data() {
    return {
      userId: this.$route.query.userId,
      orderGoodsList: JSON.parse(this.$route.query.goodsList),
      // 用户地址对象集合
      userAddressList: [],
      // 地址下拉选择项
      selectAddressList: [],
      // 当前选择的地址ID
      tempAddress: "",
      addressForm: {
        id: "",
        userId: this.$route.query.userId,
        name: "",
        phone: "",
        province: "",
        city: "",
        country: "",
        detailAddress: "",
        mailCode: "",
        defaultFlag: 0,
        email: "",
      },
      projectForm: {
        id: "",
        orderId: "",
        directCustomer: "",
        projectName: "",
        mainFactory: "",
        functionalPart: "",
        annualConsumption: "",
        productionTime: "",
        closingTime: "",
        remarks: ""
      },
      visitAddress: false,
      formLabelWidth: "100px",
      projectLabelWidth: "150px",
      addressList: [], //省份
      cityList: [], //市
      countyList: [], //区
      rules: {
        name: [
          {required: true, message: this.$t("sampleApply.accountInfo.receiverTips"), trigger: "blur"},
        ],
        phone: [
          {required: true, message: this.$t("sampleApply.accountInfo.telephoneTips"), trigger: "blur"},
          {min: 11, max: 11, message: this.$t("sampleApply.accountInfo.validPhoneTips"), trigger: "change"}
        ],
        area: [
          {required: true, validator: checkArea, trigger: "blur"},
        ],
        detailAddress: [
          {required: true, message: this.$t("sampleApply.accountInfo.addressTips"), trigger: "blur"},
        ],
      },
      projectRules: {
        directCustomer: [
          { required: true, message: this.$t("sampleApply.preOrder.directCustomerTips"), trigger: "blur" },
        ],
        functionalPart: [
          { required: true, message: this.$t("sampleApply.preOrder.functionalPartTips"), trigger: "blur" },
        ],
        annualConsumption: [
          { required: true, message: this.$t("sampleApply.preOrder.annualConsumptionTips"), trigger: "blur" },
        ],
        productionTime: [
          { required: true, message: this.$t("sampleApply.preOrder.productionTimeTips1"), trigger: "blur" },
        ],
      },
    }
  },
  created() {
    this.loadUserAddress(this.userId);
    this.getAddress();
  },
  methods: {
    loadUserAddress(userId) {
      // 每次获取前先清空下拉选择列表
      this.selectAddressList = [];
      console.log(userId);
      let url = "/home/user-address/getUserAddressByUserId";
      let param = {
        userId: userId
      };
      this.$doPost(url, param).then(res => {
        if (res.code == 200) {
          console.log("获取用户地址信息成功！");
          this.userAddressList = res.data;
          res.data.forEach(item => {
            let temp = {
              label: item.name + " "
                  + item.phone + " "
                  + item.province + item.city + item.country + item.detailAddress,
              value: item.id,
            }
            if (item.defaultFlag == 1) {
              this.tempAddress = temp.value;
            }
            this.selectAddressList.push(temp);
          });
        } else {
          console.log("获取用户地址信息失败！");
        }
      })
    },
    addAddress() {
      this.provinceChange(0);
      this.cityChange(0);
      this.visitAddress = true;
    },
    provinceChange(index) {
      this.cityList = this.addressList[index].addressList;
    },
    cityChange(index) {
      this.countyList = this.cityList[index].addressList;
    },
    getAddress() {
      this.addressList = addressTree;
      this.provinceChange(0);
      this.cityChange(0);
    },
    cancel() {
      this.visitAddress = false;
      this.clearAddress();
    },
    doAddorUpdateAddress() {
      this.$refs["newAddress"].validate((valid) => {
        if (valid) {
          console.log(this.addressForm);
          if (this.addressForm.id != "") {
            this.updateAddress(this.addressForm);
          } else {
            this.addUserAddress(this.addressForm);
          }
          this.visitAddress = false;
        }
      });
    },
    addUserAddress(item) {
      let url = "/home/user-address/addUserAddress";
      let userAddresses = [];
      userAddresses.push(item);
      this.$doPost(url, userAddresses, true).then(res => {
        if (res.code == 200) {
          console.log("新增地址成功");
          this.loadUserAddress(this.userId);
          this.clearAddress();
        } else {
          console.log("新增地址失败");
        }

      })
    },
    updateAddress(item) {
      let url = "/home/user-address/updateUserAddress";
      let userAddresses = [];
      userAddresses.push(item);
      this.$doPost(url, userAddresses, true).then(res => {
        if (res.code == 200) {
          console.log("更新地址成功");
          this.loadUserAddress(this.userId);
          this.clearAddress();
        } else {
          console.log("更新地址失败");
        }

      })
    },
    clearAddress() {
      this.addressForm = {
        id: "",
        userId: this.$route.query.userId,
        name: "",
        phone: "",
        province: "",
        city: "",
        country: "",
        detailAddress: "",
        mailCode: "",
        defaultFlag: 0,
        email: "",
      };
    },
    editAddress(id) {
      this.userAddressList.forEach(item => {
        if (item.id == id) {
          this.addressForm.id = id;
          this.addressForm.name = item.name;
          this.addressForm.phone = item.phone;
          this.addressForm.province = item.province;
          this.addressForm.city = item.city;
          this.addressForm.country = item.country;
          this.addressForm.mailCode = item.mailCode;
          this.addressForm.email = item.email;
          this.addressForm.detailAddress = item.detailAddress;
          this.addressForm.defaultFlag = item.defaultFlag;
        }
      });
      //判断当前是否又值
      this.addressList.forEach((element) => {
        if (element.addressName == this.addressForm.province) {
          this.cityList = element.addressList;
        }
      });

      this.cityList.forEach((element) => {
        if (element.addressName == this.addressForm.city) {
          this.countyList = element.addressList;
        }
      });
      this.visitAddress = true;
    },
    submitOrder() {
      let url = "/order/sample-order/addSampleOrder";
      let sampleOrderGoodsList = this.orderGoodsList;
      let sampleOrderDTO = {
        userId: this.userId,
        userAddressId: this.tempAddress,
        sampleOrderGoodsList: sampleOrderGoodsList,
        projectInformation: this.projectForm,
        remarks: this.projectForm.remarks,
        cusSrc: 0,
      };
      if (this.$refs["projectRef"].validate((valid) => {
        if (valid) {
          this.$doPost(url, sampleOrderDTO, true).then(res => {
            if (res.code == 200) {
              console.log("样品申请成功！");
              this.$router.push({
                name: 'Personal',
                query: {
                  "selectedTitle": "样品订单",
                }
              })
            } else {
              console.log("样品申请失败！");
            }
          })
        }
      }));
    }
  }
}
</script>

<style scoped lang="less">
.preOrder_body {
  min-height: 500px;

  > div {
    width: 1200px;
    margin: 20px auto;
  }
}

.goods_list_div {
  max-height: 500px;
  overflow-y: auto;

  > div:nth-child(1) {
    display: flex;
    margin-bottom: 20px;
  }
}

.project_info_div {
  > div:nth-child(1) {
    display: flex;
    margin-bottom: 20px;
  }
}

.address_div {
  > div:nth-child(1) {
    display: flex;
    margin-bottom: 20px;
  }

  > div:nth-child(2) {
    display: flex;

    /deep/ .el-button {
      margin-left: 10px;
    }

    /deep/ .el-select {
      width: 50%;
    }
  }
}

// 修改地址弹框
.edit_address_dialog {
  /deep/ .el-dialog__body {
    padding-top: 15px;
    padding-bottom: 0;
  }

  /deep/ .el-dialog__footer {
    text-align: center;
    padding-bottom: 25px;
    padding-top: 0;

    .el-button {
      font-size: 16px;
      margin-right: 20px;
      margin-left: 20px;
      border-radius: 60px;
      padding: 5px 15px;
      /*background-color: #ffffff;*/
      /*color: #67C23A;*/
      border: 1px solid #67C23A;
    }
  }
}

.vselect {
  display: flex;
}

.vselect > div:not(:last-child) {
  margin-right: 15px;
}

.submit_div {
  display: flex;
  justify-content: right;
}

/deep/ .el-date-editor {
  width: 280px;
}
.func-block {
  border-radius: 10px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}
.card {
  padding: 50px 60px 30px 40px;
  background: white;
}
</style>