<template>
  <div>
    <!--<common-header></common-header>-->
    <main style="width: 100%;height: 650px;background-color: white;overflow: hidden">
      <div class="comments-box">
        <div class="box-title">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我要留言</div>
        <el-form ref="leaveMessage" :rules="rules" :model="leaveMessage" class="comment-body">
          <!--标题-->
          <el-form-item label="公司名称：" label-width="110px" prop="companyName">
            <el-input v-model="leaveMessage.companyName" style="width: 95%" maxlength="30" placeholder="不超过30个字"></el-input>
          </el-form-item>
          <!--简介-->
          <el-form-item label="联系方式：" label-width="110px" prop="contactInformation">
            <el-input v-model="leaveMessage.contactInformation" style="width: 95%" maxlength="30" placeholder="不超过30个字"></el-input>
          </el-form-item>
          <!--首页显示-->
          <el-form-item label="联系人：" label-width="110px" prop="contactPerson">
            <el-input v-model="leaveMessage.contactPerson" style="width: 95%" maxlength="30" placeholder="不超过30个字"></el-input>
          </el-form-item>
          <!--主体内容-->
          <el-form-item label="留言内容：" label-width="110px" prop="messageContent">
            <el-input v-model="leaveMessage.messageContent" style="width: 95%" type="textarea" maxlength="200" placeholder="不超过200个字" class="message-content"></el-input>
          </el-form-item>
          <!--提交-->
          <el-form-item class="submitButton">
            <el-button type="primary" @click="addLeaveMessage">确定</el-button>
          </el-form-item>
        </el-form>
      </div>
    </main>

    <!--<common-footer></common-footer>-->

  </div>
</template>

<script>
import CommonHeader from "@/components/CommonHeader";
import CommonFooter from "@/components/CommonFooter";

export default {
  name: "CommentsArea",
  components: {
    // CommonFooter,
    // CommonHeader,
  },
  data(){
    return {
      leaveMessage: {},
      rules: {
        companyName: [
          {required: true, message: "请填写公司名称", trigger: "blur"},
          {min: 0, max: 30, message: "不超过30个字", trigger: "blur"}
        ],
        contactInformation: [
          {required: true, message: "请填写联系方式", trigger: "blur"},
          {min: 0, max: 30, message: "不超过30个字", trigger: "blur"}
        ],
        contactPerson: [
          {required: true, message: "请填写联系人", trigger: "blur"},
          {min: 0, max: 30, message: "不超过30个字", trigger: "blur"}
        ],
        messageContent: [
          {required: true, message: "请填写留言内容", trigger: "blur"},
          {min: 0, max: 200, message: "不超过200个字", trigger: "blur"}
        ],
      }
    }
  },
  methods: {
    addLeaveMessage() {
      console.log(this.leaveMessage);
      let url = "/item/leave-message/addLeaveMessage";
      this.$refs["leaveMessage"].validate((valid) => {
        // 已校验的数据校验通过
        if (valid) {
          this.$doPost(url, this.leaveMessage).then((res) => {
            if (res.code == 200) {
              this.$alert("提交成功，感谢您的留言！");
              this.$router.push("/")
            }
          }).catch((err) => {
            console.log(err);
          });
        } else {
          this.$alert('存在必填项未填写', {
            showClose: false,
            center: true,
            confirmButtonText: '确定',
          });
          return;
        }
      });
    }
  }
}
</script>

<style scoped lang="less">
.comments-box {
  font-family: PingFangSC-Bold;
  background-color: white;
  box-shadow: 0 5px 12px 4px rgba(119,119,119,0.0900);
  height: 80%;
  width: 40%;
  margin: 40px auto;
  .box-title {
    width: 100%;
    height:36px;
    line-height: 36px;
    background-color: #F4F4F4;
    margin: 0 auto;
    font-weight: bold;
    font-size: 14px;
  }
  .comment-body {
    margin-top: 40px;
    
    .submitButton {
      display: flex;
      justify-content: center;
    }
  }
  
  /deep/ .message-content .el-textarea__inner {
    resize: none;
    height: 150px;
  }
  /*> textarea {*/
  /*  font-family: PingFangSC-Bold;*/
  /*  line-height: 20px;*/
  /*  font-weight: bold;*/
  /*  font-size: 14px;*/
  /*  width: 96%;*/
  /*  margin-left: 2%;*/
  /*  height: 70%;*/
  /*  resize: none;*/
  /*  border: white;*/
  /*  outline: none;*/
  /*}*/
  > button {

    margin-left: 85%;
    font-size: 14px;
    font-weight: bold;
    border: 0;
    width: 102px;
    height: 44px;
    background-color: #D8D8D8;
    border-radius: 4px;
    cursor: pointer;
  }
  > button:hover {
    background-color: gray;
    color: white;
  }
}

</style>