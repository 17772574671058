<template>
  <div class="container">
    <div class="company-profile">
      <div class="inner-header">
        {{ $t('companyProfile.companyProfile') }}
      </div>
      <div class="profile-detail">
        {{ $t('companyProfile.profileDetails') }}
      </div>
    </div>
    <div class="layout">
      <div class="inner-header">
        {{ $t('companyProfile.ourLayout') }}
      </div>
      <img :src="layoutImgEn" v-if="$i18n.locale === 'en_US'">
      <img :src="layoutImg" v-else>
    </div>
    <div class="superiority">
      <div class="inner-header">
        {{ $t('companyProfile.superiority') }}
      </div>
      <img :src="advantageImgEn" v-if="$i18n.locale === 'en_US'">
      <img :src="advantageImg" v-else>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanyProfile",
  components: {

  },
  data() {
    return{
      profileImg: require("/src/assets/images/profile.png"),
      layoutImg: require("/src/assets/images/layout.jpg"),
      layoutImgEn: require("/src/assets/images/layout-en.jpg"),
      advantageImg: require("/src/assets/images/advantage.jpg"),
      advantageImgEn: require("/src/assets/images/advantage-en.jpg"),
      honorLeftImg: require("/src/assets/images/honor-left.jpg"),
      honorImgList1: [
        require("/src/assets/images/honors/" + "honor-right-1-1.jpg"),
        require("/src/assets/images/honors/" + "honor-right-1-2.jpg"),
        require("/src/assets/images/honors/" + "honor-right-1-3.jpg"),
        require("/src/assets/images/honors/" + "honor-right-1-4.jpg"),
        require("/src/assets/images/honors/" + "honor-right-1-5.jpg"),
        require("/src/assets/images/honors/" + "honor-right-1-6.jpg"),
      ],
      honorImgList2: [
        require("/src/assets/images/honors/" + "honor-right-2-1.jpg"),
        require("/src/assets/images/honors/" + "honor-right-2-2.jpg"),
        require("/src/assets/images/honors/" + "honor-right-2-3.jpg"),
        require("/src/assets/images/honors/" + "honor-right-2-4.jpg"),
      ]
    }

  }
}
</script>

<style scoped lang="less">
.container {
  .inner-header {
    font-size: 28px;
    font-weight: 600;
    color: #222222;
    margin-top: 36px;
    margin-bottom: 24px;
    text-align: center;
  }

  .company-profile {
    .profile-detail {
      width: 1200px;
      color: #444444;
      font-size: 16px;
      line-height: 30px;
      margin: auto auto 72px auto;
    }
  }

  .layout {
    text-align: center;
    margin-bottom: 80px;
    margin-top: 36px;

    > img {
      object-fit: contain;
      width: 617px;
    }
  }

  .superiority {
    text-align: center;

    > img {
      width: 1300px;
      height: 401px;
      margin-top: 15px;
    }

    margin-bottom: 120px;
  }
}
</style>
