<template>
  <div>
    <div class="honor">
      <!--<div class="inner-header">-->
      <!--  {{ $t('companyProfile.honor') }}-->
      <!--</div>-->
      <div class="honor-list">
        <div class="left">
          <div class="left-1">
            {{ $t('companyProfile.honorText1') }}
          </div>
          <div class="left-2">
            {{ $t('companyProfile.honorText2') }}
          </div>
          <div class="left-3">
            {{ $t('companyProfile.honorText3') }}
          </div>
        </div>
        <div class="right">
          <div class="line-first">
            <el-image
              v-for="(item, index) in honorImgList1"
              :key="index"
              style="width: 140px; height: 200px;"
              :src="urlPrefix + item"
              :preview-src-list="[urlPrefix + item]"
              class="first-img"
            />
          </div>
          <div
            class="line-second"
          >
            <el-image
              v-for="(item, index) in honorImgList2"
              :key="index"
              style="width: 220px; height: 166px;"
              :src="urlPrefix + item"
              :preview-src-list="[urlPrefix + item]"
              class="second-img"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Honor",
  data() {
    return {
      urlPrefix: 'https://jd-data.oss-cn-shanghai.aliyuncs.com/honor/',
      honorImgList1: [
        "honor-right-1-1.png",
        "honor-right-1-2.png",
        "honor-right-1-3.png",
        "honor-right-1-4.png",
        "honor-right-1-5.png",
      ],
      honorImgList2: [
        "honor-right-2-1.png",
        "honor-right-2-2.png",
      ]
    };
  },
  created() {
  },
  methods: {},
};
</script>

<style scoped lang="less">
.honor {
  width: 1400px;
  margin: auto auto 120px auto;

  .honor-list {
    margin-top: 48px;
    display: flex;

    .left {
      width: 228px;
      min-height: 500px;
      margin-right: 23px;
      background: url("~@/assets/images/honor-left.jpg") no-repeat;
      background-size: cover;
      text-align: center;

      .left-1 {
        margin: 161px auto 24px auto;
        min-height: 32px;
        font-size: 28px;
        font-weight: 600;
        color: #16314D;
        line-height: 32px;
      }

      .left-2 {
        margin: auto;
        width: 132px;
        font-size: 16px;
        line-height: 26px;
        color: #16314D;
        font-weight: 500;
      }

      .left-3 {
        margin: 36px auto auto auto;
        width: 130px;
        min-height: 36px;
        line-height: 36px;
        background: #16314D;
        border-radius: 4px;
        color: #FFFFFF;
        font-size: 14px;
      }
    }

    .right {
      width: 80%;
      min-height: 544px;
      background: #F9F9F9;

      .line-first {
        //margin-left: 38px;
        margin-top: 57px;
        margin-left: 10px;
        //margin-right: 20px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .first-img {
          width: 140px;
          height: 200px;
          margin-right: 10px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .line-second {
        margin-top: 51px;
        margin-left: 10px;

        .second-img {
          width: 220px;
          height: 166px;
          margin-right: 50px;
        }
      }
    }
  }
}
</style>
