import Vue from "vue";
import VueI18n from "vue-i18n";
import locale from "element-ui/lib/locale";
import zh from "@/assets/i18n/zh";
import en from "@/assets/i18n/en";
import elementEnLocale from "element-ui/lib/locale/lang/en";
import elementZhLocale from "element-ui/lib/locale/lang/zh-CN";
import {doPost} from "@/utils/http";

Vue.use(VueI18n);

const messages = {
  zh_CN: {
    ...zh,
    ...elementZhLocale,
  }, // 中文语言包
  en_US: {
    ...en,
    ...elementEnLocale,
  }, // 英文语言包
};

const i18n = new VueI18n({
  locale: localStorage.getItem("lang") || "zh_CN",
  messages: messages,
});

// 配置elementUI 语言转换关系
locale.i18n((key, value) => i18n.t(key, value))

export function toEn() {
  let mappingStore = localStorage.getItem("langMapping");
  if (mappingStore !== null) {
    localStorage.setItem("lang", "en_US");
    window.location.reload();
    return;
  }
  let url = "/admin/i18n/getMapping";
  doPost(url).then(res => {
    if(res.code === 200) {
      localStorage.setItem("langMapping", JSON.stringify(res.data));
      localStorage.setItem("lang", "en_US");
      window.location.reload();
    }
  })
}

export function translate(cat, val) {
  let locale = localStorage.getItem("lang") || "zh_CN";
  if (locale === "zh_CN") {
    return val;
  }
  if (val === "产品类别") {
    return "Category";
  }
  let mapping = {};
  let mappingStore = localStorage.getItem("langMapping");
  mapping = JSON.parse(mappingStore);
  return mapping[cat][val] ? mapping[cat][val]: val;
}

export default i18n;
