<template>
  <main>
    <body>
      <div class="left">
        <div class="img-block">
          <div class="main-img-box">
            <img
              v-if="mainImg"
              class="main-img"
              :src="baseURL + mainImg"
            >
            <img
              v-else
              class="main-img"
              :src="errImg"
            >
          </div>
          <div class="carousel">
            <el-carousel
              type="card"
              height="70px"
              arrow="always"
              :autoplay="false"
              @change="getIndex"
            >
              <el-carousel-item
                v-for="(img, index) in imgs"
                :key="index"
              >
                <div class="select-img-box">
                  <img :src="baseURL + img">
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <div class="box">
<!--          <div class="span">-->
<!--            同等规格型号-->
<!--          </div>-->
<!--          <div-->
<!--            v-for="(item, index) in prodData['原厂替换']"-->
<!--            :key="index"-->
<!--            class="row"-->
<!--          >-->
<!--            <span class="key">{{ item['原厂品牌'] }}&nbsp;&nbsp;&nbsp;</span>-->
<!--            <span class="value">{{ item['原厂型号'] }}</span>-->
<!--          </div>-->
          <div class="span">
            {{ $t("product.prodDoc") }}
          </div>
          <div
            v-for="(item, index) in prodData['docs']"
            :key="index"
            class="row"
          >
            <a
              :href="baseURL + item['file_path']"
              class="value"
              target="_blank"
            >{{ item['file_name'] }}</a>
          </div>
        </div>
      </div>

      <div class="right">
        <div class="box">
          <div class="head card">
            <div class="card-content">
              <div class="partNo">
                {{ prodData["产品型号"] }}
              </div>
              <div v-show="prodData.sampleFlag">
                <div class="tag">
                  <div class="el-icon-box" />
                  <div class="text">
                    {{ $t("product.sampAvl") }}
                  </div>
                </div>
                <div class="cart-func">
                  <el-input-number
                    v-model="num"
                    size="small"
                    :min="1"
                    :max="20"
                    class="counter"
                    @change="handleChange"
                  />
                  <el-button
                    icon="el-icon-shopping-bag-2"
                    size="small"
                    @click="addToCart"
                  >
                    {{ $t("product.addToCart") }}
                  </el-button>
                </div>
              </div>
            </div>
          </div>
          <div class="span">
            {{ $t("product.productIntroduction") }}
          </div>
          <div
            v-for="(key, index) in overviewKeys"
            :key="'1-'+index"
            class="row"
          >
            <span class="key">{{ $translate(cat, key) }}:&nbsp;&nbsp;&nbsp;</span>
            <span class="value">{{ $translate(cat, prodData[key]) }}</span>
          </div>

          <div class="span">
            {{ $t("product.detailedParams") }}
          </div>
          <div
            v-for="(key, index) in detailsKeys"
            :key="'2-'+index"
            class="row"
          >
            <span class="key">{{ $translate(cat, key) }}:&nbsp;&nbsp;&nbsp;</span>
            <span class="value">{{ $translate(cat, prodData[key]) }}</span>
          </div>
        </div>
      </div>
    </body>
  </main>
</template>

<script>
import commonHeader from "@/components/CommonHeader";
import CommonFooter from "@/components/CommonFooter";
import baseUrl from "@/store/baseUrl";
import eventBus from "@/utils/eventBus";
export default {
  name: "ProductDetail",
  components: {
  },
  data() {
    return {
      baseURL: baseUrl.state.downloadURL,
      errImg: require("@/assets/images/jdError.png"),
      id: this.$route.query.id,
      prodData: {},
      cat: "",
      overviewKeys: ["产品描述", "产品类别"],
      detailsKeys: [],
      otherKeys: ["原厂替换", "产品型号", "imgs", "docs", "sampleFlag"],
      imgs: [],
      docs: [],
      mainImg: null,
      loginVisible: false,
      isLogin: false,
      userId: null,
      num: 0,
    }
  },
  created() {
    this.initData();
    this.initCartInfo();
  },
  methods: {
    initData() {
      let url = "/item/goodsQuery/loadDetailsByQuery";
      let params = {id:this.id};
      this.$doPost(url, params).then(res=>{
        if (res.code === 200) {
          this.prodData = res.data;
          this.cat = res.data["产品类别"]
          // 初始化详细参数
          for(let key in this.prodData) {
            if (this.overviewKeys.indexOf(key) === -1 && this.otherKeys.indexOf(key) === -1 && !key.startsWith("可替代")){
              this.detailsKeys.push(key)
            }
          }
          // 初始化图片列表
          // 由于某些原因，需要第二张图作为主图
          let imgs = this.prodData["imgs"];
          this.imgs = imgs.splice(1,imgs.length)
          this.imgs.push(imgs[0])
          this.mainImg = this.imgs && this.imgs[0];
          this.docs = this.prodData["docs"];
        }
      })
    },
    addToCart() {
      if (this.isLogin) {
        let url = "/cart/sample-cart/addSamplesToCart";
        let sampleCarts = [{partNo: this.prodData['产品型号'], amount: this.num}]
        let param = {
          userId: this.userId,
          sampleCarts: sampleCarts,
        }
        this.$doPost(url,param,true).then(res=>{
          if (res.code === 200) {
            this.$message.success(this.$t("sampleApply.sampleApply.toCartSuccess"))
            eventBus.$emit("userId", this.userId);
          }
          else if (res.code === 2000) {
            this.$message.warning(this.$t("sampleApply.sampleApply.cartIsFull"));
          }
          else {
            this.$message.error(this.$t("sampleApply.sampleApply.toCartFailed"));
          }
        });
        return;
      }
      eventBus.$emit("isLogin", false);
      console.log("未登录");
    },
    handleChange() {},
    initCartInfo() {
      this.initUserId();
      if (this.isLogin) {
        this.initSampNum();
      }
    },
    initUserId() {
      this.userId = localStorage.getItem('userId');
      if (this.userId !== null) {
        this.isLogin = true;
      }
    },
    initSampNum() {
      let url = "/cart/sample-cart/getSampleCarts?userId=" + this.userId;
      let partNos = [this.prodData['产品型号']];
      this.$doPost(url, partNos, true).then(res=>{
        if (res.code === 200) {
          let len = Object.keys(res.data).length;
          if(len > 0) {
            this.num = res.data[this.prodData['产品型号']];
          }
        }
      })
    },
    getIndex(index){
      this.mainImg = this.imgs[index];
    },
  }
}
</script>

<style scoped lang="less">
main {
  min-height: 100vh;
}
body {
  margin: 40px auto 120px auto;
  width: 80%;
  display: flex;
  font-family: "微软雅黑", "Arial Black";
  .left {
    width: 50%;
    .img-block{
      margin-top: 40px;
      .main-img-box {
        margin: 0 auto;
        width: 300px;
        height: 300px;
        .main-img {
          width: 300px;
          height: 300px;
          border: solid #D8D8D8 0.5px;
          border-radius: 5px;
        }
      }
      .carousel {
        margin: 20px auto;
        width: 400px;
        .el-carousel{
          width: 100%;
          /deep/ .select-img-box {
            margin: 0 auto;
            width: 50px;
            height: 100%;
            > img {
              width: 100%;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
  .right {
    width: 50%;
  }
}
.span {
  width: 400px;
  height: 40px;
  line-height: 40px;
  background: rgb(220, 220, 220);
  font-size: 18px;
  font-weight: bold;
  padding-left: 20px;
  margin: 20px 0 20px 0;
}
.row {
  line-height: 40px;
  .key {
    font-size: 16px;
  }
  .value {
    font-size: 16px;
    color: rgb(80, 80, 80);
  }
}
.box {
  width: 450px;
  margin: 0 auto;
  .head {
    margin-top: 50px;
    margin-bottom: 20px;
    .partNo {
      font-size: 25px;
      font-weight: bold;
      margin-right: 20px;
      margin-bottom: 10px;
    }
    .cart-func {
      display: flex;
      justify-content: space-between;
      .counter {

      }
      /deep/ .el-button {
        color: black;
        font-size: 15px;
        box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);

      }
    }

    .tag {
      display: flex;
      .el-icon-box {
        font-size: 25px;
        font-weight: 300;
        margin-right: 5px;
        line-height: 50px;
      }
      .text {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
}
.card {
  line-height: 50px;
  background: white;
  width: 420px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  border-radius: 15px;
}
.card-content {
  padding: 20px;
}
</style>