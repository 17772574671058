<template>
  <div id="app">
    <router-view></router-view>
    <susp-consult></susp-consult>
  </div>
</template>

<script>

import SuspConsult from "@/components/SuspConsult";
export default {
  name: 'App',
  components: {
    SuspConsult

  }
}
</script>

<style>
html,body{
  margin: 0;
  padding: 0;
}
#app {
  min-height: 100vh;
  background: #F4F4F4;
  position: relative;
}
.el-dialog__wrapper {
  z-index: 9999 !important;
}
.el-message {
  z-index: 9999 !important;
}
</style>
