module.exports = {
  common: {
    confirm: "Confirm",
    cancel: "Cancel",
    deleteSuccess: "Deletion Succeeded",
    deleteFailed: "Delete Failed",
    copySuccess: "Copy succeeded",
  },
  header: {
    mainProducts: "Main Products",
    technicalProposal: "Solutions",
    information: "News",
    companyProfile: "About JiDC",
    aboutUs: "About JiDC",
    honor: "Honor and Qualification",
    sampleApply: "Samples Request",
    productManual: "JiDC Product Manual",
    jidc: "JiDe Precision",
    personalCenter: "Personal Center",
    logout: "Logout",
    clickLogin: "Click Login",
    weChatLogin: "Scan with WeChat to login without registration",
    loginToApplySamples: "Sign in for free sample application",
    loginFirst: "Please login first!",
    tips: "Tips",
    quit: "Quit",
    loginTitle: "SIGN IN",
    regTitle: "SIGN UP",
    resetTitle: "RESET PASSWORD",
    login: "Log in",
    toLogin: "To login",
    reset: "Reset password",
    signUp: "Sign up",
    submit: "Submit",
    email: "Email",
    pwd: "Password",
    confirmPwd: "Confirm password",
    verCode: "Verification code",
    sendCode: "Send code",
    loginAgree: "Login means reading and agreeing to",
    signUpAgree: "Sign in means reading and agreeing to",
    submitAgree: "Submission means reading and agreeing to",
    protocol: " the Service Agreement and Privacy Policy.",
    regSuccess: "Congratulations! You have registered successfully.",
    loginSuccess: "Login successfully, Welcome! ",
    resetSuccess: "Password changed successfully.",
    jmp2Login: "About to jump to the login page...",
    pwdRule: "At least 8 digits, must contain both letters and numbers.",

    technicalProposalList: {
      solutionList: "solutionList",
      carRegion: "Automotive",
      newEnergyConnector: "New Energy Connectors",
      heavyDutyConnector: "Heavy Duty Connectors (Off Road)",
      houseAppliances: "Appliances",
      greenEnergy: "Solar, Green Energy",
    },

    productList: {
      housing: "Housing",
      terminals: "Terminals",
      connectors: "Connectors",
      sealingProducts: "Sealing Products",
      customizedProducts: "Customized Products",
    },

    searchPlaceholder: "Find JiDC or Equivalent Products"
  },
  footer: {
    aboutUs: {
      self: "About JiDC",
      "address-line1": "Guangdong JiDe Precision Electronics Co., Ltd",
      "address-line2": "28#Dongke Road, Dongcheng Science Park, Dongcheng District, Dongguan city, Guangdong Province, China",
    },
    technicalProposal: {
      self: "Solutions",
      carRegion: "Automotive",
      newEnergyConnector: "New Energy Connectors",
      heavyDutyConnector: "Heavy Duty Connectors (Off Road)",
      houseAppliances: "Appliances",
      greenEnergy: "Solar, Green Energy",
    },
    contactUs: {
      self: "Contact Us",
      serviceHotline: "7x24 Support"
    },
    followUs: {
      self: "Connect With Us",
      officialAccount: "JiDC Precision Official Account",
      applets: "JiDC Precision Applet",
    },
    allRightsReserved: "All Rights Reserved."
  },
  // 首页
  index: {
    mission: "Creating Higher Reliability More Compact and Greener Connections",
    profileDetails: "JiDe Precision always adheres to the \"Customer-Centric\" concept, continues to increase investment in science and technology, cultivates talents, enhances core competitiveness of the company, and provides customers with integrated connectivity solutions!",
    showMore: "See more",

    // 动态
    dynamics: {

    },
    // 解决方案
    solutions: {

    }
  },
  // 技术方案
  technicalProposal: {

  },
  // 资讯
  information: {
    infoTitle: "JiDC News Center",
    infoListEmpty: "The information list is empty",
    searchPlaceholder: "Please enter the content",
    releaseTime: "Release Time",
    readNum: "Reading Volume",
  },
  // 公司简介
  companyProfile: {
    companyProfile: "About JiDC",
    profileDetails: "JiDC is a first-class manufacturer of connector precision components that integrates product design, mold development, precision stamping, injection molding, processing, and automated assembly, and has a variety of independently developed metal riveting production processes. The production centers of Jide Precision in Dongguan, Qingdao, and Dezhou are staffed with experienced senior engineering research and development personnel, a trained team of quality assurance engineers, and various precision equipment. Currently, Jide Precision has offices in Beijing, Shanghai, Shenzhen, Qingdao, Dezhou, and Jinan, with business coverage across the country, and can help customers nationwide meet the design and production requirements of various projects and end products, The products are mainly used in various fields such as transportation, new energy, household appliances, medical treatment, industry, etc.",
    superiority: "Our Advantage",
    honor: "Honors And Qualifications",
    ourLayout: "Our Layout",
    honorText1: "Honors and Qualifications",
    honorText2: "JiDC has rich experience and quality assurance",
    honorText3: "Quality Assurance",
  },
  // 样品申请
  sampleApply: {
    accountInfo: {
      self: "Account Info",
      baseInfo: "Profile",
      username: "Account",
      receiveAddress: "Ship To Address",
      addressList: "Address List",
      addAddress: "New Address",
      editAddress: "Edit Shipping Address",
      noAddress: "No Address Available",
      defaultAddress: "Default Address",
      asDefaultAddress: "Set as default address",
      receiver: "Receiver",
      telephone: "Telephone",
      phone: "Phone",
      area: "Region",
      address: "Address",
      postcode: "Postcode",
      email: "Email",
      textLengthLimit50: "Up to 50 words",
      receiverTips: "Please fill in the consignee",
      telephoneTips: "Please fill in the contact information",
      validPhoneTips: "Please enter a valid mobile phone number",
      addressTips: "Please fill in the detailed address",
      setDefaultAddressSuccess: "Setting default receiving address succeeded!",
      deleteAddressTips: "Whether to delete the shipping address?",
      addressDeleteSuccess: "Address deleted successfully!",
      addressAddSuccess: "Successfully added the receiving address!",
      addressEditSuccess: "Update receiving address succeeded!",
    },
    sampleApply: {
      self: "Samples Request",
      availableNum: "Total number of samples available",
      partNo: "Part No.",
      category: "Category",
      operation: "Operation",
      searchPlaceholder: "Find JiDC or Equivalent Products",
      validQuantityTips: "Please enter the appropriate quantity!",
      toCartSuccess: "Successfully added to shopping cart",
      cartIsFull: "The shopping cart is full",
      toCartFailed: "Failed to add to shopping cart",
    },
    cart: {
      self: "Cart",
      partNo: "Part No.",
      category: "Category",
      quantity: "Quantity",
      operation: "Operation",
      submit: "Submit",
      requestCartFailed: "Failed to request shopping cart interface!",
      updateFailed: "Modification failed",
      selectGoodsTips: "Please select goods!",
    },
    order: {
      self: "Samples Order",
      partNo: "Part No.",
      partNoPlaceholder: "Please input part no.",
      orderNo: "Order No.",
      orderNoPlaceholder: "Please input order no.",
      search: "Research",
      item: "Item",
      orderTime: "Order Placed",
      orderStatus: "Status",
      expressNo: "Express No.",
      receiveInfo: "Delivering Info",
      operation: "Operation",
      orderStatusList: {
        waitDeal: "Pending",
        dealing: "Processing",
        hasSend: "Shipped",
        canceled: "Canceled",
      },
      cancelOrder: "Cancel Order",
      cancelReason: "Reason for cancellation",
      textLengthLimit30: "Up to 30 words",
      enterCancelReasonTips: "Please enter the cancellation reason!",
      reEnterCancelReasonTips: "The cancellation reason can be up to 30 words, please re-enter!",
      orderCancelSuccess: "Cancel sample application successfully!",
    },
    preOrder: {
      sampleList: "Samples List",
      partNo: "Part No.",
      category: "Category",
      quantity: "Quantity",
      projectInfo: "Project Background Information",
      directCustomer: "Direct Customer：",
      directCustomerPlaceholder: "Please put your company name",
      mainFactory: "OEM Customer：",
      projectName: "Project Name：",
      functionalPart: "Application：",
      annualConsumption: "EAU：",
      productionTime: "SOP：",
      closingTime: "EOL(End of Life)：",
      // closingTime: "EOL：",
      remarks: "Remark：",
      receiveInfo: "Delivery Address",
      addressPlaceholder: "Please Select",
      editAddress: "Edit",
      newAddress: "New Address",
      submit: "Submit",
      productionTimeTips: "Please select the production date",
      closingTimeTips: "Please select the closing date",
      remarkTips: "Please enter order comments",
      directCustomerTips: "Please fill in the direct customer",
      functionalPartTips: "Please fill in the functional part",
      annualConsumptionTips: "Please fill in the annual consumption",
      productionTimeTips1: "Please select the production time",
    },
    orderDetail: {
      orderDetailTitle: "Sample Application - Details Page",
      baseInfo: "Basic Information",
      jidcPartNo: "JiDC PartNo",
      noExpressInfo: "No express delivery information",
      showCancelReason: "View cancellation reason",
    },
  },
  // 解决方案
  solutions: {
    solutionCenter: "JiDC Solutions Center",
    solutionListEmpty: "Solution list is empty",
  },
  paramSelect: {
    currentCategory: "Current Classification",
    reset: "Reset",
    screen: "Screen",
    noMatchedData: "No matching data",
    noData: "No data",
  },
  product: {
    jidcPartNo: "JiDC PartNo",
    desc: "Description",
    category: "Category",
    emptyResult: "Search result is empty",
    prodDoc: "Product Documents",
    sampAvl: "Sample Available",
    addToCart: "Add to sample cart",
    productIntroduction: "Product Introduction",
    detailedParams: "Detailed Parameters",
  }
}
