<template>
  <div
    class="select"
    :style="{
      width: width + 'px',
      'font-size': fontSize,
      height: Number(height) + Number(2) + 'px',
    }"
  >
    <div
      class="selectTop"
      :style="{ border: border ? '1px solid rgba(151, 151, 151, 1)' : 'none' }"
      @click="selectClick"
    >
      <div
        :style="{ height: height + 'px', 'line-height': height + 'px' }"
        :class="align == 'left' ? 'leftShow' : 'centerShow'"
      >
        {{ isShowkey ? value : currentName }}
      </div>
      <div>
        <img src="@/assets/images/component/select/xiala.png" />
      </div>
    </div>
    <div v-if="selectshow" class="selectBody">
      <div class="box">
        <ul>
          <li
            v-for="(item, index) in list"
            :key="index"
            :class="align == 'left' ? 'leftLi' : 'centerLi'"
            @click="selectName(item, index)"
          >
            <span :class="{ showSelect: value == item[showValue] }">{{
              item[showName]
            }}</span>
          </li>
        </ul>
      </div>
      <div class="tri"></div>
    </div>
  </div>
</template>
<script>
export default {
  model: {
    prop: "value",
    event: "update",
  },
  props: {
    fontSize: {
      default: "14px",
      type: String,
    },
    isShowkey: {
      default: false,
      type: Boolean,
    },
    align: {
      default: "center",
      type: String,
    },
    border: {
      default: false,
      type: Boolean,
    },
    value: {
      default: "",
      type: String,
    },
    list: {
      default: () => [],
      type: Array,
    },
    showName: {
      default: "name",
      type: String,
    },
    showValue: {
      default: "value",
      type: String,
    },
    height: {},
    width: {},
  },
  data() {
    return {
      currentName: "",
      selectshow: false,
    };
  },
  watch: {
    value(val, old) {
      let hasValue = false;
      this.list.forEach((element, index) => {
        if (this.value == element[this.showValue]) {
          this.currentName = element[this.showName];
          hasValue = true;
        }
      });
      if (!hasValue && this.list.length > 0) {
        this.currentName = this.list[0][this.showName];
        this.$emit("update", this.list[0][this.showValue]);
      }
    },
    list(newList, oldList) {
      let hasValue = false;
      this.list.forEach((element, index) => {
        if (this.value == element[this.showValue]) {
          this.currentName = element[this.showName];
          this.$emit("update", element[this.showValue]);
          this.$emit("onChange", index, element);
          hasValue = true;
        }
      });
      if (!hasValue && this.list.length > 0) {
        this.currentName = this.list[0][this.showName];
        this.$emit("update", this.list[0][this.showValue]);
        this.$emit("onChange", 0, this.list[0]);
      }
    },
  },
  mounted() {
    document.addEventListener("click", (e) => {
      if (!this.$el.contains(e.target)) this.selectshow = false;
    });
  },
  created() {
    let hasValue = false;
    this.list.forEach((element, index) => {
      if (this.value == element[this.showValue]) {
        this.currentName = element[this.showName];
        hasValue = true;
      }
    });
    if (!hasValue && this.list.length > 0) {
      this.currentName = this.list[0][this.showName];
      this.$emit("update", this.list[0][this.showValue]);
    }
  },
  methods: {
    selectClick() {
      this.selectshow = !this.selectshow;
    },
    selectName(item, index) {
      this.selectshow = false;
      this.currentName = item[this.showName];
      this.$emit("update", item[this.showValue]);
      console.log(item[this.showValue], this.currentName);
      this.$emit("onChange", index, item);
    },
  },
};
</script>
<style scoped>
.select img {
  height: 100%;
  width: 100%;
}
.leftLi {
  /* 左对齐样式 */
  margin: 18px 17px;
  cursor: pointer;
}
.centerLi {
  /* 居中样式 */
  text-align: center;
  margin: 8px auto;
  cursor: pointer;
}
.showSelect {
  color: rgba(255, 139, 61, 1);
}
.select {
  width: 300px;
}
.selectTop {
  display: flex;
  position: relative;
  border-radius: 4px;
  border: 1px solid rgba(34, 34, 34, 1);
  cursor: pointer;
}
.selectTop > div:nth-child(1) {
  /* font-size: 14px; */
  font-weight: 400;
  color: rgba(34, 34, 34, 1);
}
.leftShow {
  margin: 0 17px;
}
.centerShow {
  margin: auto;
}

.selectTop > div:nth-child(2) {
  width: 14px;
  height: 14px;
  position: absolute;
  right: 10px;
  top: 11px;
  line-height: 100%;
}
.selectBody {
  position: relative;
  margin-top: 8px;
  border-radius: 4px;
  border: 1px solid rgba(34, 34, 34, 1);
  background: #ffffff;
  z-index: 10;
}
.box {
  max-height: 170px;
  overflow: auto;
}
.box ul {
  padding-left: 0;
}
.box ul > li:hover {
  color: rgba(255, 139, 61, 1);
}
.box li {
  list-style: none;
  margin: 8px auto;
  padding: 0;
  line-height: 19px;
}
.tri {
  width: 7px;
  height: 7px;
  border-radius: 2px;
  border-top: 1px solid rgba(34, 34, 34, 1);
  border-left: 1px solid rgba(34, 34, 34, 1);
  transform: rotate(45deg);
  top: -5px;
  right: 14px;
  position: absolute;
  right: 20px;
  border-bottom-right-radius: 4px;
  z-index: 100;
  background-color: white;
}
</style>
