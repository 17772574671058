<template>
  <div id="v-dialog" class="v-dialog">
    <!-- 弹出组件 -->
    <el-dialog
      :visible.sync="visible"
      center
      :close-on-click-modal="false"
      :before-close="close"
    >
      <div class="content">
        <p>{{ content }}</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <div v-if="buttonList" class="btn">
          <el-button :value="buttonList[0]" @onClick="cancel" />
          <el-button :value="buttonList[1]" @onClick="confirm" />
          <!-- <span class="button"><el-button @click="cancel">{{buttonList[0]}}</el-button></span>
          <span class="button"><el-button @click="confirm">{{buttonList[1]}}</el-button></span> -->
        </div>
        <el-button v-else @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    buttonList: {
      type: Array,
      default: () => ["取消", "确定"],
    },
  },
  data() {
    return {
      visible: false,
      content: "",
    };
  },
  watch: {
    show(cur, old) {
      // 通过监控data里的show属性  弹框有三个事件（右上角取消  确定按钮  取消按钮）
      // 每个事件写了 this.show = false
      // 当弹框出现的时候 点击任何一个事件  都会触发这里的监控事件  将页面上的弹框Dom移除
      if (cur === false) {
        let dialog = document.getElementById("v-dialog");
        dialog.parentNode.removeChild(dialog);
      }
    },
  },
  methods: {
    close() {
      // 右上角关闭
      this.a_close && this.a_close();
      this.visible = false;
      // 删除判断增加的window属性
      delete window.alertIsShow;
    },
    confirm() {
      // 确定
      this.a_confirm && this.a_confirm();
      this.visible = false;
      // 删除判断增加的window属性
      delete window.alertIsShow;
    },
    cancel() {
      // 确定
      this.a_cancel && this.a_cancel();
      this.visible = false;
      // 删除判断增加的window属性
      delete window.alertIsShow;
    },
    // onclick(value) {
    //   // this.$emit(value);
    //   this.a_onclick && this.a_onclick(value);
    //   this.visible = false;
    //   // 删除判断增加的window属性
    //   delete window.alertIsShow;
    // },
  },
};
</script>

<style scoped>

::v-deep .el-dialog__wrapper {
  z-index: 9999 !important;
}

.v-dialog /deep/ .el-dialog {
  margin-top: 220px !important;
  width: 392px;
}
.v-dialog /deep/ .el-dialog__headerbtn {
  right: 10px;
  top: 10px;
}
.v-dialog /deep/ .el-dialog__body {
  padding: 10px 28px 24px 28px;
}
.v-dialog /deep/ .el-dialog__footer {
  text-align: center;
  padding: 0 0 24px 0;
}
.v-dialog /deep/ .el-button {
  width: 98px;
  height: 28px;
  background: rgb(150, 150, 150);
  border-radius: 14px;
  color: #fff;
  line-height: 28px;
  padding: 0;
  outline: 0;
  border: 0;
  margin: 0 !important;
}
.v-dialog /deep/ .el-dialog__headerbtn .el-dialog__close {
  color: #222;
}
.v-dialog /deep/ .el-dialog__headerbtn .el-dialog__close:hover {
  color: #222;
}
.v-dialog .content {
  color: rgba(34, 34, 34, 1);
  line-height: 28px;
  display: block;
  text-align: center;
  /* margin: 0 auto; */
}
.v-dialog .content p {
  display: inline-block;
  text-align: left;
}
.btn {
  display: flex;
  justify-content: center;
}
.btn > div:not(:last-child) {
  margin-right: 62px;
}
</style>
