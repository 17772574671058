import Vue from "vue";
import Vuex from "vuex";
import tab from './tab'
import baseurl from "@/store/baseUrl";

Vue.use(Vuex)

export default new Vuex.Store({
    modules:{
        baseurl,
        tab
    }
})