import Vue  from "vue";
import VueRouter from "vue-router";
import CommentsArea from "@/views/comments/CommentsArea";
import JdNews from "@/views/news/JdNews";
import CompanyProfile from "@/views/profile/CompanyProfile";
import productList from "@/views/productList/productList";
import productDetail from "@/views/productDetail/productDetail";
import preOrder from "@/views/order/preOrder";
import messageBox from "element-ui/packages/message-box";
import {doPost} from "@/utils/http";
import Honor from "@/views/profile/Honor.vue";


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Index',
        redirect: "/main",
        component: () => import("@/views/home/index"),
        children: [
            {
                path: "/main",
                name: "Main",
                component: () => import("@/views/home/Main"),
            },
            {
                path: '/CommentsArea',
                name: 'CommentsArea',
                component: CommentsArea,
            },
            {
                path: '/SolutionList',
                name: 'SolutionList',
                component: () => import("@/views/solution/SolutionList"),
                meta: {
                    componentsName: "解决方案列表",
                },
            },
            {
                path: '/SolutionDetail',
                name: 'SolutionDetail',
                component: () => import("@/views/solution/SolutionDetail"),
                meta: {
                    componentsName: "解决方案详情页",
                },
            },
            {
                path: '/InformationDetail',
                name: 'InformationDetail',
                component: () => import("@/views/news/InformationDetail"),
                meta: {
                    componentsName: "资讯详情页",
                },
            },
            {
                path: '/Personal',
                name: 'Personal',
                component: () => import("@/views/personal/Personal"),
                meta: {
                    componentsName: "个人中心",
                },

            },
            {
                path: '/ParamsSelect',
                name: 'ParamsSelect',
                component: () => import("@/views/paramsSelect/ParamsSelect"),
                meta: {
                    componentsName: "参数选型",
                },

            },
            {
                path: '/News',
                name: 'News',
                component: JdNews,
            },
            {
                path: '/CompanyProfile',
                name: 'CompanyProfile',
                component: CompanyProfile,
            },
            {
                path: '/honor',
                name: 'Honor',
                component: Honor,
            },
            {
                path: '/productList',
                name: 'productList',
                component: productList,
            },
            {
                path: '/productDetail',
                name: 'productDetail',
                component: productDetail,
            },
            {
                path: '/preOrder',
                name: 'preOrder',
                component: preOrder,
            },
            {
                path: '/SampleOrderDetail',
                name: 'SampleOrderDetail',
                component: () => import("@/views/personal/SampleOrderDetail"),
                meta: {
                    componentsName: "样品订单详情页",
                },
            },
            {
                path: '/UserAddressList',
                name: 'UserAddressList',
                component: () => import("@/views/personal/UserAddressList"),
                meta: {
                    componentsName: "个人中心收货地址列表页",
                },
            },
        ]
    },
    {
        path: '/protocol-en',
        name: 'protocol-en',
        component: () => import("@/views/protocol/protocol-en.vue"),
        meta: {
            componentsName: "用户协议英文版",
        },
    },
    {
        path: '/protocol-zh',
        name: 'protocol-zh',
        component: () => import("@/views/protocol/protocol-zh.vue"),
        meta: {
            componentsName: "用户协议中文版",
        },
    },
    // {
    //     path: "/main",
    //     name: "Main",
    //     component: () => import("@/views/home/Main"),
    // },
    // {
    //     path: '/CommentsArea',
    //     name: 'CommentsArea',
    //     component: CommentsArea,
    // },
    // {
    //     path: '/SolutionList',
    //     name: 'SolutionList',
    //     component: () => import("@/views/solution/SolutionList"),
    //     meta: {
    //         componentsName: "解决方案列表",
    //     },
    // },
    // {
    //     path: '/SolutionDetail',
    //     name: 'SolutionDetail',
    //     component: () => import("@/views/solution/SolutionDetail"),
    //     meta: {
    //         componentsName: "解决方案详情页",
    //     },
    // },
    // {
    //     path: '/InformationDetail',
    //     name: 'InformationDetail',
    //     component: () => import("@/views/news/InformationDetail"),
    //     meta: {
    //         componentsName: "资讯详情页",
    //     },
    // },
    // {
    //     path: '/Personal',
    //     name: 'Personal',
    //     component: () => import("@/views/personal/Personal"),
    //     meta: {
    //         componentsName: "个人中心",
    //     },
    //
    //         },
    //         {
    //             path: '/ParamsSelect',
    //             name: 'ParamsSelect',
    //             component: () => import("@/views/paramsSelect/ParamsSelect"),
    //             meta: {
    //                 componentsName: "参数选型",
    //             },
    //
    // },
    // {
    //     path: '/News',
    //     name: 'News',
    //     component: JdNews,
    // },
    // {
    //     path: '/CompanyProfile',
    //     name: 'CompanyProfile',
    //     component: CompanyProfile,
    // },
    // {
    //     path: '/productList',
    //     name: 'productList',
    //     component: productList,
    // },
    // {
    //     path: '/productDetail',
    //     name: 'productDetail',
    //     component: productDetail,
    // },
    // {
    //     path: '/preOrder',
    //     name: 'preOrder',
    //     component: preOrder,
    // },
    // {
    //     path: '/SampleOrderDetail',
    //     name: 'SampleOrderDetail',
    //     component: () => import("@/views/personal/SampleOrderDetail"),
    //     meta: {
    //         componentsName: "样品订单详情页",
    //     },
    // },
    // {
    //     path: '/UserAddressList',
    //     name: 'UserAddressList',
    //     component: () => import("@/views/personal/UserAddressList"),
    //     meta: {
    //         componentsName: "个人中心收货地址列表页",
    //     },
    // },
]

const router = new VueRouter({
    mode: 'history',
    routes
})
router.beforeEach((to, from, next) => {

    if (to.name === "Personal") {
        let username = localStorage.getItem("username");
        let userId = localStorage.getItem("userId");
        let token = localStorage.getItem("token");
        if (userId !== null && token !== null) {
            let url = "/sso/user-login/checkToken";
            let params = {userId: userId, token: token}
            doPost(url, params).then(res => {
                if (res.code === 200) {
                    if (res.data === false) {
                        let reLogin = localStorage.getItem("lang") === "en_US" ? "Token invalid, please login again!" : "令牌失效，请重新登录！";
                        let confirmText = localStorage.getItem("lang") === "en_US" ? "Confirm" : "确定";
                        messageBox.alert(reLogin, {
                            confirmButtonText: confirmText,
                        }).then(r => {
                            localStorage.clear();
                            // router.go(0)
                        });

                    } else {
                        next()
                    }
                }
            })
        } else {
            let loginFirst = localStorage.getItem("lang") === "en_US" ? "Please login first!" : "请先登录！";
            let tips = localStorage.getItem("lang") === "en_US" ? "Tips" : "提示";
            let confirmText = localStorage.getItem("lang") === "en_US" ? "Confirm" : "确定";
            messageBox.alert(loginFirst, tips, {
                confirmButtonText: confirmText,
            }).then(r => {
                router.push({path: "/"}).then(r => {})
            });
        }
    } else {
        next()
    }

})
export default router
