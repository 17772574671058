import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import './theme/index.css';
import router from './router'
import store from './store';
import 'lib-flexible';
import {translate} from "@/utils/i18n";
import {doGet, doPost, downLoad} from "@/utils/http";
import * as echarts from 'echarts';
import select from "@/components/ui/select/select";
import i18n from "@/utils/i18n";
import commonUtil from "@/utils/commonUtil";

Vue.config.productionTip = false
Vue.use(ElementUI)

Vue.prototype.$doPost = doPost
Vue.prototype.$doGet= doGet
Vue.prototype.$download= downLoad
Vue.prototype.$commonUtil = commonUtil
Vue.prototype.$echarts = echarts
Vue.prototype.$translate = translate

import Router from 'vue-router'
Vue.use(Router)
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.component("VSelect", select);
new Vue({
  i18n,
  store,
  router,
  render: h => h(App),
}).$mount('#app')
