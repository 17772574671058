<template>
  <div>
    <!--<common-header />-->
    <main>
      <div class="main-box">
        <div class="head">
          <div class="title-text">
            {{ $t("information.infoTitle") }}
          </div>
          <el-input
            v-model="searchKey"
            :placeholder="$t('information.searchPlaceholder')"
            class="input-box"
            size="small"
            @keyup.enter.native="listInformationByPage"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              class="search-btn"
              @click="listInformationByPage"
            />
          </el-input>
        </div>

        <div
          v-if="informationList != null && informationList.length > 0"
          class="body"
        >
          <div
            v-for="(item, index) in informationList"
            :key="index"
            class="news-item"
          >
            <div
              v-if="$i18n.locale === 'en_US'"
              class="item-header"
              @click="toDetailPage(item)"
            >
              {{ item.enTitle }}
            </div>
            <div
              v-else
              class="item-header"
              @click="toDetailPage(item)"
            >
              {{ item.title }}
            </div>
            <div class="item-body">
              <div class="item-aside">
                <el-image
                  :src="item.img"
                  style="width: 200px;border: solid #D8D8D8 0.5px;border-radius: 5px;"
                >
                  <div slot="error">
                    <img
                      :src="require('/src/assets/images/jdError.png')"
                      style="width: 200px;border: solid #D8D8D8 0.5px;border-radius: 5px;"
                    >
                  </div>
                </el-image>
              </div>
              <div class="item-main">
                <div
                  v-if="$i18n.locale === 'en_US'"
                  class="item-text"
                >
                  {{ item.enDescription }}
                </div>
                <div
                  v-else
                  class="item-text"
                >
                  {{ item.description }}
                </div>
                <div class="item-tag">
                  {{ $t("information.releaseTime") }}：{{ item.releaseTime }}&nbsp;&nbsp;&nbsp;{{ $t("information.readNum") }}：{{ item.readNumber }}
                </div>
              </div>
            </div>
          </div>
          <el-pagination
            background
            layout="prev, pager, next, total"
            :page-size="page.size"
            :total="page.total"
            :current-page="page.current"
            class="footer-pagination"
            @current-change="handleCurrentChange"
          />
        </div>
        <div
          v-else
          class="body"
        >
          <el-empty :description="$t('information.infoListEmpty')" />
        </div>
      </div>
    </main>
    <!--<common-footer />-->
  </div>
</template>

<script>
import CommonHeader from "@/components/CommonHeader";
import CommonFooter from "@/components/CommonFooter";

export default {
  name: "JdNews",
  components: {
    // CommonFooter,
    // CommonHeader,
  },
  data(){
    return{
      searchKey: "",
      informationList: [],
      page: {
        size: 10,
        total: 0,
        current: 1,
      },
    }
  },
  created() {
    this.listInformationByPage();
  },
  methods: {
    // 跳转到详情页面
    toDetailPage(item) {
      console.log(item);
      this.$router.push({
        path: '/informationDetail',
        query: {
          informationId: item.id,
        }
      })
    },
    handleCurrentChange(val) {
      this.page.current = val;
      this.listInformationByPage();
    },
    listInformationByPage() {
      let url = "/item/information/listInformationByPage";
      let param = {
        size: this.page.size,
        current: this.page.current,
        total: this.page.total,
        searchKey: this.searchKey
      }
      this.$doPost(url, param).then((res) => {
        if (res.code == 200) {
          console.log(res.data);
          this.informationList = res.data.records;
          this.page.total = res.data.total;
        }
      }).catch((err) => {
        console.log(err);
      });
    },
    // searchInformation() {
    //   if (this.searchKey == null || this.searchKey.trim() == "") {
    //     this.$message({
    //       message: "请输入检索内容",
    //       type: "warning"
    //     });
    //     return;
    //   }
    //   let param = {
    //     size: this.page.size,
    //     searchKey: this.searchKey
    //   }
    //   let url = "/item/information/searchInformation";
    //   this.$doPost(url, param).then((res) => {
    //     if (res.code == 200) {
    //       console.log(res.data);
    //       this.informationList = res.data.records;
    //       this.page.total = res.data.total;
    //       this.page.current = res.data.current;
    //     }
    //   }).catch((err) => {
    //     console.log(err);
    //   });
    // }
  },
}
</script>

<style lang="less" scoped>
main {
  width: 100%;
  background-color: white;
  /*height: 1000px;*/
  display: flex;
  .main-box {
    width: 65%;
    margin: 0 auto;
    overflow: hidden;
    /deep/ .el-input-group__append:hover {
      background-color: white;
    }
    .head {
      font-family: PingFangSC-Bold;
      margin-top:100px;
      display: flex;
      .input-box {
        width: 300px;
      }
      .title-text {
        width: 100%;
        line-height: 32px;
        font-weight: bold
      }
    }
    .body {
      .news-item {
        margin: 20px 10px 40px 10px;
        .item-header {
          margin: 20px 10px 20px 10px;
          font-weight: bold;
          cursor: pointer;
          text-decoration: underline;
        }
  
        .item-header:hover {
          color: #5daf34;
        }
        
        .item-body {
          display: flex;
          .item-main {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-left: 30px;
            margin-right: 30px;
            position: relative;
            .item-text{
              font-size: 15px;
              line-height: 30px;
              /*display: -webkit-box;*/
              /*-webkit-box-orient: vertical;*/
              /*-webkit-line-clamp: 4;*/
              /*overflow: hidden;*/
            }
            .item-tag{
              font-size: 14px;
              /*position: absolute;*/
              /*bottom: 20px;*/
              color: #9ba0a6;
              margin-bottom: 15px;
            }
          }
        }
      }
      .footer-pagination {
        margin: 0 auto;
        text-align: center;
      }
    }
  }
}
</style>