<template>
  <footer>
    <div class="section flex-col">
      <div class="line flex-col" />
      <div class="text-wrapper flex-row">
        <!--<span class="text_1">{{ $t("footer.aboutUs.self") }}</span>-->
        <span class="text_2">{{ $t("footer.technicalProposal.self") }}</span>
        <span class="text_3">{{ $t("footer.contactUs.self") }}</span>
        <!--<span class="text_4">链接</span>-->
        <span class="text_5">{{ $t("footer.followUs.self") }}</span>
      </div>
      <div class="group flex-row">
        <!--<span class="paragraph_1">{{ $t("footer.aboutUs.address-line1") }}<br>{{ $t("footer.aboutUs.address-line2") }}<br></span>-->
        <span class="paragraph_2">
          <div>{{ $t("footer.technicalProposal.carRegion") }}</div>
          <div>{{ $t("footer.technicalProposal.newEnergyConnector") }}</div>
          <div>{{ $t("footer.technicalProposal.heavyDutyConnector") }}</div>
          <div>{{ $t("footer.technicalProposal.houseAppliances") }}</div>
          <div>{{ $t("footer.technicalProposal.greenEnergy") }}</div>
        </span>
        <span class="text_6">
          <span class="paragraph_1">{{ $t("footer.aboutUs.address-line1") }}<br>{{ $t("footer.aboutUs.address-line2") }}<br></span>
          <div>{{ $t("footer.contactUs.serviceHotline") }}: 18766032705</div>
          <div>Email: xiajing@jidcon.com</div>
        </span>
        <!--<span class="text_7">-->
        <!--  <span @click="openTyMall" style="cursor: pointer">京北通宇商城</span>-->
        <!--</span>-->
        <span>
          <span class="thumbnail_1">
            <img
              class="qrcode"
              referrerpolicy="no-referrer"
              :src="wxQrcode"
            >
          </span>
          <span class="thumbnail_2">
            <img
              class="qrcode"
              referrerpolicy="no-referrer"
              :src="appQrcode"
            >
          </span>
          <div style="display: flex">
            <div class="tag">{{ $t("footer.followUs.officialAccount") }}</div>
            <div class="tag">{{ $t("footer.followUs.applets") }}</div>
          </div>
        </span>
      </div>
    </div>
    <div class="copyright">
      <a href="https://beian.miit.gov.cn/">粤ICP备2021152175号-1</a>
      &nbsp;&nbsp;&nbsp;&nbsp;©2022-{{ thisYear }} www.jidcon.com {{ $t("footer.allRightsReserved") }}
    </div>
  </footer>
</template>

<script>
export default {
  name: "CommonFooter",
  data() {
    return {
      appQrcode: require("../assets/images/appQrcode.jpg"),
      wxQrcode: require("../assets/images/wxQrcode.png"),
      thisYear: 2022,
    }
  },
  created() {
    this.getThisYear();
  },
  methods: {
    getThisYear() {
      let date = new Date();
      this.thisYear = date.getFullYear();
    },
    openTyMall(){
      window.open("https://www.jbchip.com/")
    }
  }
}
</script>

<style scoped>
footer {
  width: 100%;
  height: 420px;
  background: #103F9B;
  color: #FFFFFF !important;
  overflow: hidden;
}

.section {
  width: 1201px;
  height: 200px;
  margin: 90px auto;
}

/*.line {*/
/*  width: 1201px;*/
/*  height: 1px;*/
/*  background: gray 100% no-repeat;*/
/*}*/

.text-wrapper {
  /*width: 1144px;*/
  width: 100%;
  height: 25px;
  margin-top: 32px;
  display: flex;
  justify-content: space-around;
}

.text-wrapper >span {
  width: 20%;
  text-align: center;
  height: 25px;
  display: inline-block;
  white-space: nowrap;
  line-height: 25px;
  /*color: rgba(0, 0, 0, 1);*/
  font-size: 18px;
  font-weight: bold;
  font-family: PingFangSC-Semibold;
}

.text_2 {
  /*margin-left: 235px;*/
}

.text_3 {
  /*margin-left: 166px;*/
}

.text_4 {
  /*margin-left: 182px;*/
}

.text_5 {
  /*margin-left: 209px;*/
}

.group {
  width: 100%;
  margin-top: 16px;
  /*color: rgba(0, 0, 0, 0.85);*/
  font-size: 12px;
  font-family: PingFangSC-Regular;
  overflow: visible;
  display: flex;
  justify-content: space-around;
}

.group >span {
  display: inline-block;
  line-height: 24px;
  overflow: hidden;
  width: 20%;
  text-align: center;
}

.paragraph_2 {
  /*margin-left: 165px;*/
}

.text_6 {
  /*margin-left: 178px;*/
}

.text_7 {
  /*margin-left: 170px;*/
}

.thumbnail_1 {
  width: 20px;
  height: 20px;
  margin: 2px 0;
}

.thumbnail_2 {
  width: 20px;
  height: 20px;
  margin: 2px 0 0 18px;
}
.copyright {
  color: #FFFFFF;
  text-align: center;
  font-size: 14px;
  margin-bottom: 10px;
  >a {
    color: white;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
.flex-row{
  overflow: visible;
}
.qrcode {
  width: 100px;
  height: 100px;
}
.tag {
  width: 100px;
  margin: 5px 10px;
}
</style>