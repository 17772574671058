/**
 * axios封装
 */
import axios from 'axios'
import qs from 'qs'
import store from '../store'
import commonUtil from "@/utils/commonUtil";
import alert from "@/utils/alert";
import router from "@/router";


// create an axios instance
const service = axios.create({
    baseURL: store.state.baseurl.baseURL,
    timeout: 50000,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
})
// request的请求拦截器



const getErrorMsgArr = (num, response) => {
    let errorMsgArr = sessionStorage.getItem("errorMsgArr");
    if (errorMsgArr && errorMsgArr.length > 0) {
        errorMsgArr = JSON.parse(errorMsgArr);
        errorMsgArr.push({
            api: response.config.url,
            code: num,
            time: commonUtil.getCurrentTime(2),
            msg: response.data.message,
            data: response.config.data,
            isSend: false,
        });
        sessionStorage.setItem("errorMsgArr", JSON.stringify(errorMsgArr));
    } else {
        sessionStorage.setItem(
            "errorMsgArr",
            JSON.stringify([
                {
                    api: response.config.url,
                    code: num,
                    time: commonUtil.getCurrentTime(2),
                    msg: response.data.message,
                    data: response.config.data,
                    isSend: false,
                },
            ])
        );
    }
};

service.interceptors.response.use(
    (response) => {
        console.log("请求接口:" + response.config.url);
        if (response.status == 200) {
            //401，500，200，503,400
            //判断登录
            if (response.data.code == 401) {
                //路由重定向
                getErrorMsgArr(401, response);
                router.replace({
                    path: "/Login",
                    query: { redirect: router.currentRoute.fullPath },
                });
            } else if (response.data.code == 400) {
                alert({
                    content: response.data.message,
                    visible: true,
                });
                getErrorMsgArr(400, response);
                return;
            } else if (response.data.code == 503) {
                alert({
                    content: response.data.message,
                    visible: true,
                });
                getErrorMsgArr(503, response);
                return;
            } else if (response.data.code == 500) {
                alert({
                    content: response.data.message,
                    visible: true,
                });
                getErrorMsgArr(500, response);
                return;
            } else if (response.data.code == 429) {
                getErrorMsgArr(429, response);
                return;
            }
        } else {
            console.error(response);
        }

        return Promise.resolve(response.data);
    },
    (error) => {
        return Promise.reject(error);
    }
);



// 封装get请求
export function doGet(url, params, Local) {
    let header = {};
    header['Locale'] = 'zh_cn';
    if (Local) {
        header['Locale'] = Local;
    }
    header['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
    header['Authorization'] = sessionStorage.getItem("token");
    header['Locale'] = getLocale();
    if (!params){
        params={}
    }
    // let config = {};
    // config.headers = header;
    params.headers =header;

    return new Promise((resolve, reject) => {
        service.get(url,params).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

// 封装post方法
export function doPost(url, params, isJson, Local) {
    let header = {};

    header['Locale'] = 'zh_cn';
    if (Local) {
        header['Locale'] = Local;
    }
    header['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
    header['Authorization'] = sessionStorage.getItem("token");
    header['Locale'] = getLocale();
    if (isJson) {
        header['Content-Type'] = "application/json";
        params = JSON.stringify(params);
    } else {
        params = qs.stringify(params);
    }
    let config = {};
    config.headers = header;
    return new Promise((resolve, reject) => {
        service.post(url, params, config).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

// 单独封装下载请求
export function downLoad(url, params, config) {
    params = qs.stringify(params);
    config.responseType =  "blob";
    config.timeout = 180000;
    return new Promise((resolve, reject) => {
        service
          .post(url, params, config)
          .then((res) => {
              resolve(res);
          })
          .catch((err) => {
              reject(err);
          });
    });
}

function getLocale() {
    let lang = localStorage.getItem("lang");
    if (lang === undefined || lang === null) {
        return "zh_cn"
    }
    return lang.toLowerCase();
}

export default service;