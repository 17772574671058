<template>
  <body>
    <!--<common-header @search="searchByQuery"/>-->

    <main>
      <div class="search_input">
        <div>
          <el-input
            v-model="searchKey"
            placeholder="搜索济德型号/同等规格型号"
            class="input-with-select"
            @keyup.enter.native="search"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="search"
            />
          </el-input>
        </div>
      </div>
      <!--      <div class="decoration">-->
      <!--        <div class="decor-1"></div>-->
      <!--        <div class="decor-2"></div>-->
      <!--      </div>-->
      <div class="prod-list">
        <div
          v-for="(goods, index) in goodsList"
          :key="index"
          class="prod-item"
        >
          <div class="img-box">
            <el-image :src="getImg(goods.goodsImg)">
              <div
                slot="error"
                class="image-slot"
              >
                <img
                  class="prod-img"
                  :src="errorImg"
                >
              </div>
            </el-image>
          </div>
          <div>
            <div class="part-no">
              <span>{{ $t("product.jidcPartNo") }} </span>
              <span
                class="href"
                @click="toDetail(goods)"
              >{{ goods["产品型号"] }}</span>
            </div>
            <div class="data-item">
              <span class="item-key">{{ $t("product.desc") }}：</span>
              <span class="item-value">{{ $translate(goods["产品类别"], goods["产品描述"]) }}</span>
            </div>
            <div class="data-item">
              <span class="item-key">{{ $t("product.category") }}：</span>
              <span class="item-value">{{ $translate(goods["产品类别"], goods["产品类别"]) }}</span>
            </div>
            <!--            <div class="data-alternative">-->
            <!--              <span class="item-key">同等规格型号：</span>-->
            <!--              <div>-->
            <!--                <div-->
            <!--                  v-for="(item,index) in goods['原厂替换']"-->
            <!--                  :key="index"-->
            <!--                >-->
            <!--                  <span>{{ item.原厂品牌 }}</span>-->
            <!--                  <span>{{ item.原厂型号 }}</span>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
        </div>
      </div>
      <div
        v-if="goodsList.length < 1"
        class="div_noProduct"
      >
        <div>
          <el-empty description="搜索结果为空" />
        </div>
      </div>
      <el-pagination
        v-if="goodsList.length > 0"
        :current-page="page.currentPage"
        layout="prev, pager, next, jumper"
        :total="page.total"
        @current-change="handleCurrentChange"
      />
    </main>

    <!--<common-footer />-->
  </body>
</template>

<script>
import baseUrl from "@/store/baseUrl";
import eventBus from "@/utils/eventBus";
export default {
  name: "ProductList",
  components: {
    // CommonFooter,
    // CommonHeader
  },
  data() {
    return {
      baseUrl: baseUrl.state.baseURL,
      downloadURL: baseUrl.state.downloadURL,
      page: {
        currentPage: 1,
        size: 10,
        total: 100
      },
      goodsList: [],
      errorImg: require("/src/assets/images/jdError.png"),
      searchKey: '',
    }
  },
  watch: {
    $route(newRoute,oldRoute){
      let newKey = newRoute.query.searchKey;
      if (newKey !== this.searchKey) {
        this.searchKey = newKey;
          this.search();
      }
    }
  },
  created() {
    this.searchKey = this.$route.query.searchKey;
    this.search();
  },
  methods: {
    toDetail(item) {
      const { href } = this.$router.resolve({
        name: "productDetail",
        query: {
          id: item.id,
        },
      });
      window.open(href, "_blank");
    },
    getImg(imgs){
      if (imgs.length === 1) {
        let mainImg = imgs[0];
        return this.downloadURL + mainImg;
      } else if (imgs.length > 1) {
        // 将第二张图片作为主图
        return this.downloadURL + imgs[1];
      }
    },
    handleCurrentChange: function (currentPage) {
      this.page.currentPage = currentPage;
      this.loadGoods();
    },
    loadGoods(){
      let url = "/item/goodsQuery/loadGoodsByQuery";
      let param = {
        searchKey: this.searchKey,
        queryMethod: "jdSelectMethod",
        current: this.page.currentPage,
        size: this.page.size,
      };
      this.$doPost(url,param,true).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.goodsList = res.data.records;
          this.page.total = res.data.total;
          // console.log(this.goodsList);
        }
      });
    },
    searchByQuery(searchKey) {
      this.searchKey = searchKey;
      this.search();
    },
    search() {
      this.page.currentPage = 1;
      this.loadGoods();
    }
  }
}
</script>

<style lang="less" scoped>
body {
  background-color: white;
}
main {
  overflow: hidden;
  width: 80%;
  margin: 0 auto;
  background-color: white;
  /deep/ .el-pager{
    .number {
      font-size: 18px;
    }
  }
  /deep/ .el-pagination {
    width: 400px;
    margin: 0 auto ;
    margin-bottom: 70px;
  }
  .search_input {
    margin-top: 30px;
    margin-bottom: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    >div:nth-child(1){
      width: 70%;
      /deep/.el-input-group--append .el-input__inner{
        height: 50px;
        border: 4px solid gainsboro;
      }
      /deep/ .el-input-group__append{
        border-color: gainsboro;
        border-style: solid;
        border-width: 4px 4px 4px 0px;
      }
    }
  }
  .decoration {
    margin-top: 70px;
    margin-bottom: 50px;
    display: flex;
    .decor-1 {
      height: 35px;
      background-color: #D8D8D8;
      width: 50%;
    }
    .decor-2 {
      height: 35px;
      background-color: #555555;
      width: 50%;
    }
  }
  .prod-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .prod-img, /deep/ .el-image__inner{
      width: 140px;
      /*margin-top: 30px;*/
      margin-left: 30px;
      border: solid #D8D8D8 0.5px;
      border-radius: 5px;
    }
    .prod-item {
      margin-bottom: 50px;
      display: flex;
      font-family: "PingFangSC-Regular", Arial;
      width: 50%;
      .img-box {
        width: 180px;
        margin-left: 25px;
        margin-right: 20px;
      }
      .part-no {
        > span, > a{
          color: #FF8B3D;
          font-weight: bold;}
        // 伪装成href
        > span.href {
          text-decoration:underline;
        }
        > span.href:hover{
          cursor: pointer;
        }
      }
      .tiny-item {
        display: flex;
      }
      .data-item{
        font-size: 14px;
        line-height: 28px;
        .item-key{
          color: #979797;
        }
        .item-value {
        }
      }
      .data-alternative{
        display: flex;
        font-size: 14px;
        line-height: 28px;
        .item-key{
          color: #979797;
        }
        .item-value {
        }
        >div{
          >div{
            margin-left: 20px;
            >span:nth-child(1){
              margin-right: 20px;
            }
          }
        }
      }
    }
  }

}
.div_noProduct{
  width: 100%;
  > div{
    text-align: center;
  }
}
</style>