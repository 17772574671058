import {downLoad} from "@/utils/http";
import baseURL from "@/store/baseUrl";
import md5 from "js-md5";

const commonUtil = {

  cloneObj(obj) {
    let _obj = JSON.stringify(obj),
      objClone = JSON.parse(_obj);
    return objClone;
  },
  //验证是否为数字
  isNumber(data) {
    if (data == 0) {
      return true;
    } else {
      var patrn = /^(-)?\d+(\.\d+)?$/;
      if (patrn.exec(data) == null || data == "") {
        return false;
      } else {
        return true;
      }
    }
  },
  isMoney(data) {
    //22,111,22.11   判断是否是金额
    if (data == null || data == "") {
      return false;
    }
    var arg = data.toString();
    var argChar = "0123456789.,";
    var beginArg = arg.substring(0, 1);
    if (beginArg == "." || beginArg == ",") {
      return false;
    }
    for (var i = 0; i < arg.length; i++) {
      if (argChar.indexOf(arg.substring(i, i + 1)) == -1) {
        return false;
      }
    }
    return true;
  },
  isMoneyCanFu(data) {
    //22,111,22.11   判断是否是金额(可通过负数)

    var arg = data.toString();
    var argChar = "-0123456789.,";
    var beginArg = arg.substring(0, 1);
    if (beginArg == "." || beginArg == ",") {
      return false;
    }
    for (var i = 0; i < arg.length; i++) {
      if (argChar.indexOf(arg.substring(i, i + 1)) == -1) {
        return false;
      }
    }
    return true;
  },
  parseInt(data) {
    if (data === "") {
      return 0;
    } else if (data == null) {
      return 0;
    } else if (data == "NaN") {
      return 0;
    } else if (typeof data === "undefined") {
      return 0;
    } else {
      return parseInt(data);
    }
  },
  isNull(data) {
    if (
      typeof data === "undefined" ||
      data == null ||
      data == "" ||
      data == "[]" ||
      JSON.stringify(data) == "{}" ||
      JSON.stringify(data) == "[]" ||
      JSON.stringify(data).replace(/\s+/g, "") == ""
    ) {
      return true;
    } else {
      return false;
    }
  },
  isNullObject(data) {
    if (data == null || JSON.stringify(data) == "{}") {
      return true;
    } else {
      return false;
    }
  },
  contains(str1, str) {
    let hasValue = false;
    str.forEach((element) => {
      if (element == str1) {
        hasValue = true;
      }
    });
    return hasValue;
  },
  //str1在str中
  containsStr(str1, str) {
    let hasValue = false;

    if (str.indexOf(str1) != -1) {
      hasValue = true;
    }
    return hasValue;
  },

  /**
   * 获取系统当前时间
   */
  getCurrentTime(format) {
    var now = new Date();
    var year = now.getFullYear(); //得到年份
    var month = now.getMonth(); //得到月份
    var date = now.getDate(); //得到日期
    var day = now.getDay(); //得到周几
    var hour = now.getHours(); //得到小时
    var minu = now.getMinutes(); //得到分钟
    var sec = now.getSeconds(); //得到秒
    month = month + 1;
    if (month < 10) month = "0" + month;
    if (date < 10) date = "0" + date;
    if (hour < 10) hour = "0" + hour;
    if (minu < 10) minu = "0" + minu;
    if (sec < 10) sec = "0" + sec;
    var time = "";
    //精确到天
    if (format == 1) {
      time = year + "-" + month + "-" + date;
    }
    //精确到秒
    else if (format == 2) {
      time =
        year + "-" + month + "-" + date + " " + hour + ":" + minu + ":" + sec;
    }
    return time;
  },
  /**
   * 计算字符串实际宽度
   * @param {*} fontSize
   * @param {*} fontFamily
   * @param {*} text
   * @auther wh
   */
  mathWidth(fontSize, fontFamily, text) {
    var span = document.createElement("span");

    var width = span.offsetWidth;

    span.style.visibility = "hidden";
    span.style.fontSize = fontSize;
    span.style.fontFamily = fontFamily;
    span.style.display = "inline-block";
    document.body.appendChild(span);
    if (typeof span.textContent != "undefined") {
      span.textContent = text;
    } else {
      span.innerText = text;
    }
    width = parseFloat(window.getComputedStyle(span).width) - width;
    return width;
  },
  compareTIme(date1, date2) {
    var date = new Date(date1);
    var date1 = new Date(date2);
    if (date.getTime() - date1.getTime() > 0) {
      return true;
    } else {
      return false;
    }
  },
  //获取时分秒
  TimeToString(Date) {
    let hour = Date.getHours(); //得到小时
    let min = Date.getMinutes(); //得到分钟
    let sec = Date.getSeconds(); //得到秒
    if (hour < 10) hour = "0" + hour;
    if (min < 10) min = "0" + min;
    if (sec < 10) sec = "0" + sec;
    let time = "";
    time = " " + hour + ":" + min + ":" + sec;
    return time;
  },
  //获取年月日
  timeToDate() {
    let now = new Date();
    let year = now.getFullYear(); //得到年份
    let month = now.getMonth(); //得到月份
    let date = now.getDate() - 1; //得到日期

    month = month + 1;
    if (month < 10) month = "0" + month;
    if (date < 10) date = "0" + date;

    let time = "";
    //精确到天
    time = year + "-" + month + "-" + date;

    return time;
  },
  //获取年月日
  getDateToday() {
    let now = new Date();
    let year = now.getFullYear(); //得到年份
    let month = now.getMonth(); //得到月份
    let date = now.getDate(); //得到日期

    month = month + 1;
    if (month < 10) month = "0" + month;
    if (date < 10) date = "0" + date;

    let time = "";
    //精确到天
    time = year + "-" + month + "-" + date;

    return time;
  },

  // 格式化时间
  timeFormat(fmt, time) {
    var curr_date = time.getDate();
    var curr_month = time.getMonth() + 1;
    var curr_year = time.getFullYear();
    String(curr_month).length < 2
      ? (curr_month = "0" + curr_month)
      : curr_month;
    String(curr_date).length < 2 ? (curr_date = "0" + curr_date) : curr_date;
    var yyyyMMdd = curr_year + "" + curr_month + "" + curr_date;
    return yyyyMMdd;
  },

  // 监听缓存
  resetSetItem(key, newVal) {
    var newStorageEvent = document.createEvent("StorageEvent");
    const storage = {
      setItem: function (k, val) {
        sessionStorage.setItem(k, val);

        // 初始化创建的事件
        newStorageEvent.initStorageEvent(
          key,
          false,
          false,
          k,
          null,
          val,
          null,
          null
        );

        // 派发对象
        window.dispatchEvent(newStorageEvent);
      },
    };
    return storage.setItem(key, newVal);
  },

  // 判断是否为非空正整数
  isInteger(fData) {
    //如果为空，返回false
    if (fData == null) return false;
    if (fData == "NaN" || fData.indexOf(".") != -1 || fData.indexOf("-") != -1)
      return false;

    return true;
  },

  // 判断传入数字是否在指定范围内（左闭右开）
  isIntegerInRange(fInput, fLower, fHigh) {
    if (fLower == null) return fInput < fHigh;
    else if (fHigh == null) return fInput > fLower;
    else return fInput > fLower && fInput < fHigh;
  },

  // 获取小数点后长度
  getDecimalLength(num) {
    if (this.isNull(num)) {
      return 0;
    }

    let decimal = num.toString().split(".")[1];
    // console.log(decimal);

    if (this.isNull(decimal)) {
      return 0;
    }

    return decimal.length;
  },

  // 四舍五入，获取指定位数的小数
  getFloat(num, n) {
    if (this.isNull(num)) {
      return num;
    }
    n = n ? this.parseInt(n) : 0;
    if (n <= 0) {
      return Math.round(num);
    }
    num = Math.round(num * Math.pow(10, n)) / Math.pow(10, n);
    num = Number(num).toFixed(n);
    return num;
  },

  // 日期格式化
  parseTime(time, pattern) {
    if (arguments.length === 0 || !time) {
      return null
    }
    const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
    let date
    if (typeof time === 'object') {
      date = time
    } else {
      if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
        time = parseInt(time)
      } else if (typeof time === 'string') {
        time = time.replace(new RegExp(/-/gm), '/');
      }
      if ((typeof time === 'number') && (time.toString().length === 10)) {
        time = time * 1000
      }
      date = new Date(time)
    }
    const formatObj = {
      y: date.getFullYear(),
      m: date.getMonth() + 1,
      d: date.getDate(),
      h: date.getHours(),
      i: date.getMinutes(),
      s: date.getSeconds(),
      a: date.getDay()
    }
    const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
      let value = formatObj[key]
      // Note: getDay() returns 0 on Sunday
      if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
      if (result.length > 0 && value < 10) {
        value = '0' + value
      }
      return value || 0
    })
    return time_str
  },

  // 导出文件
  exportFile(data, fileName) {
    if (!data) {
      return;
    }
    let blob = new Blob();
    blob = data;
    console.log(data);
    if (window.navigator.msSaveOrOpenBlob) {
      // 兼容IE10
      navigator.msSaveBlob(blob, fileName);
    } else {
      // 其他非IE内核支持H5的浏览器
      let url = window.URL.createObjectURL(
        new Blob([data])
      );
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  },

  downloadFile(url) {
    let finalUrl = baseURL.state.baseURL + url;
    let link = document.createElement("a");
    link.style.display = "none";
    link.href = finalUrl;
    link.setAttribute("download", "fileName");
    document.body.appendChild(link);
    link.click();
    link.remove();
  },

  encode(pwd) {
    return md5("JIDEJINGMI" + pwd);
  }
};
export default commonUtil;
